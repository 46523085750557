import React from 'react';
import NewAppModal from '../../../../../common/NewAppModal';
import {useLanguageContext, useThemeContext} from '../../../../../../context';
import placeholder from '../../../../../../assets/images/placeholder.svg';
import {Col, Container, Row} from 'react-bootstrap';
import List from './List';
import Icon from '../../../../../../assets/icons';
import {DEVICE_HEIGHT} from '../../../../../../constants';

const ModifierModal = ({modifierModal, handleCloseModifierModal, saveModifier, selectedModifier, setSelectedModifier}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const handleDeleteModifier = (id, opt) => {
        handleSelectModifier(id, opt);
    };

    const handleSelectModifier = (id, modifierOpt) => {
        setSelectedModifier(prev => {
            if (prev?.hasOwnProperty(id)) {
                const newState = {...prev};
                if (newState[id][modifierOpt.id]) {
                    delete newState[id][modifierOpt.id];
                    if (Object.keys(newState[id]).length === 0) {
                        delete newState[id];
                    }
                    return newState;
                } else {
                    return {
                        ...prev,
                        [id]: {
                            ...prev[id],
                            [modifierOpt.id]: {
                                id: modifierOpt.id,
                                name: modifierOpt.name,
                                price: modifierOpt.price,
                                in_stock: modifierOpt.in_stock,
                            },
                        },
                    };
                }
            } else {
                return {
                    ...prev,
                    [id]: {
                        [modifierOpt.id]: {
                            id: modifierOpt.id,
                            name: modifierOpt.name,
                            price: modifierOpt.price,
                            in_stock: modifierOpt.in_stock,
                        },
                    },
                };
            }
        });
    };
    return (
        <>
            <NewAppModal
                className="deliveryFloorModal modal-backdrop-custom"
                toggle={modifierModal.visible}
                backCta={{
                    name: 'backArrowIcon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }}
                handleClose={() => handleCloseModifierModal()}
                primaryCta={{
                    title: 'Confirm',
                    action: () => saveModifier(),
                }}
                cancelCta={{
                    title: I18n.cancel,
                    action: () => handleCloseModifierModal(),
                }}
                title={modifierModal?.selectedItem?.name}>
                <div>
                    <Container fluid>
                        <Row style={{height: DEVICE_HEIGHT - 300}}>
                            <Col md={8} className="modifierModalLeftPanel">
                                <p className="fontSize20 marBot0" style={{color: theme.white}}>
                                    {I18n.modifiers}
                                </p>

                                {modifierModal?.modifiers?.map(mod => (
                                    <List mod={mod} handleSelectModifier={handleSelectModifier} selectedModifier={selectedModifier} />
                                ))}
                            </Col>

                            <Col md={4} className="modifierModalRightPanel">
                                <div className="flex verticalCenter">
                                    <span className="itemListImage marRight15">
                                        <img src={modifierModal?.selectedItem?.image || placeholder} style={{resizeMode: 'cover'}} />
                                    </span>
                                    <p style={{color: theme.white}} className="marBot0 fontSize18 fontWeight500 OneLineTruncate">
                                        {modifierModal?.selectedItem?.name}
                                    </p>
                                </div>
                                <div className="flex justifyCenter marTop20">
                                    <div style={{width: '100%', borderBottom: '1px solid #2F3651'}} />
                                </div>

                                {modifierModal?.modifiers?.map(mod => (
                                    <div>
                                        <p className="fontSize14 marBot20 marTop20" style={{color: theme.white}}>
                                            {mod?.modifiers?.name}
                                        </p>
                                        {Object.values(selectedModifier?.[mod?.modifiers?.id] || {}).length > 0 &&
                                            Object.values(selectedModifier?.[mod?.modifiers?.id])?.map(item => (
                                                <>
                                                    <div style={{display: 'flex', marginTop: '10px'}}>
                                                        <p
                                                            className="fontSize12 marBot10"
                                                            style={{
                                                                color: theme.darkGrayTwo,
                                                                width: '70%',
                                                            }}>
                                                            {item.name}
                                                        </p>

                                                        <div
                                                            style={{
                                                                width: '30%',
                                                                display: 'flex',
                                                                justifyContent: 'flex-end',
                                                                alignItems: 'flex-start',
                                                            }}>
                                                            <p className="fontSize12 marBot10" style={{color: theme.darkGrayTwo}}>
                                                                {`Rs. ${item.price || 0}`}
                                                            </p>
                                                            <span
                                                                className="cursorPointer"
                                                                style={{
                                                                    position: 'relative',
                                                                    bottom: '6px',
                                                                    marginLeft: '10px',
                                                                }}>
                                                                <Icon
                                                                    name="delete2Icon"
                                                                    fill={theme.red}
                                                                    viewBox={'0 0 20 20'}
                                                                    onClick={() => handleDeleteModifier(mod?.modifiers?.id, item)}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}

                                        <div
                                            style={{
                                                width: '100%',
                                                borderBottom: '1px solid #2F3651',
                                            }}
                                        />
                                    </div>
                                ))}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </NewAppModal>
        </>
    );
};

export default ModifierModal;
