import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import withObservables from '@nozbe/with-observables';
import {capitalizeFirstAlphabet} from '../../../constants';

const ResturantSummary = ({order, floor_plan, table, waiter, rider}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    return (
        <>
            <div className="detailBotCol "></div>
            <div className="rowConatainer ">
                <div className="detailBotCol ">
                    <p className="fontSize12 marBot0" style={{color: theme.lightGrayTwo}}>
                        {I18n.floor_plan}
                    </p>
                    <p className="fontSize14 marBot0" style={{color: theme.white}}>
                        {floor_plan?.name}
                    </p>
                </div>

                <div className="detailBotCol ">
                    <p className="fontSize12 marBot0" style={{color: theme.lightGrayTwo}}>
                        {I18n.type}
                    </p>
                    <p className="fontSize14 marBot0" style={{color: theme.white}}>
                        {capitalizeFirstAlphabet(order.type)}
                    </p>
                </div>

                {order?.type === 'dinein' && (
                    <div className="detailBotCol">
                        <p className="fontSize12 marBot0" style={{color: theme.lightGrayTwo}}>
                            {I18n.table}
                        </p>
                        <p className="fontSize14 marBot0" style={{color: theme.white}}>
                            {table?.name}
                        </p>
                    </div>
                )}

                {order.type === 'dinein' && (
                    <div className="detailBotCol ">
                        <p className="fontSize12 marBot0" style={{color: theme.lightGrayTwo}}>
                            {I18n.guests}
                        </p>
                        <p className="fontSize14 marBot0" style={{color: theme.white}}>
                            {order?.no_of_guests}
                        </p>
                    </div>
                )}

                {(order?.type === 'dinein' || order.type === 'delivery') && (
                    <div className="detailBotCol ">
                        <p className="fontSize12 marBot0" style={{color: theme.lightGrayTwo}}>
                            {order?.type === 'dinein' ? I18n.waiter : I18n.rider}
                        </p>
                        <p className="fontSize14 marBot0" style={{color: theme.white}}>
                            {order?.type === 'dinein' ? waiter?.name || '--' : rider?.name || '--'}
                        </p>
                    </div>
                )}
            </div>
        </>
    );
};

const enhance = withObservables(['order'], ({order}) => ({
    floor_plan: order.floor_plan.observe(),
    table: order.dine_in_table.observe(),
    waiter: order.waiter.observe(),
    rider: order.rider.observe(),
}));

export default enhance(ResturantSummary);
