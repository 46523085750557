import {database} from '..';
import {ITEMS_PER_PAGE, uuid} from '../../constants';
import {Q} from '@nozbe/watermelondb';
import {BUSINESS_SCHEMA, PRODUCT_SCHEMA, PRODUCT_TEMPLATE_SCHEMA, PRODUCT_BUSINESS_SCHEMA, CATEGORY_SCHEMA, ACCOUNT_SCHEMA} from '../schema';

const product = database.collections.get(PRODUCT_SCHEMA);
const productTemplate = database.collections.get(PRODUCT_TEMPLATE_SCHEMA);
const productBusiness = database.collections.get(PRODUCT_BUSINESS_SCHEMA);

export const getProductByID = async id => await product.find(id);

export const observeCategoryProductCount = (business_id, category_id) =>
    product.query(Q.on(PRODUCT_BUSINESS_SCHEMA, [Q.where('business_id', business_id)]), Q.on(CATEGORY_SCHEMA, 'id', category_id)).observeCount();

export const observeAllProductCount = business_id => product.query(Q.on(PRODUCT_BUSINESS_SCHEMA, [Q.where('business_id', business_id)])).observeCount();

// export const getPaginatedCategoryProducts = (business_id, category_id, start, stop) =>
//     product.query(
//         Q.on(PRODUCT_BUSINESS_SCHEMA, [Q.where('business_id', business_id)]),
//         Q.on(CATEGORY_SCHEMA, 'id', category_id),
//         Q.skip(start),
//         Q.take(stop),
//         Q.sortBy('name', Q.asc),
//     );

export const getPaginatedCategoryProducts = (business_id, category_id, start, stop) => {
    let queryConditions = [Q.on(PRODUCT_BUSINESS_SCHEMA, [Q.where('business_id', business_id)]), Q.on(CATEGORY_SCHEMA, 'id', category_id), Q.sortBy('name', Q.asc)];

    if (start !== undefined && stop !== undefined) {
        queryConditions.push(Q.skip(start), Q.take(stop));
    }

    return product.query(...queryConditions);
};

export const getAllStockItems = (business_id, start, stop) => {
    let queryConditions = [Q.on(PRODUCT_BUSINESS_SCHEMA, [Q.where('business_id', business_id)]), Q.sortBy('name', Q.asc)];

    if (start !== undefined && stop !== undefined) {
        queryConditions.push(Q.skip(start), Q.take(stop));
    }

    return product.query(...queryConditions);
};

export const getCategoryProductWithBarcode = (business_id, category_id, barcode) => {
    let queryConditions = [
        Q.on(PRODUCT_BUSINESS_SCHEMA, [Q.where('business_id', business_id)]),
        Q.on(CATEGORY_SCHEMA, 'id', category_id),
        Q.where('barcode', barcode),
        Q.sortBy('name', Q.asc),
    ];

    return product.query(...queryConditions).fetch();
};

export const getProductWithBarcode = (business_id, barcode) => {
    let queryConditions = [Q.on(PRODUCT_BUSINESS_SCHEMA, [Q.where('business_id', business_id)]), Q.where('barcode', barcode), Q.sortBy('name', Q.asc)];

    return product.query(...queryConditions).fetch();
};

export const observeAllProductsV2 = (business_id, category_id) =>
    product.query(Q.on(PRODUCT_BUSINESS_SCHEMA, 'business_id', business_id), Q.on(CATEGORY_SCHEMA, 'id', category_id)).observe();

export const observeAllProducts = () =>
    product.query(
        Q.on(PRODUCT_BUSINESS_SCHEMA, 'business_id', localStorage.getItem('business_id')),
        Q.where('archive', Q.notEq(true)),
        Q.where('draft', Q.notEq(true)),
        Q.where('type', Q.notEq('deal')),
    );

export const isBarCodeExist = barcode => product.query(Q.where('barcode', Q.eq(barcode))).fetch();

export const observeActiveProducts = (currentPage = 0, itemsPerPage = 10) =>
    product.query(
        Q.on(PRODUCT_BUSINESS_SCHEMA, 'business_id', localStorage.getItem('business_id')),
        Q.where('type', Q.notEq('deal')),
        Q.where('archive', Q.notEq(true)),
        Q.skip(currentPage * itemsPerPage),
        Q.take(itemsPerPage),
        Q.sortBy('name', Q.asc),
        Q.where('archive', Q.notEq(true)),
        Q.where('draft', Q.notEq(true)),
    );

export const observeAllUnAchriveProducts = () =>
    product.query(
        Q.on(PRODUCT_BUSINESS_SCHEMA, 'business_id', localStorage.getItem('business_id')),
        Q.where('type', Q.notEq('deal')),
        Q.where('archive', Q.notEq(true)),
        Q.where('draft', Q.notEq(true)),
    );

export const getTotalItemsCount = async () =>
    await product.query(Q.on(PRODUCT_BUSINESS_SCHEMA, 'business_id', localStorage.getItem('business_id')), Q.where('type', Q.notEq('deal'))).fetchCount();

export const queryItems = async (selectedFilter, searchTerm, currentPage = 0, itemsPerPage = 10, isDealPanel) => {
    let filterCondition;

    const typeCondition = isDealPanel ? Q.where('type', Q.eq('deal')) : Q.where('type', Q.notEq('deal'));

    switch (selectedFilter) {
        case 'all_inventory':
            filterCondition = Q.where('archive', Q.notEq(true));
            break;
        case 'low_stock':
            filterCondition = Q.and(Q.where('archive', Q.notEq(true)), Q.where('quantity', Q.lt(Q.column('low_stock'))));
            break;
        case 'sold_out':
            filterCondition = Q.and(Q.where('archive', Q.notEq(true)), Q.where('quantity', Q.lte(0)));
            break;
        case 'archived':
            filterCondition = Q.where('archive', Q.eq(true));
            break;
        case 'drafts_saved':
            filterCondition = Q.and(Q.where('archive', Q.notEq(true)), Q.where('draft', Q.eq(true)));
            break;
        default:
            filterCondition = Q.and(Q.where('archive', Q.notEq(true)), Q.where('draft', Q.notEq(true)));
    }

    const totalCountQuery = product.query(
        Q.on(PRODUCT_BUSINESS_SCHEMA, 'business_id', localStorage.getItem('business_id')),
        typeCondition,
        Q.or(Q.where('name', Q.like(`%${searchTerm}%`)), Q.where('barcode', Q.like(`%${searchTerm}%`))),
        filterCondition,
    );

    const totalCount = await totalCountQuery.fetchCount();

    const data = await product
        .query(
            Q.on(PRODUCT_BUSINESS_SCHEMA, 'business_id', localStorage.getItem('business_id')),
            typeCondition,
            Q.or(Q.where('name', Q.like(`%${searchTerm}%`)), Q.where('barcode', Q.like(`%${searchTerm}%`))),
            filterCondition,
            Q.skip(currentPage * itemsPerPage),
            Q.take(itemsPerPage),
            Q.sortBy('name', Q.asc),
        )
        .fetch();

    return {data, totalCount};
};

export const observeActiveDeals = business_id =>
    product.query(
        Q.on(PRODUCT_BUSINESS_SCHEMA, 'business_id', business_id),
        Q.where('archive', Q.notEq(true)),
        Q.where('draft', Q.notEq(true)),
        Q.where('type', Q.eq('deal')),
        Q.sortBy('name', Q.asc),
    );

export const observeArchiveDeals = business_id =>
    product.query(Q.on(PRODUCT_BUSINESS_SCHEMA, 'business_id', business_id), Q.where('archive', Q.eq(true)), Q.where('type', Q.eq('deal')), Q.sortBy('name', Q.asc));

export const observeDraftDeals = business_id =>
    product.query(
        Q.on(PRODUCT_BUSINESS_SCHEMA, 'business_id', business_id),
        Q.where('type', Q.eq('deal')),
        Q.where('draft', Q.eq(true)),
        Q.where('archive', Q.notEq(true)),
        Q.sortBy('name', Q.asc),
    );

const prepareInsertion = (data, template, account, category, tax, locations, itemLevelDiscount) => {
    return data.map(item => {
        try {
            let totalProfit = item.sellingPrice - item.costPrice;
            let totalMargin = (totalProfit / item.costPrice) * 100 || 0;

            item['profit'] = totalProfit <= 0 ? 0 : Number(totalProfit);
            item['margin'] = totalMargin <= 0 || totalMargin == Infinity ? 0 : totalMargin;

            const prod = product.prepareCreate(field => {
                field.product_template.set(template);
                field.account.set(account);
                category && field.category.set(category);
                tax && field.sales_tax.set(tax);
                field._raw.id = uuid();
                field.name = `${template.name} ${item.name}`;
                field.base_price = tax?.is_inclusive ? Number((Number(item.sellingPrice) * (100 / (100 + tax?.rate))).toFixed(2)) : Number(item.sellingPrice);
                field.selling_price = Number(item.sellingPrice);
                field.cost_price = Number(item.costPrice || 0);
                field.profit = Number(item.profit || 0);
                field.margin = Number(item.margin || 0);
                field.quantity = Number(item.quantity || 0);
                field.barcode = item.barcode;
                field.low_stock = Number(item.lowStock || 0);
                field.product_level_discount = itemLevelDiscount;
                field.created_at = new Date().getTime();
            });

            const prodBus = locations.map(business => {
                return productBusiness.prepareCreate(field => {
                    field._raw.id = uuid();
                    field.product.set(prod);
                    field.business.set(business);
                    field.quantity = Number(item.quantity || 0);
                });
            });

            return [prod, ...prodBus];
        } catch (err) {
            console.log(err);
        }
    });
};

const prepareUpdation = poLines => {
    return poLines?.map(line => {
        const prodRef = line.productRef.prepareUpdate(field => {
            let costPrice = 0;
            let quantity = 0;
            let rate = line.productTemplate.conversion_rate || 1;
            if (line.value.selectedUnit === line.productTemplate.unit) {
                costPrice = line.value.cost_price;
                quantity = line.value.quantity;
            } else {
                costPrice = line.value.cost_price * rate;
                quantity = line.value.quantity / rate;
            }

            let totalProfit = field.selling_price - Number(costPrice);
            let totalMargin = (totalProfit / Number(costPrice)) * 100;

            totalProfit = totalProfit <= 0 ? 0 : Number(totalProfit);
            totalMargin = totalMargin <= 0 || totalMargin == Infinity ? 0 : Number(totalMargin);

            line.value.quantity && (field.quantity += Number(quantity));
            line.value.cost_price && (field.cost_price = Number(costPrice));
            field.margin = totalMargin;
            field.profit = totalProfit;
        });

        const prodBussRef = line.prodBusinessRef.prepareUpdate(field => {
            let quantity = 0;
            let rate = line.productTemplate.conversion_rate || 1;

            if (line.value.selectedUnit === line.productTemplate.unit) {
                quantity = line.value.quantity;
            } else {
                quantity = line.value.quantity / rate;
            }
            field.quantity += Number(quantity);
        });

        return [prodRef, prodBussRef];
    });
};

export const bulkCreateProducts = async (data, template, account, category, tax, locations, itemLevelDiscount) => {
    await database.write(async () => {
        const allRecords = prepareInsertion(data, template, account, category, tax, locations, itemLevelDiscount);
        await database.batch(...allRecords.flat());
    });
};

export const bulkUpdateProducts = async poLines => {
    await database.write(async () => {
        const allRecords = await prepareUpdation(poLines);
        const flattenedRecords = allRecords.flat();
        await database.batch(...flattenedRecords);
        return allRecords;
    });
};

const prepareInsertionProductTemplate = async (data, account, product_category) => {
    return await Promise.all(
        [data].map(async details => {
            try {
                return productTemplate.prepareCreate(field => {
                    field.account.set(account);
                    product_category && field.category.set(product_category);
                    field._raw.id = uuid();
                    field.name = details.name;
                    field.description = details.description;
                    field.image = details.image;
                    field.unit = details.unit;
                    field.secondary_unit = details.secondary_unit;
                    field.conversion_rate = details.rate;
                    field.tax = details.tax;
                    field.is_taxable = details.is_taxable;
                    field.is_trackable = details.is_trackable;
                });
            } catch (err) {
                console.log(err);
            }
        }),
    );
};

const prepareInsertionProduct = async (data, account, template, product_category, locations) => {
    return await Promise.all(
        [data].map(async details => {
            try {
                const prod = product.prepareCreate(field => {
                    field.product_template.set(template);
                    field.account.set(account);
                    product_category && field.category.set(product_category);
                    details.tax && field.sales_tax.set(details.tax);
                    field._raw.id = uuid();
                    field.name = details.name;
                    field.selling_price = details.selling_price;
                    field.cost_price = details.cost_price;
                    field.profit = details.profit;
                    field.margin = details.margin;
                    field.quantity = details.quantity;
                    field.barcode = details.barcode;
                    field.low_stock = details.low_stock;
                });

                const prodBus = locations.map(business => {
                    return productBusiness.prepareCreate(field => {
                        field._raw.id = uuid();
                        field.product.set(prod);
                        field.business.set(business);
                        field.quantity = Number(details.quantity || 0);
                    });
                });

                return [prod, ...prodBus];
            } catch (err) {
                console.log(err);
            }
        }),
    );
};

export const bulkCreateTemplateAndItem = async (data, account, product_category, locations) => {
    await database.write(async () => {
        const template = await prepareInsertionProductTemplate(data, account, product_category);
        const allRecords = await prepareInsertionProduct(data, account, template[0], product_category, locations);
        await database.batch(...allRecords.flat(), ...template);
    });
};

const prepareInsertionCatalogs = (data, account) => {
    return data?.map(details => {
        try {
            const prodTemp = productTemplate.prepareCreate(field => {
                field.account.set(account);
                details.category && field.category.set(details.category);
                field._raw.id = uuid();
                field.name = details.name;
                field.description = details.description;
                field.image = details.image;
                field.unit = details.unit;
                field.secondary_unit = details.secondary_unit;
                field.conversion_rate = details.rate;
                field.tax = details.tax;
                field.is_taxable = details.is_taxable;
                field.is_trackable = details.is_trackable;
            });

            const prod = product.prepareCreate(field => {
                field.product_template.set(prodTemp);
                field.account.set(account);
                details.category && field.category.set(details.category);
                details.tax && field.sales_tax.set(details.tax);
                field._raw.id = uuid();
                field.name = details.name;
                field.selling_price = details.selling_price;
                field.cost_price = details.cost_price;
                field.profit = details.profit;
                field.margin = details.margin;
                field.quantity = details.quantity;
                field.barcode = details.barcode;
                field.low_stock = details.low_stock;
            });

            return [prodTemp, prod];
        } catch (err) {
            console.log(err);
        }
    });
};

export const bulkCreateCatalogs = async (data, account) => {
    await database.write(async () => {
        const allRecords = await prepareInsertionCatalogs(data, account);
        await database.batch(...allRecords.flat());
    });
};

export const getFilteredProducts = async (business_id, category_id, name_sort, price_sort, date_sort) => {
    let filterQuery = [];

    if (name_sort) filterQuery.push(Q.sortBy('name', name_sort == 'asc' ? Q.asc : Q.desc));
    if (price_sort) filterQuery.push(Q.sortBy('selling_price', price_sort == 'asc' ? Q.asc : Q.desc));
    if (date_sort) filterQuery.push(Q.sortBy('created_at', date_sort == 'asc' ? Q.asc : Q.desc));

    return await product.query(Q.on(PRODUCT_BUSINESS_SCHEMA, [Q.where('business_id', business_id)]), Q.on(CATEGORY_SCHEMA, 'id', category_id), ...filterQuery).fetch();
};

export const getFilteredProductsFromAllCategories = async (business_id, name_sort, price_sort, date_sort) => {
    let filterQuery = [];

    if (name_sort) filterQuery.push(Q.sortBy('name', name_sort == 'asc' ? Q.asc : Q.desc));
    if (price_sort) filterQuery.push(Q.sortBy('selling_price', price_sort == 'asc' ? Q.asc : Q.desc));
    if (date_sort) filterQuery.push(Q.sortBy('created_at', date_sort == 'asc' ? Q.asc : Q.desc));

    return await product.query(Q.on(PRODUCT_BUSINESS_SCHEMA, [Q.where('business_id', business_id)]), ...filterQuery).fetch();
};
