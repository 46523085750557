import {Q} from '@nozbe/watermelondb';
import {database} from '..';
import {USER_SCEHMA, USER_BUSINESS_SCHEMA, ROLE_SCHEMA, ACCOUNT_SCHEMA} from '../schema';

const user = database.collections.get(USER_SCEHMA);

export const findUserByID = async id => await user.find(id);

export const getUserFromEmail = async email => await user.query(Q.where('email', email)).fetch();

export const getUserImagesToUpload = async () => await user.query(Q.where('image', Q.like('%file:///%'))).fetch();

export const observeActiveUsers = () =>
    user.query(Q.on(USER_BUSINESS_SCHEMA, 'business_id', localStorage.getItem('business_id')), Q.where('archive', Q.notEq(true))).observeWithColumns(['archive']);

export const observeAccountActiveUsers = () => user.query(Q.where('archive', Q.notEq(true))).observeWithColumns(['archive']);

export const observeInActiveUsers = () =>
    user.query(Q.on(USER_BUSINESS_SCHEMA, 'business_id', localStorage.getItem('business_id')), Q.where('archive', Q.eq(true))).observeWithColumns(['archive']);
export const observeAccountInActiveUsers = () => user.query(Q.where('archive', Q.eq(true))).observeWithColumns(['archive']);

export const filterUserBasedRoles = async role =>
    user
        .query(
            Q.experimentalNestedJoin(USER_BUSINESS_SCHEMA, ROLE_SCHEMA),
            Q.where('archive', Q.notEq(true)),
            Q.on(USER_BUSINESS_SCHEMA, Q.on(ROLE_SCHEMA, 'name', Q.like(`%${role}%`))),
        )
        .fetch();
