import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import {QtyIncreaseDecrase} from '../V2';
import Icon from '../../../assets/icons';
import {formatNum} from '../../../constants';

const DealsItem = ({itemList, setSelectedItems, amount}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const totalBasePrice = itemList?.reduce((acc, item) => {
        const itemBasePrice = item?.item?.base_price || 0;
        const itemQuantity = item?.quantity || 1;
        return acc + itemBasePrice * itemQuantity;
    }, 0);

    const handleUpdateItemQty = (item, val, type) => {
        let updatedItems = itemList?.map(x => {
            if (x.item.id === item.item.id) {
                return {
                    ...x,
                    quantity: type === 'onBlur' ? val : type === 'increment' ? Number(x.quantity + 1) : Number(x.quantity - 1),
                };
            } else {
                return x;
            }
        });
        updatedItems = updatedItems.filter(x => x.quantity > 0);
        setSelectedItems(updatedItems);
    };

    const handleDeleteItem = id => {
        const filterItems = itemList?.filter(item => item.item.id !== id);
        setSelectedItems(filterItems);
    };

    return (
        <>
            <div className="marTop20 dealsItemBox">
                <div>
                    <div className="flex">
                        <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.white, width: '40%'}}>
                            {I18n.items}
                        </p>
                        <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.white, width: '30%', textAlign: 'center'}}>
                            {I18n.quantity}
                        </p>

                        <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.white, width: '25%', textAlign: 'center'}}>
                            {I18n.cost_price}
                        </p>

                        <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.white, width: '5%', textAlign: 'center'}} />
                    </div>
                    <div className="divider width100 marTop20 marBot10" />
                    {itemList?.map(item => {
                        return (
                            <div className="marTop10 flex">
                                <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.white, width: '40%'}}>
                                    {item?.item?.name}
                                </p>

                                <span className="flex justifyCenter" style={{width: '30%'}}>
                                    <QtyIncreaseDecrase item={item} toggle={true} handleUpdateItemQty={handleUpdateItemQty} trigger="onBlur" />
                                </span>

                                <span className="flex justifyCenter" style={{width: '25%'}}>
                                    <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.darkGrayTwo}}>
                                        {item?.item?.base_price}
                                    </p>
                                </span>

                                <span className="flex justifyCenter cursorPointer" style={{width: '5%'}}>
                                    <Icon name="delete2Icon" fill={theme.red} viewBox={'0 0 20 20'} onClick={() => handleDeleteItem(item?.item?.id)} />
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="totalCost">
                <p className="marBot0 fontSize20 fontWeight400" style={{color: theme.white}}>
                    {I18n.total_cost}
                </p>

                <p className="marBot0 fontSize22 fontWeight600" style={{color: theme.white}}>
                    {`Rs. ${formatNum(totalBasePrice)}`}
                </p>
            </div>
            {amount > totalBasePrice && (
                <div className="flex marTop15">
                    <Icon name="infocircleIcon" fill={theme.barclaysBlue} width="18" height="18" viewBox={'0 0 18 18'} />

                    <p className="marBot0 fontSize12 marLeft5 fontWeight400" style={{color: theme.darkGrayTwo}}>
                        {I18n.deal_amount_is_greater}
                    </p>
                </div>
            )}
        </>
    );
};

export default DealsItem;
