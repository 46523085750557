import {useLanguageContext, useThemeContext} from '../../../../../context';
import {CheckBox, Dropdown, MessageModal} from '../../../../common';
import TableBody from '../../../../common/TableContainer/TableBody';
import {firstLetterCaptilize} from '../../../../../constants';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import withObservables from '@nozbe/with-observables';
import {switchMap} from 'rxjs';
import {observeDealProducts} from '../../../../../pos-core/database/helpers/deal_product';

const List = ({product, dealProducts, userPermission, setRenderToast, setSelected, selected, handleChecked}) => {
    let navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [archiveModal, setArchiveModal] = useState(false);
    const toggleArchiveModal = () => setArchiveModal(x => !x);

    const handleArchive = async product => {
        setArchiveModal(false);

        let data = product?.id;
        if (selected[data]) {
            setSelected(items => {
                let obj = {...items};
                let item = obj[data];
                if (item) delete obj[data];
                return obj;
            });
        }

        await product.archiveProduct(product?.archive ? false : true);
        const toastMessage = product?.archive ? I18n.deal_archived_successfully : I18n.deal_unarchived_successfully;
        setRenderToast(toastMessage);
    };

    const tableAction = [
        {
            title: I18n.edit,
            action: () => navigate(`/deals/${product.id}/edit`),
        },
        {
            title: I18n[product.archive ? 'unarchive' : 'archive'],
            action: toggleArchiveModal,
        },
    ];

    return (
        <>
            <TableBody className={`fiveColumn cursorPointer`} style={{borderColor: theme.inputBorder}}>
                {/* <div className="checkboxCol BoxWidth">
                    <p className="fontSize14 OneLineTruncate marBot0" style={{color: theme.white}}>
                        {firstLetterCaptilize(product.name)}
                    </p>
                </div> */}

                <div
                    className="checkboxCol"
                    onClick={e => {
                        e.stopPropagation();
                    }}>
                    <CheckBox
                        checkboxLabel={
                            <p className="marBot0 fontSize14 OneLineTruncate" style={{color: theme.white}}>
                                {firstLetterCaptilize(product.name)}
                            </p>
                        }
                        onChange={() => handleChecked(product)}
                        checked={selected[product?.id]}
                    />
                </div>

                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {product.type}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {product.selling_price}
                    </p>
                </div>

                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {dealProducts?.length}
                    </p>
                </div>

                {userPermission?.canCrud && (
                    <div className="BoxWidth justifyCenter">
                        <div className="listActionBox">
                            <Dropdown
                                dropDown={{
                                    name: 'threeDotIcon',
                                    fill: theme.barclaysBlue,
                                    width: 18,
                                    height: 4,
                                    viewBox: '0 0 18 4',
                                    option: tableAction,
                                }}
                            />
                        </div>
                    </div>
                )}
            </TableBody>

            <MessageModal
                setToggle={setArchiveModal}
                toggle={archiveModal}
                className="messageModal"
                description={I18n[product.archive ? 'do_you_really_want_to_unarchive_this_deal' : 'do_you_really_want_to_archive_this_deal']}
                secondaryCta={{
                    title: I18n.cancel,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n[product.archive ? 'unarchive' : 'archive'],
                    action: () => {
                        handleArchive(product);
                    },
                }}
            />
        </>
    );
};

const enhance = withObservables(['product'], ({product}) => ({
    dealProducts: product.deal.observe().pipe(switchMap(x => observeDealProducts(x.id))),
}));

export default enhance(List);
