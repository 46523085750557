import axios, {AxiosError, AxiosResponse} from 'axios';
import {BASE_URL} from '../constants';
import API_ENDPOINTS from './endpoints';
import {hasUnsyncedChanges, synchronize} from '@nozbe/watermelondb/sync';
import Cookies from 'js-cookie';
import {database} from '../pos-core/database';
import {getTemplateImagesToUpload} from '../pos-core/database/helpers';

export const Axios = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
});

export const handleApi = async axiosPromise => {
    try {
        return (await axiosPromise).data;
    } catch (err) {
        console.log(err);

        if (err instanceof AxiosError) {
            throw err;
        }

        if (err instanceof Error) {
            throw err;
        }

        throw err;
    }
};
export const handleDownloadCSV = async url => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Token ${Cookies.get('access_token')}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const blob = await response.blob();
            const urlObject = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = urlObject;
            link.download = 'report.csv';
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            link.remove();

            URL.revokeObjectURL(urlObject);
        } else {
            const jsonRes = await response.json();
            throw new Error(`Error ${response.status}: ${jsonRes.message}`);
        }
    } catch (error) {
        console.error('Error downloading CSV:', error);
    }
};

export const handleCatalogsApi = async axiosPromise => {
    try {
        return await axiosPromise;
    } catch (error) {
        console.log('err', error);
        return error;
    }
};
export const handleLoginApi = async axiosPromise => {
    try {
        return await axiosPromise;
    } catch (err) {
        console.log(err);
        return err;
    }
};
export const Sync = async (email, clearPulledAt = false) => {
    console.log('Initiate Sync');

    let time = clearPulledAt ? null : localStorage.getItem(email);

    try {
        await handleImageBackup();
    } catch (err) {
        console.log('err in handleImageBackup', err);
    }

    try {
        await synchronize({
            database,
            pullChanges: async ({lastPulledAt, schemaVersion, migration}) => {
                lastPulledAt = time ? Number(time) : undefined;

                let syncStatus = await hasUnsyncedChanges({database});

                let paramsForApi = {
                    lastPulledAt,
                    schemaVersion,
                    migration,
                    hasUnsyncedChanges: syncStatus,
                };

                const response = await Axios.get(API_ENDPOINTS.SYNC, {
                    params: paramsForApi,
                    headers: {Authorization: `Token ${Cookies.get('access_token')}`},
                });

                if (!(response.status == 200)) {
                    throw new Error(await response.statusText);
                }

                console.log('response', response);
                const {changes, sync_timestamp} = response.data;
                if (sync_timestamp) {
                    localStorage.setItem(email, sync_timestamp.toString());
                }
                let timestamp = sync_timestamp;

                return {changes, timestamp};
            },
            pushChanges: async ({changes}) => {
                const paramsForApi = {
                    params: {
                        changes,
                    },
                };

                const response = await Axios.post(API_ENDPOINTS.SYNC, paramsForApi, {
                    headers: {Authorization: `Token ${Cookies.get('access_token')}`},
                });

                if (!(response.status == 200)) {
                    throw new Error(await response.statusText);
                }
            },
        });
    } catch (error) {
        return error;
    }
};

const handleImageBackup = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let templateImages = await getTemplateImagesToUpload();

            let imagesToUpload = [...templateImages];

            if (imagesToUpload.length) {
                let promises = imagesToUpload.map(val => {
                    return new Promise(async (resolve, reject) => {
                        try {
                            let paramsToUpload = {
                                data: val.image,
                                data_id: val.id,
                                data_type: val.table,
                            };

                            let {url} = await postUploadImage(paramsToUpload);

                            console.log('url', url);
                            await val.updateImage(url);

                            resolve();
                        } catch (err) {
                            console.log('err in updateImage', err);
                            reject(err);
                        }
                    });
                });

                Promise.all(promises)
                    .then(() => {
                        console.log('All images backed up');
                    })
                    .catch(err => {
                        console.log('err in uploading images', err);
                        reject(err);
                    });
            } else {
                console.log('No images to backup');
            }

            resolve();
        } catch (err) {
            console.log('Err in handleImageBackup', err);
            reject(err);
        }
    });
};

export const postUserLogin = payload => handleLoginApi(Axios.post(API_ENDPOINTS.LOGIN, payload));
export const postUserLogout = payload =>
    handleApi(
        Axios.post(API_ENDPOINTS.LOGOUT, payload, {
            headers: {Authorization: `Token ${Cookies.get('access_token')}`},
        }),
    );

export const getCatalogsData = payload => handleCatalogsApi(Axios.get(API_ENDPOINTS.GET_CATALOGS, {params: payload}));

export const postAccountRegister = payload => handleApi(Axios.post(API_ENDPOINTS.REGISTER, payload));

export const getCheckUseremail = payload => handleApi(Axios.get(API_ENDPOINTS.CHECK_EMAIL, {params: payload}));

export const getCheckPhoneNumber = payload => handleApi(Axios.get(API_ENDPOINTS.CHECK_PHONE_NUMBER, {params: payload}));

export const updatePassword = payload =>
    handleApi(
        Axios.post(API_ENDPOINTS.UPDATE_PASSWORD, payload, {
            headers: {Authorization: `Token ${Cookies.get('access_token')}`},
        }),
    );

export const postUploadImage = payload =>
    handleApi(
        Axios.post(API_ENDPOINTS.UPLOAD_IMAGE, payload, {
            headers: {Authorization: `Token ${Cookies.get('access_token')}`},
        }),
    );

// Kitched Display API'S

export const createDevice = payload =>
    handleApi(
        Axios.post(`${API_ENDPOINTS.CREATE_DEVICE}device/`, payload, {
            headers: {Authorization: `Token ${Cookies.get('access_token')}`},
        }),
    );

export const getAccountDevices = () =>
    handleApi(
        Axios.get(`${API_ENDPOINTS.CREATE_DEVICE}device/`, {
            headers: {Authorization: `Token ${Cookies.get('access_token')}`},
        }),
    );

export const updatePin = (payload, deviceId) =>
    handleApi(
        Axios.post(`${API_ENDPOINTS.UPDATE_PIN}${deviceId}/`, payload, {
            headers: {Authorization: `Token ${Cookies.get('access_token')}`},
        }),
    );

export const sendEmail = payload =>
    handleApi(
        Axios.post(API_ENDPOINTS.SEND_EMAIL, payload, {
            headers: {Authorization: `Token ${Cookies.get('access_token')}`},
        }),
    );

export const sendUserCredentials = payload =>
    handleApi(
        Axios.post(API_ENDPOINTS.SEND_USER_CRED, payload, {
            headers: {Authorization: `Token ${Cookies.get('access_token')}`},
        }),
    );

export const loginDevice = payload => handleApi(Axios.post(API_ENDPOINTS.LOGIN_DEVICE, payload));

export const validateDevicePin = payload => handleApi(Axios.post(API_ENDPOINTS.VALIDATE_PIN, payload));

export const updateOdooConfig = (payload, deviceId) =>
    handleApi(
        Axios.post(`${API_ENDPOINTS.UPDATE_ODOO_CONFIG}${deviceId}/`, payload, {
            headers: {Authorization: `Token ${Cookies.get('access_token')}`},
        }),
    );

export const getKitchenOrders = deviceId => handleApi(Axios.get(`${API_ENDPOINTS.GET_KDS_ORDER}${deviceId}/`));

export const getKitchenOrdersHistory = (deviceId, payload) => handleApi(Axios.get(`${API_ENDPOINTS.GET_KDS_ORDER}${deviceId}/`, {params: payload}));

export const updateKitchenOrder = payload => handleApi(Axios.post(`${API_ENDPOINTS.UPDATE_KDS_ORDER}`, payload));

export const kitchenNotifications = payload => handleApi(Axios.post(`${API_ENDPOINTS.KITCHEN_NOTIFICATIONS}`, payload));

export const updateDeviceConfiguration = (payload, deviceId) => handleApi(Axios.put(`${API_ENDPOINTS.UPDATE_DEVICE_CONFIG}${deviceId}/`, payload));

export const getAllCountry = payload => handleApi(Axios.get(API_ENDPOINTS.GET_COUNTRY));

export const sendOtp = payload => handleApi(Axios.post(`${API_ENDPOINTS.SEND_OTP}`, payload));

export const verifyEmail = payload => handleApi(Axios.post(`${API_ENDPOINTS.VERIFY_EMAIL}`, payload));

export const forgotPassword = payload => handleApi(Axios.post(`${API_ENDPOINTS.FORGOT_PASSWORD}`, payload));

export const getDashboardAnalytics = payload =>
    handleApi(
        Axios.get(API_ENDPOINTS.DASHBOARD_ANALYTICS, {
            headers: {Authorization: `Token ${Cookies.get('access_token')}`},
            params: payload,
        }),
    );
export const uploadBusinessMargin = payload =>
    handleApi(
        Axios.post(`${API_ENDPOINTS.POST_BUSINEES_MARGIN}`, payload, {
            headers: {Authorization: `Token ${Cookies.get('access_token')}`},
        }),
    );

export const getMarginReport = payload =>
    handleApi(
        Axios.get(`${API_ENDPOINTS.GET_MARGIN_REPORT}`, {
            params: payload,
            headers: {Authorization: `Token ${Cookies.get('access_token')}`},
        }),
    );

export const getMarginActivation = payload =>
    handleApi(Axios.get(API_ENDPOINTS.GET_MARGIN_ACTIVATION, {params: payload, headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const getTopAndLowSellingProducts = payload =>
    handleApi(Axios.get(API_ENDPOINTS.GET_TOP_AND_LOW_SELLING_PRODUCTS, {params: payload, headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const getLowAndOutStockProducts = payload =>
    handleApi(Axios.get(API_ENDPOINTS.GET_LOW_AND_OUT_OF_STOCK_PRODUCTS_And_EXORT_CSV, {params: payload, headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const getLocationWiseSellingView = payload =>
    handleApi(Axios.get(API_ENDPOINTS.GET_LOCTION_WISE_SALES_AND_EXPORT_CSV, {params: payload, headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const getSalesReportAnalytics = payload =>
    handleApi(Axios.get(API_ENDPOINTS.GET_SALES_REPORT_ANALYTICS, {params: payload, headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const getSalesTransationsList = payload =>
    handleApi(Axios.post(API_ENDPOINTS.GET_SALES_TRANSACTIONS_LIST, payload, {headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const getWalletActivity = payload => handleApi(Axios.post(API_ENDPOINTS.GET_Wallet_ACTIVITY, payload, {headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const getWalletActivityDetails = payload =>
    handleApi(Axios.get(API_ENDPOINTS.GET_WALLET_ACTIVITY_DETAILS, {params: payload, headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const getTransactionBreakDown = payload =>
    handleApi(Axios.post(API_ENDPOINTS.GET_TRANSACTION_BREAK_DOWN, payload, {headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));
export const getBanks = payload => handleApi(Axios.get(API_ENDPOINTS.GET_BANKS, {params: payload, headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const getAccountTitle = payload =>
    handleApi(Axios.get(API_ENDPOINTS.FETCH_ACCOUNT_TITLE, {params: payload, headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const sendWalletOTP = payload => handleApi(Axios.post(API_ENDPOINTS.SEND_WALLET_OTP, payload, {headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const createBeneficiary = payload => handleApi(Axios.post(API_ENDPOINTS.CREATE_BENEFICIARY, payload, {headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const getBeneficiariesList = payload =>
    handleApi(Axios.get(API_ENDPOINTS.GET_BENEFICIARIES, {params: payload, headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const deleteBeneficiary = payload =>
    handleApi(
        Axios.delete(API_ENDPOINTS.DELETE_BENEFICIARY, {
            headers: {Authorization: `Token ${Cookies.get('access_token')}`},
            data: payload,
        }),
    );

export const bulkPayoutBeneficiaries = payload =>
    handleApi(Axios.post(API_ENDPOINTS.PAYOUT_BENEFICIARY, payload, {headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const getWalletCurrentBalance = payload =>
    handleApi(Axios.get(API_ENDPOINTS.GET_WALLET_CURRENT_BALANCE, {params: payload, headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const getWalletBalance = payload =>
    handleApi(Axios.get(API_ENDPOINTS.GET_WALLET_BALANCE, {params: payload, headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const getWalletCtivityCSV = payload =>
    handleApi(Axios.get(API_ENDPOINTS.GET_WALLET_ACTIVITY_EXPORT_CSV, {params: payload, headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));

export const getTransationCSV = payload =>
    handleApi(Axios.get(API_ENDPOINTS.GET_SALES_TRANSATION_EXPORT_CSV, {params: payload, headers: {Authorization: `Token ${Cookies.get('access_token')}`}}));
