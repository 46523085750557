import {Container, Row, Col} from 'react-bootstrap';
import {Button, Calculator, TextField} from '../../../common';
import {useEffect, useRef, useState} from 'react';
import {useLanguageContext, useThemeContext} from '../../../../context';
import PosAppModal from '../PosAppModal';
import {DEVICE_HEIGHT, formatNum} from '../../../../constants';
import useFocus from '../../../../hooks/useFocus';
import Icon from '../../../../assets/icons';

const ItemDiscountsModal = ({toggle, setToggle, handleSave, selectedItem, showDiscountType, title, secondaryTitle, from}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [type, setType] = useState();
    const [value, setValue] = useState(0);
    const [errors, setErrors] = useState({});
    const {textBoxRef} = useFocus();

    useEffect(() => {
        let paymentType;
        let paymentAmount;

        if (!selectedItem) {
            paymentType = 'amount';
            paymentAmount = 0;
        } else if (from === 'customer') {
            paymentType = 'amount';
            paymentAmount = selectedItem?.total_credit;
        } else if (from === 'item') {
            paymentType = selectedItem?.product_level_discount?.discountType;
            paymentAmount = selectedItem?.product_level_discount?.discountAmount;
        } else {
            paymentType = selectedItem?.discount_type || 'amount';
            paymentAmount = selectedItem?.discount || 0;
        }
        setType(paymentType);
        setValue(paymentAmount);
    }, [selectedItem, toggle]);

    const handleClose = event => {
        setToggle(false);
    };
    useEffect(() => {
        let valueToCompare;
        if (from === 'customer') {
            valueToCompare = selectedItem?.total_credit;
        } else if (from === 'item') {
            valueToCompare = selectedItem?.product_level_discount?.discountAmount;
        } else {
            valueToCompare = selectedItem?.selling_price;
        }

        if (value > 100 && type == 'percentage') {
            setErrors({
                textBox: 'Percentage must be less than 100',
            });
        } else if (selectedItem && value > valueToCompare && type == 'amount') {
            setErrors({
                textBox: from === 'customer' ? 'Amount is greater than credit amount' : 'Discount must be less than Amount',
            });
        } else {
            setErrors({});
        }
    }, [value]);

    const onChange = (label, val) => {
        if (from === 'dinein') {
            if (label == I18n.guests) {
                const formattedValue = val.replace(/^0+/, '') || '0';
                setValue(formattedValue);
            }
        } else {
            if (label == I18n.rupees) setValue(val);
            else if (label == I18n.percentage) setValue(val);
        }
    };

    const handleType = val => {
        if (val !== type) {
            setType(val);
            setValue(0);
        }
    };

    const handleDone = () => {
        handleSave(type, value);
    };
    const handleCancel = () => {
        if (from === 'dinein') {
            handleSave('', 0);
        }
        handleClose();
    };

    const handleEnterPress = event => {
        if (event.key === 'Enter' && value) {
            handleDone();
            handleClose();
        }
    };

    return (
        <PosAppModal
            className="selectCustomerModal"
            toggle={toggle}
            backCta={{
                name: 'backArrowIcon',
                fill: theme.barclaysBlue,
                width: '18',
                height: '18',
                viewBox: '0 0 18 18',
            }}
            handleClose={handleClose}
            bottomSaveCta={{
                title: from === 'dinein' ? I18n.continue : from === 'customer' ? I18n.confirm : I18n.save,
                action: () => handleDone(),
                disabled: value < 0 || !Boolean(value) || Object.keys(errors)?.length,
            }}
            bottomCancelCta={{
                title: from === 'dinein' ? I18n.skip : I18n.cancel,
                action: () => handleCancel(),
            }}
            title={title || I18n.back}>
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={8}>
                        <div
                            className="itemLevelPopupMain"
                            style={{
                                height: DEVICE_HEIGHT - 200,
                            }}
                            onKeyDown={handleEnterPress}>
                            <div className="discoountButtonInputBox">
                                {showDiscountType && (
                                    <div className="selectDiscountButton">
                                        <Button
                                            className={`rupeesDiscount fontWeight400 fontSize22 cursorPointer`}
                                            backgroundColor={type == 'amount' ? theme.barclaysBlue : theme.seperatorTwo}
                                            txtColor={theme.white}
                                            title={I18n.rupees}
                                            handleClick={() => handleType('amount')}
                                        />
                                        <Button
                                            className={`percentDiscount  fontWeight400 fontSize22 cursorPointer`}
                                            backgroundColor={type == 'percentage' ? theme.barclaysBlue : theme.seperatorTwo}
                                            txtColor={theme.white}
                                            title={I18n.percentage}
                                            handleClick={() => handleType('percentage')}
                                        />
                                    </div>
                                )}

                                <div className={errors?.textBox ? 'discountTextFeildBox discountErrorTextFeildBox' : 'discountTextFeildBox'}>
                                    {from == 'customer' && (
                                        <p className="fontSize20 marBot15 spaceBetweenCenter" style={{color: theme.white}}>
                                            <span>{I18n.credit_amount}</span>
                                            <span className="fontWeight500">{`Rs. ${formatNum(selectedItem?.total_credit || 0)}`}</span>
                                        </p>
                                    )}
                                    {from == 'dinein' && (
                                        <div className="flex spaceBetweenCenter">
                                            <p className="fontSize20 marBot5" style={{color: theme.white}}>
                                                {title}
                                            </p>
                                            <p className="fontSize14 marBot0" style={{color: theme.white}}>
                                                {from === 'dinein' && (
                                                    <span>
                                                        <Icon name="editIcon" fill={theme.barclaysBlue} width="18" height="18" />
                                                    </span>
                                                )}

                                                {secondaryTitle}
                                            </p>
                                        </div>
                                    )}
                                    <TextField
                                        onChange={onChange}
                                        label={from === 'dinein' ? I18n.guests : type == 'amount' ? I18n.rupees : I18n.percentage}
                                        value={value}
                                        maxLength={6}
                                        error={errors?.textBox}
                                        autoFocus={true}
                                        type="numeric"
                                        inputRef={textBoxRef}
                                    />
                                </div>
                            </div>
                            <div className="billScreenCalculator">
                                <Calculator className={`width55`} maxLength={6} columnFour={false} setState={setValue} textBoxRef={textBoxRef} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PosAppModal>
    );
};

export default ItemDiscountsModal;
