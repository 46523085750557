import React from 'react';
import { useAuthContext, useLanguageContext, useThemeContext } from '../../../../../../context';
import { formatDateAndTime, formatNum } from '../../../../../../constants';
import StatusBox from '../../../../../common/StatusBox';
import Icon from '../../../../../../assets/icons';
import PaymentTimeline from '../../../Payments/Transactions/TransactionDetail/PaymentTimeline';
import { useNavigate } from 'react-router-dom';

const PayoutTransaction = ({ data }) => {
    const { I18n } = useLanguageContext();
    const { theme } = useThemeContext();
    const { business } = useAuthContext();
    let navigate = useNavigate();

    const { type, amount, status, timeline, sender_details, beneficiary_details, refund_transaction_id } = data || {};

    const handleNavigate = id => {
        if (id && business?.id) navigate(`/activity/${id}`, { state: { businessId: business?.id } });
    };

    let stepdata = [
        timeline.payout_failed
            ? {
                iconName: 'crossIcon',
                viewBox: '0 0 18 18',
                width: 18,
                height: 18,
                stepLebal: I18n.payout_failed,
                details: formatDateAndTime(timeline.payout_failed),
                fill: theme.red,
            }
            : null,
        timeline.payout_succeeded
            ? {
                iconName: 'tickCircleIcon',
                viewBox: '0 0 18 18',
                width: 18,
                height: 18,
                stepLebal: I18n.payout_succeeded,
                details: formatDateAndTime(timeline.payout_succeeded),
                fill: theme.brightGreen,
            }
            : null,
        timeline.payout_in_processing
            ? {
                iconName: 'pendingPaymentIcon',
                viewBox: '0 0 18 18',
                width: 15,
                height: 15,
                stepLebal: I18n.payout_in_processing,
                details: formatDateAndTime(timeline.payout_in_processing),
                fill: theme.white,
            }
            : null,
    ].filter(Boolean);

    return (
        <div className="transactionDetailWrapper">
            <div className="transactionDetailAmountSection">
                <div className="transactionDetailAmountTop">
                    <p className="marBot5 fontSize16" style={{ color: theme.darkGrayTwo }}>
                        {I18n.amount}
                    </p>
                    <p className="fontSize24 fontWeight600 marBot0 rowConatainer" style={{ color: theme.white }}>
                        <span className="marRight10"> {`Rs. ${formatNum(amount || 0)}`} </span> <StatusBox status={type} />
                    </p>
                </div>
                <div className="transactionDetailAmountStatus marBot20 marTop20 ">
                    <StatusBox status={status} />
                </div>
                <hr className="detailSeperator" />
                <h3 className="fontSize16 fontWeight700" style={{ color: theme.white }}>
                    {I18n.timeline}
                </h3>
                <PaymentTimeline detais={stepdata} />

                {refund_transaction_id && (
                    <>
                        <hr className="detailSeperator" />
                        <div className="transactionDetailItemPurchased">
                            <h3 className="fontSize16 fontWeight700" style={{ color: theme.white }}>
                                {I18n.refund_transaction}
                            </h3>
                            <div className="detailItemPurchasedInner">
                                <p className="fontSize12 fontWeight600" style={{ color: theme.lightGrayTwo }}>
                                    {I18n.transaction_id}
                                </p>
                                <p
                                    onClick={() => handleNavigate(refund_transaction_id)}
                                    className="fontSize14 fontWeight400 cursorPointer"
                                    style={{ color: theme.barclaysBlue }}>
                                    <span className="marRight5"> {refund_transaction_id || '--'}</span>
                                    <Icon name="payoutNewIcon" viewBox={'0 0 18 18'} width={'14'} height={'14'} fill={theme.barclaysBlue} />
                                </p>
                            </div>
                        </div>
                    </>
                )}

                {beneficiary_details ? (
                    <>
                        <hr className="detailSeperator" />

                        <div className="transactionDetailItemPurchased">
                            <h3 className="fontSize16 fontWeight700" style={{ color: theme.white }}>
                                {I18n.payment_sent_to}
                            </h3>

                            <div className="detailItemPurchasedInner">
                                <p className="fontSize12 fontWeight600" style={{ color: theme.lightGrayTwo }}>
                                    {I18n.account_title}
                                </p>
                                <p className="fontSize14 fontWeight400" style={{ color: theme.white }}>
                                    {beneficiary_details?.account_title || '--'}
                                </p>
                            </div>

                            <div className="detailItemPurchasedInner">
                                <p className="fontSize12 fontWeight600" style={{ color: theme.lightGrayTwo }}>
                                    {I18n.bank_details}
                                </p>

                                <p className="flexRow" style={{ color: theme.white }}>
                                    {beneficiary_details?.bank_logo && <img src={beneficiary_details?.bank_logo} alt="bankLogo" height={20} width={20} />}
                                    <span className="fontSize14 fontWeight400 marLeft5 marBot0">{beneficiary_details?.bank_name || '--'}</span>
                                </p>
                            </div>

                            <div className="detailItemPurchasedInner">
                                <p className="fontSize12 fontWeight600" style={{ color: theme.lightGrayTwo }}>
                                    {I18n.account_hash}
                                </p>
                                <p className="fontSize14 fontWeight400" style={{ color: theme.white }}>
                                    {beneficiary_details?.account_number || '--'}
                                </p>
                            </div>
                        </div>
                        <hr className="detailSeperator" />
                    </>
                ) : null}

                {sender_details ? (
                    <>
                        <div className="transactionDetailItemPurchased">
                            <h3 className="fontSize16 fontWeight700" style={{ color: theme.white }}>
                                {I18n.payment_sent_from}
                            </h3>

                            <div className="detailItemPurchasedInner">
                                <p className="fontSize12 fontWeight600" style={{ color: theme.lightGrayTwo }}>
                                    {I18n.account_title}
                                </p>
                                <p className="fontSize14 fontWeight400" style={{ color: theme.white }}>
                                    {sender_details?.account_title || '--'}
                                </p>
                            </div>

                            <div className="detailItemPurchasedInner">
                                <p className="fontSize12 fontWeight600" style={{ color: theme.lightGrayTwo }}>
                                    {I18n.bank_details}
                                </p>
                                <p className="flexRow" style={{ color: theme.white }}>
                                    {sender_details?.bank_logo && <img src={sender_details.bank_logo} alt="bankLogo" height={20} width={20} />}
                                    <span className="fontSize14 fontWeight400 marLeft5 marBot0">{sender_details?.bank_name || '--'}</span>
                                </p>
                            </div>

                            <div className="detailItemPurchasedInner">
                                <p className="fontSize12 fontWeight600" style={{ color: theme.lightGrayTwo }}>
                                    {I18n.account_hash}
                                </p>
                                <p className="fontSize14 fontWeight400" style={{ color: theme.white }}>
                                    {sender_details?.account_number || '--'}
                                </p>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default PayoutTransaction;
