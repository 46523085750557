import React from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../../context';
import {formatDateAndTime, formatNum} from '../../../../../../constants';
import StatusBox from '../../../../../common/StatusBox';
import Icon from '../../../../../../assets/icons';
import PaymentTimeline from '../../../Payments/Transactions/TransactionDetail/PaymentTimeline';
import {useNavigate} from 'react-router-dom';

const RefundTransaction = ({data}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business} = useAuthContext();
    let navigate = useNavigate();

    const {type, failed_transaction, timeline, amount, status} = data || {};

    let stepdata = [
        {
            iconName: 'refundIcon',
            viewBox: '0 0 18 18',
            width: 18,
            height: 18,
            stepLebal: I18n.refund_succeeded,
            details: formatDateAndTime(timeline.succeeded),
            fill: theme.brightOrange,
        },
        {
            iconName: 'pendingPaymentIcon',
            viewBox: '0 0 18 18',
            width: 15,
            height: 15,
            stepLebal: I18n.refund_in_processing,
            details: formatDateAndTime(timeline.processing),
            fill: theme.white,
        },
    ].filter(Boolean);

    const handleNavigate = id => {
        if (id && business?.id) {
            navigate(`/activity/${id}`, {state: {businessId: business?.id}});
        }
    };
    return (
        <div className="transactionDetailWrapper">
            <div className="transactionDetailAmountSection">
                <div className="transactionDetailAmountTop">
                    <p className="marBot5 fontSize16" style={{color: theme.darkGrayTwo}}>
                        {I18n.amount}
                    </p>

                    <p className="fontSize24 fontWeight600 marBot0 rowConatainer" style={{color: theme.white}}>
                        <span className="marRight10"> {`Rs. ${formatNum(amount || 0)}`} </span> <StatusBox status={type} />
                    </p>
                </div>
                <div className="transactionDetailAmountStatus marBot20 marTop20 ">
                    <StatusBox status={status} />
                </div>
                <hr className="detailSeperator" />
                <h3 className="fontSize16 fontWeight700" style={{color: theme.white}}>
                    {I18n.timeline}
                </h3>
                <PaymentTimeline detais={stepdata} />
                <hr className="detailSeperator" />

                <div className="transactionDetailItemPurchased">
                    <h3 className="fontSize16 fontWeight700" style={{color: theme.white}}>
                        {I18n.initial_failed_transaction}
                    </h3>
                    <div className="detailItemPurchasedInner">
                        <p className="fontSize12 fontWeight600" style={{color: theme.lightGrayTwo}}>
                            {I18n.transaction_id}
                        </p>
                        <p
                            onClick={() => handleNavigate(failed_transaction)}
                            className="fontSize14 fontWeight400 cursorPointer"
                            style={{color: failed_transaction ? theme.barclaysBlue : theme.white}}>
                            <span className="marRight5"> {failed_transaction || '--'}</span>
                            <Icon name="payoutNewIcon" viewBox={'0 0 18 18'} width={'14'} height={'14'} fill={theme.barclaysBlue} />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RefundTransaction;
