import {Spinner} from 'react-bootstrap';
import Icon from '../../../assets/icons';
import {useThemeContext} from '../../../context';

const SecondaryButton = ({
    value,
    className,
    saveLoad,
    title,
    disabled,
    backgroundColor,
    borderColor,
    handleClick,
    buttonIcon,
    txtColor,
    icon_name,
    icon_fill,
    icon_height,
    icon_width,
    icon_viewBox,
    ...rest
}) => {
    const {theme} = useThemeContext();

    return (
        <button
            value={value}
            className={`buttonStyle ${className}`}
            disabled={disabled}
            style={{
                backgroundColor: disabled ? theme.seperatorTwo : backgroundColor || theme.brightGreen,
                borderColor: disabled ? theme.seperatorTwo : borderColor || backgroundColor || theme.brightGreen,
                color: disabled ? theme.darkGrayTwo : txtColor || theme.white,
            }}
            onClick={handleClick}
            {...rest}>
            {buttonIcon ? (
                <span className="buttonIcon">
                    <Icon name={icon_name} fill={icon_fill} height={icon_height || '19'} width={icon_width || '20'} viewBox={icon_viewBox || '0 0 16 15'} />
                </span>
            ) : null}

            {saveLoad ? <Spinner animation="border" /> : title}
        </button>
    );
};

export default SecondaryButton;
