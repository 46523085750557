import React, { useState, useEffect } from 'react';
import { CustomContainer, Loading, NonIdealScreen } from '../../../../../common';
import { Col, Container, Row } from 'react-bootstrap';
import { NewPosHeader } from '../../../../../common/V2';
import { DEVICE_HEIGHT, formatNum } from '../../../../../../constants';
import { useLanguageContext, useThemeContext, useAuthContext } from '../../../../../../context';
import { useNavigate, useParams } from 'react-router-dom';
import DepositBreakDownList from './DepositBreakDownList';
import TableHeader from '../../../../../common/TableContainer/TableHeader';
import { getTransactionBreakDown } from '../../../../../../api';

const DepositBreakDown = () => {
    const { I18n } = useLanguageContext();
    const { theme } = useThemeContext();
    const { business } = useAuthContext();
    let navigate = useNavigate();

    const [data, setData] = useState({
        total: 0,
        transactionCount: 0,
        data: [],
    });
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        handleTransactionData();
    }, []);

    async function handleTransactionData() {
        try {
            let params = {
                business_id: business?.id,
                transaction_id: id,
            };
            const response = await getTransactionBreakDown(params);
            if (response.success) {
                setData({
                    total: response?.data.total_amount,
                    percentage_rate: response?.data.fee_rate,
                    transactionCount: response?.data?.transactions_count,
                    data: response?.data?.report,
                });
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log('Failed to fetch transaction BreakDown data:', error);
        }
    }

    const handleNavigate = (id, bussiess_id) => {
        navigate(`/payments-transactions/${id}`, { state: { businessId: bussiess_id, screenName: "deposit_breakdown" } });
    }

    return (
        <section className="transactionDetailWrapper" style={{ backgroundColor: theme.topBarBG, padding: "10px 15px" }}>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <NewPosHeader
                        backBtnCta={true}
                        title={{
                            name: `Payment breakdown | ${id}`,
                        }}
                    />
                    <div className="transactiontInnerBox">
                        <Container fluid>
                            <Row>
                                <Col md={12} xs={12}>

                                    <>
                                        <TableHeader className="fourColumn" style={{ borderColor: theme.inputBorder }}>
                                            <div className="BoxWidth justifyStart">
                                                <p className="fontSize16 fontWeight500 OneLineTruncate" style={{ color: theme.white }}>
                                                    {I18n.transaction_id}
                                                </p>
                                            </div>
                                            <div className="BoxWidth justifyCenter">
                                                <p className="fontSize16 fontWeight500 OneLineTruncate" style={{ color: theme.white }}>
                                                    {I18n.gross_amount}
                                                </p>
                                            </div>
                                            <div className="BoxWidth justifyCenter">
                                                <p className="fontSize16 fontWeight500 OneLineTruncate" style={{ color: theme.white }}>
                                                    {`${I18n.fees} (${data.percentage_rate}%)`}
                                                </p>
                                            </div>
                                            <div className="BoxWidth justifyCenter">
                                                <p className="fontSize16 fontWeight500 OneLineTruncate" style={{ color: theme.white }}>
                                                    {I18n.net_amount}
                                                </p>
                                            </div>
                                        </TableHeader>
                                        <div style={{ height: DEVICE_HEIGHT - 290, overflow: 'auto' }} className="tableListMainBox">
                                            {data.data?.map((item, index) => (
                                                <DepositBreakDownList item={item} index={index} key={index} handleNavigate={handleNavigate} />
                                            ))}
                                        </div>
                                    </>

                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="breakdowntotalBox">
                        <p className="fontSize18 fontWeight500 marBot0" style={{ color: theme.white }}>
                            {`${I18n.payment} (${data?.transactionCount || 0})`}
                        </p>
                        <p className="fontSize18 fontWeight500 marBot0" style={{ color: theme.white }}>
                            {`${I18n.total_of_net_amount} : Rs. ${formatNum(data?.total || 0)}`}
                        </p>
                    </div>
                </>
            )}
        </section>
    );
};

export default DepositBreakDown;
