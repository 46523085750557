import {useState} from 'react';
import {CustomContainer} from '../../../../common';
import CreateBeneficiaryComponent from '../../../../common/CreateBeneficiaryComponent';
import ConfirmBeneficiaryDetails from './ConfirmBeneficiaryDetails';
import {useLocation} from 'react-router-dom';

const CreateBeneficiary = () => {
    const [current, setCurrent] = useState({step: 1, data: null});
    const {state} = useLocation();

    return (
        <>
            <CustomContainer reduceHeight={45} className="addScreenMainBox  createCustomerContainer">
                {current.step === 1 ? (
                    <CreateBeneficiaryComponent current={current} setCurrent={setCurrent} />
                ) : (
                    <ConfirmBeneficiaryDetails current={current} setCurrent={setCurrent} from={state?.screenName || 'beneficiaryPage'} />
                )}
            </CustomContainer>
        </>
    );
};

export default CreateBeneficiary;
