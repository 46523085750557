import React, {useEffect, useState} from 'react';
import AppModal from '../AppModal';
import {useLanguageContext, useThemeContext} from '../../../context';
import Loading from '../Loading';
import {TextField, ToastBox} from '../../common';
import NewAppModal from '../../common/NewAppModal';
import CreateTable from '../../screens/Authenticated/Restaurant/PosCockpit/Dinein/CreateTable';
import RadioButton from '../RadioButton';
import Icon from '../../../assets/icons';
import {uuid, validateTableData} from '../../../constants';
import Cockpit from '../../screens/Authenticated/Restaurant/PosCockpit/Cockpit';
import Dinein from '../../screens/Authenticated/Restaurant/PosCockpit/Dinein';

const SelectFloorPlanModal = ({toggle, setToggle, floorPlans, setTabComponent, setTabVal}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [floorType, setFloorType] = useState('');
    const [plans, setPlans] = useState(floorPlans);

    const addlabelsToPlan = () => {
        const modifiedPlans = floorPlans?.map(item => ({
            ...item,
            label: (
                <div className="selectFloorPlanWrapper">
                    <p className="marBot0 marTop0 textCenter fontSize14 fontWeight400 white">{item.name}</p>
                </div>
            ),
            value: item.name,
        }));

        setPlans(modifiedPlans);
    };

    useEffect(() => {
        addlabelsToPlan();
    }, [floorPlans]);

    function handleClose(event) {
        setToggle(false);
        setFloorType('');
    }

    const handleChange = async e => {
        const value = e?.target?.value || e;

        console.log('value', value);
        setFloorType(value);
    };

    const hanldePlanSelect = () => {
        const selectedPlan = floorPlans.find(plan => plan.name === floorType);
        const selectedIndex = floorPlans.indexOf(selectedPlan);
        setTabComponent(selectedPlan);
        setTabVal(selectedIndex + 1);
        setToggle(false);
        setFloorType('');
    };

    return (
        <>
            <NewAppModal
                className="deliveryFloorModal modal-backdrop-custom"
                toggle={toggle}
                backCta={{
                    name: 'backArrowIcon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }}
                handleClose={() => handleClose()}
                primaryCta={{
                    title: 'Continue',
                    action: () => hanldePlanSelect(true),
                }}
                cancelCta={{
                    title: I18n.cancel,
                    action: () => handleClose(),
                }}
                title="Select floor plan">
                <div className="flex horizontalCenter width100">
                    <div className="flex width50" style={{flexDirection: 'column'}}>
                        <p className="fontSize20 marBot20" style={{color: theme.white}}>
                            Floor plans
                        </p>

                        <RadioButton options={plans} className="floorPlansWrapper" selectedSize={floorType} handleChange={handleChange} showCustomBtn />
                    </div>
                </div>
            </NewAppModal>
        </>
    );
};

export default SelectFloorPlanModal;
