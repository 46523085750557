import {Row, Col, ProgressBar} from 'react-bootstrap';
import {CustomContainer, NonIdealScreen, ToastBox} from '../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../context';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import CsvUploadModal from '../../../common/CsvUploadModal';
import {RESTAURANT} from '../../../../constants';

const Onboarding = ({productCount, customerCount}) => {
    const navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {state} = useLocation();

    const [cvsModal, setCvsModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const {renderToast, setRenderToast, business, account} = useAuthContext();
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });
    const [steps, setSteps] = useState([
        {
            icon: 'itemNewIcon',
            title: I18n.set_up_items,
            description: I18n.before_you_open_your_business_first_you_need_some_items,
            primaryCta: {
                title: I18n.import_items,
                action: togglecsvModal,
            },
            secondaryCta: {
                title: I18n.create_item,
                action: handleCreateItem,
            },
            skipText: {
                title: I18n.skip_this_step,
                action: () => toggleIndex(1),
            },
        },
        {
            icon: 'customerSquareIcon',
            title: I18n.customer,
            description: I18n.create_customer_and_add_them_into_sales,

            primaryCta: {
                title: I18n.import_customers,
                action: togglecsvModal,
            },
            secondaryCta: {
                title: I18n.create_customer,
                action: handleCreateCustomer,
            },
            skipText: {
                title: I18n.skip_this_step,
                action: () => toggleIndex(2),
            },
        },
        {
            icon: 'teamNewIcon',
            title: I18n.set_up_team,
            description: I18n.set_up_your_team_members,
            secondaryCta: {
                title: I18n.create_member,
                action: handleCreateMember,
            },
            primaryCta: {
                title: I18n.go_to_pos,
                action: handleNavigatTopos,
            },
        },
    ]);

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (renderToast === 'onboarding_item_created') {
            setShowMessage({
                visible: true,
                message: I18n.item_created_successfully,
            });
            setActiveIndex(1);
        } else if (renderToast === 'onboarding_customer_created') {
            setShowMessage({
                visible: true,
                message: I18n.customer_created_successfully,
            });
            setActiveIndex(2);
        } else if (renderToast === I18n.modifier_created_successfully) {
            setShowMessage({
                visible: true,
                message: I18n.modifier_created_successfully,
            });
            setActiveIndex(2);
        } else {
            if (state?.screenName === 'items') {
                setActiveIndex(0);
            }
            if (state?.screenName === 'modifiers' || state?.screenName === 'customers') {
                setActiveIndex(1);
            }
            if (state?.screenName === 'teams') {
                setActiveIndex(2);
            }
            console.log('state', state);
        }
    }, [renderToast]);

    const toggleIndex = val => setActiveIndex(val);

    function handleCreateItem() {
        navigate('/items/create', {
            state: {screenName: 'is_onboarding'},
        });
    }

    function togglecsvModal() {
        setCvsModal(x => !x);
    }

    function handleCreateMember() {
        navigate('/team/create', {
            state: {screenName: 'is_onboarding'},
        });
    }

    function handleCreateCustomer() {
        navigate('/customers/create', {
            state: {screenName: 'is_onboarding'},
        });
    }

    function handleCreateModifier() {
        navigate('/modifiers/create', {
            state: {screenName: 'is_onboarding'},
        });
    }

    function handleNavigatTopos() {
        if (business.type === RESTAURANT) {
            navigate('/pos-cockpit');
        } else {
            navigate('/pos');
        }
    }

    const modifier_step = {
        icon: 'modifierIcon',
        viewBox: '0 0 100 100',
        title: I18n.setup_modifiers,
        description: I18n.setup_modifiers_and_customize,
        secondaryCta: {
            title: I18n.create_modifiers,
            action: handleCreateModifier,
        },
        skipText: {
            title: I18n.skip_this_step,
            action: () => toggleIndex(2),
        },
    };

    useEffect(() => {
        if (business.type === RESTAURANT) {
            const temp = [...steps];
            temp[1] = modifier_step;
            setSteps(temp);
        }
    }, [business]);

    return (
        <CustomContainer className="allScreenNonIdealBox">
            <Row className="justify-content-md-center">
                <Col md={4} className="progressbarMainBox">
                    <div className="progressbarMainBoxInner">
                        <ProgressBar onClick={() => toggleIndex(0)} now={activeIndex == 0 ? 100 : 0} />
                        <ProgressBar onClick={() => toggleIndex(1)} now={activeIndex == 1 ? 100 : 0} />
                        <ProgressBar onClick={() => toggleIndex(2)} now={activeIndex == 2 ? 100 : 0} />
                    </div>
                </Col>

                <CsvUploadModal setToggle={setCvsModal} toggle={cvsModal} type={activeIndex === 0 ? 'item' : activeIndex === 1 ? 'customer' : ''} />

                <NonIdealScreen
                    heading={steps[activeIndex].title}
                    subHeading={steps[activeIndex].description}
                    name={steps[activeIndex].icon}
                    fill={theme.white}
                    skipText={steps[activeIndex].skipText}
                    primaryCta={steps[activeIndex].primaryCta}
                    secondaryCta={steps[activeIndex].secondaryCta}
                    isMultiStep={true}
                    viewBox={steps[activeIndex]?.viewBox}
                />
                <ToastBox className="addSuccessfullyToast" ToastVisiable={showMessage.visible} bodyPara={showMessage.message} setShowMessage={setShowMessage} showIcon={true} />
            </Row>
        </CustomContainer>
    );
};

export default Onboarding;
