import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Icon from '../../../../../../assets/icons';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../../context';
import {Button, CheckBox, CustomContainer, Header, NonIdealScreen, TextField} from '../../../../../common';
import Modal from '../../Modal';
import ItemList from './ItemList';
import AddModifierList from './AddModifierList';
import {
    DEVICE_HEIGHT,
    filteredObjects,
    filterOptions,
    filterProductRelation,
    INITIAL_PAGE,
    ITEMS_PER_PAGE,
    toSnakeCase,
    uuid,
    validateModifierForm,
} from '../../../../../../constants';
import {observeActiveProducts, queryItems} from '../../../../../../pos-core/database/helpers';
import withObservables from '@nozbe/with-observables';
import {bulkCreateModifierOptions, bulkDeleteModifierOptions, bulkUpdateModifierOptions} from '../../../../../../pos-core/database/helpers/modifier_option';
import {bulkCreateProductModifierRelation, bulkUpdateProductModifierRelation} from '../../../../../../pos-core/database/helpers/product_modifier';
import Pagination from '@mui/material/Pagination';
import {Col, Row} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

const CreateModifier = ({activeProducts, selectedModifier}) => {
    const {state} = useLocation();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {account, setRenderToast, persistData, setPersistData} = useAuthContext();
    const [errors, setErrors] = useState({});
    const [modifierName, setModifierName] = useState('');
    const [modifier, setModifier] = useState([{id: uuid(), modifier_name: '', price: '', isChecked: true}]);
    const [isPrintInReceipt, setIsPrintInReceipt] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(true);
    const [search, setSearch] = useState('');
    let navigate = useNavigate();

    const togglePrint = () => setIsPrintInReceipt(x => !x);

    const closeModal = () => {
        setSelectedItems([]);
        setToggle(false);
    };

    const saveModal = () => {
        setSelectedItems(prev => [...prev]);
        setToggle(false);
    };

    const onChangeSearch = async (event, key) => {
        let value = key || event?.target?.value || '';
        setSearch(value);
        const {data, totalCount} = await queryItems('', value.toLowerCase(), INITIAL_PAGE, ITEMS_PER_PAGE, false);
        setProducts(data);
        setIsFetching(false);
        setCurrentPage(INITIAL_PAGE);
        setHasMore(totalCount > ITEMS_PER_PAGE);
    };

    const onChange = (label, value) => {
        if (label == I18n.modifier_name) setModifierName(value);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const emptyForm = () => {
        setModifierName('');
        setIsPrintInReceipt(false);
        setModifier([]);
        setSelectedItems([]);
    };

    const handleAddModifier = () => {
        const initialzieModifier = {
            id: uuid(),
            modifier_name: '',
            price: '',
            isChecked: true,
        };
        setModifier(prev => [...prev, initialzieModifier]);
    };

    const handleDeleteModifier = id => {
        const filteredModifiers = modifier.filter(item => item.id != id);
        setModifier(filteredModifiers);
    };

    const updateListItem = (label, val, id) => {
        const updatedItem = modifier?.map(allItem => {
            if (allItem.id === id) {
                return {
                    ...allItem,
                    [label]: val,
                };
            }
            return allItem;
        });
        setModifier(updatedItem);
    };

    const handleConfirm = () => {
        handlePersistData();
        navigate('/items/create', {state: {screenName: 'create_modifier'}});
    };

    const handleSave = async () => {
        try {
            const formData = {modifierName, selectedItems, modifier};
            const formErrors = validateModifierForm(formData);
            console.log('form errors', formErrors);
            setErrors(formErrors);
            if (Object.keys(formErrors).length > 0) return;

            const paramsForModifier = {
                name: modifierName,
                print_in_receipt: isPrintInReceipt,
            };

            if (selectedModifier) {
                const modifierOpt = await selectedModifier?.modifier_option.fetch();
                const prodModifier = await selectedModifier?.product_modifier.fetch();
                const cloneOpt = [...modifierOpt];
                const {additionArray, deletionArray, updationArray} = filterOptions(cloneOpt, modifier, 'modifiers');
                const prods = await Promise.all(prodModifier.map(async prodMod => await prodMod.product.fetch()));
                const cloneProd = [...prods];
                const {additionProdArray, deletionProdArray} = filterProductRelation(cloneProd, selectedItems);

                await selectedModifier.updateModifierDetails(paramsForModifier);

                await bulkCreateModifierOptions(selectedModifier, additionArray);
                await bulkUpdateModifierOptions(updationArray);
                await bulkDeleteModifierOptions(deletionArray);

                await bulkCreateProductModifierRelation(selectedModifier, additionProdArray);
                await bulkUpdateProductModifierRelation(selectedModifier, deletionProdArray);
            } else {
                let createdModifier = await account.createModifier(paramsForModifier);
                await bulkCreateModifierOptions(createdModifier, modifier);
                await bulkCreateProductModifierRelation(createdModifier, selectedItems);
            }

            emptyForm();
            const toastMessage = selectedModifier ? I18n.modifier_updated_successfully : I18n.modifier_created_successfully;
            setRenderToast(toastMessage);
            setPersistData(null);
            if (state?.screenName == 'is_onboarding') {
                navigate('/onboarding');
            } else {
                navigate('/modifiers');
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const removeSelectedItem = clickedItem => {
        const filterList = selectedItems.filter(elem => elem?.item?.id !== clickedItem.item.id);
        setSelectedItems(filterList);
    };

    const fetchMoreData = () => {
        setIsFetching(true);
        setCurrentPage(prevPage => prevPage + 1);
    };

    const setInitialValues = async () => {
        if (selectedModifier) {
            const modifierOpt = await selectedModifier?.modifier_option.fetch();
            const prodModifier = await selectedModifier?.product_modifier.fetch();

            const prods = await Promise.all(
                prodModifier.map(async prodMod => ({
                    isChecked: true,
                    item: await prodMod.product.fetch(),
                })),
            );

            const temp = modifierOpt.map(item => ({
                id: item.id,
                price: item.price,
                modifier_name: item.name,
                isChecked: item.in_stock,
            }));
            setModifierName(selectedModifier?.name);
            setIsPrintInReceipt(selectedModifier?.print_in_receipt);
            setModifier(temp);
            setSelectedItems(prods);
        } else if (Object.keys(persistData?.modifier)?.length > 0) {
            setModifierName(persistData?.modifier?.modifierName_data);
            setIsPrintInReceipt(persistData?.modifier?.isPrintInReceipt_data);
            setModifier(persistData?.modifier?.modifier_option);
            setSelectedItems(persistData?.modifier?.product_modifier);
        }
    };

    const handlePersistData = () => {
        let modifierPersistData = {
            modifierName_data: modifierName,
            isPrintInReceipt_data: isPrintInReceipt,
            modifier_option: modifier,
            product_modifier: selectedItems,
        };
        setPersistData({modifier: modifierPersistData});
    };

    const fetchData = async pageNum => {
        const {data} = await queryItems('', search.toLowerCase(), pageNum, ITEMS_PER_PAGE, false);
        if (data.length === 0) {
            setHasMore(false);
        } else {
            setProducts(prevData => [...prevData, ...data]);
        }
        setIsFetching(false);
    };

    const handleBack = () => {
        if (state?.screenName == 'is_onboarding') {
            navigate('/onboarding', {state: {screenName: 'modifiers'}});
        } else {
            navigate('/modifiers');
        }
        setPersistData(null);
    };

    useEffect(() => {
        if (selectedModifier || Object?.keys(persistData?.modifier || {})?.length > 0) {
            setInitialValues();
        }
    }, [selectedModifier, persistData?.modifier]);

    useEffect(() => {
        if (isFetching) {
            fetchData(currentPage);
        }
    }, [currentPage, isFetching]);

    return (
        <div>
            <Header
                type="draftHeader"
                title={I18n.back}
                saveCta={{
                    title: I18n.save,
                    action: () => handleSave(),
                    // saveLoad: btnLoading,
                }}
                backAction={handleBack}
            />

            <CustomContainer reduceHeight={110} className="addScreenMainBox">
                <Row className="justify-content-md-center">
                    <Col md={7}>
                        <div className="width100 flex verticalCenter horizontalCenter">
                            <div className="width100 marTop10">
                                <p className="marBot20 fontSize24 fontWeight600 textCenter" style={{color: theme.white}}>
                                    {I18n.create_modifier}
                                </p>

                                <TextField
                                    onChange={onChange}
                                    error={errors?.['modifier_name']}
                                    label={I18n.modifier_name}
                                    placeholder={I18n.modifier_name}
                                    value={modifierName}
                                    maxLength={100}
                                    required={true}
                                />

                                <div className="">
                                    <CheckBox
                                        checkboxLabel={
                                            <p className="marBot0 fontSize14" style={{color: theme.text}}>
                                                {I18n.print_in_receipt}
                                            </p>
                                        }
                                        onChange={togglePrint}
                                        checked={isPrintInReceipt}
                                    />
                                </div>

                                <div className="divider width100 marTop10" />
                                <p className="marTop20 marBot20 fontSize20 fontWeight400" style={{color: theme.white}}>
                                    {I18n.apply_modifier_on_these_items}
                                </p>

                                <div className="addTableDivWrapper" onClick={() => setToggle(true)}>
                                    <Icon name={'add2Icon'} width="24" height="24" viewBox={'0 0 18 18'} fill={theme.barclaysBlue} />
                                    <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.barclaysBlue}}>
                                        {I18n.add_items}
                                    </p>
                                </div>
                                {errors?.items && <p className="inputError">{errors.items}</p>}

                                {selectedItems?.length > 0 && (
                                    <div className="selectedItemsWrapper">
                                        <p className="marBot10 marTop10 fontSize14 fontWeight600" style={{color: theme.white}}>
                                            {I18n.items}
                                        </p>

                                        <div className="divider width100 marTop10 marBot10" />

                                        <div className="flex flexwrap gap10 ">
                                            {selectedItems?.map(elem => (
                                                <div className="itemChip" key={elem?.item?.id}>
                                                    <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.white}}>
                                                        {elem?.item?.name}
                                                    </p>
                                                    <span className="flex verticalCenter" onClick={() => removeSelectedItem(elem)}>
                                                        <Icon name="crossFilled" viewBox="0 0 15 15" height={15} width={15} />
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                <div>
                                    <div className="divider width100 marTop20 marBot20" />
                                    <div className="flex">
                                        <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.white, width: '40%'}}>
                                            {I18n.modifier}
                                        </p>
                                        <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.white, width: '30%'}}>
                                            {I18n.price}
                                        </p>
                                        <span
                                            style={{
                                                width: '30%',
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                gap: '30px',
                                            }}>
                                            <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.white}}>
                                                {I18n.stock_status}
                                            </p>

                                            <span onClick={() => handleAddModifier()} className="addModifierIcon cursorPointer">
                                                <Icon name={'add2Icon'} width="24" height="24" viewBox={'0 0 18 18'} fill={theme.barclaysBlue} />
                                            </span>
                                        </span>
                                    </div>
                                    <div className="divider width100 marTop10 marBot10" />

                                    {errors?.modifier && <p className="inputError">{errors.modifier}</p>}

                                    {modifier?.map(item => (
                                        <div key={item.id}>
                                            <AddModifierList
                                                item={item}
                                                updateListItem={updateListItem}
                                                handleDeleteModifier={handleDeleteModifier}
                                                errors={errors}
                                                setErrors={setErrors}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </CustomContainer>

            <Modal
                toggle={toggle}
                setToggle={setToggle}
                handleConfirm={handleConfirm}
                title={I18n.back}
                searchPlaceholder={I18n.search_item_by_name}
                createButtonTitle={I18n.create_item}
                onChange={onChangeSearch}
                scrollable={false}
                bottomCancelCta={{
                    title: I18n.cancel,
                    action: () => closeModal(),
                }}
                bottomSaveCta={{
                    title: I18n.save,
                    action: () => saveModal(),
                }}
                targetId="scrollableDiv"
                children={
                    <InfiniteScroll
                        className="infiniteScrollBar"
                        dataLength={products.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget="scrollableDiv">
                        {products?.length > 0 ? (
                            products?.map((item, index) => (
                                <ItemList key={index} item={item} setSelectedItems={setSelectedItems} selectedItems={selectedItems} setErrors={setErrors} />
                            ))
                        ) : (
                            <NonIdealScreen
                                heading={search ? I18n.search : I18n.item}
                                subHeading={search ? I18n.we_couldnt_find_any_results_for_your_search : I18n.create_items_and_add_to_modifiers}
                                name={search ? 'seacrhNotFoundIcon' : 'itemNewIcon'}
                                fill={theme.white}
                                isMultiStep={false}
                            />
                        )}
                    </InfiniteScroll>
                }></Modal>
        </div>
    );
};

const enhance = withObservables([], () => ({
    activeProducts: observeActiveProducts().observeWithColumns(['archive']),
}));

export default enhance(CreateModifier);
