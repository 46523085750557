import {useThemeContext} from '../../../context';
import dayjs from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {TextField} from '../../common';
import Icon from '../../../assets/icons';

const DateSelector = ({error = false, value, setValue, label, disabled, disableDate, disableFuture, required}) => {
    const {theme} = useThemeContext();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={error ? 'form-group formError errorField' : 'form-group'}>
                <div className="formGroupInner datePickerBox">
                    <DatePicker
                        label={
                            <span>
                                {label} {required && <span style={{color: 'red'}}>*</span>}
                            </span>
                        }
                        value={value ? dayjs(value) : ''}
                        onChange={newValue => setValue(label, newValue)}
                        renderInput={props => <TextField {...props} variant="filled" />}
                        disabled={disabled}
                        shouldDisableDate={disableDate}
                        disableFuture={disableFuture}
                    />
                    <span className="datePickerIcon">
                        <Icon name="calenderNewIcon" fill={theme.white} width="24" height="24" viewBox={'0 0 24 24'} />
                    </span>
                </div>
                <p className="inputError">{error ? error : null}</p>
            </div>
        </LocalizationProvider>
    );
};

export default DateSelector;
