import React, {useState} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {filterOptions, toSnakeCase, uuid, validateFloorPlanForm, validateTableData} from '../../../../../constants';
import {Col, Row} from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router-dom';
import NewAppModal from '../../../../common/NewAppModal';
import CreateTable from '../PosCockpit/Dinein/CreateTable';
import Icon from '../../../../../assets/icons';
import RadioButton from '../../../../common/RadioButton';
import {CustomContainer, Header, TextField} from '../../../../common';
import {bulkCreateDineInTables, bulkDeleteDineInTables} from '../../../../../pos-core/database/helpers/dine_in_table';
import usePermission from '../../../../../hooks/usePermission';

const CreateFloorPlan = ({floorData}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business, setRenderToast} = useAuthContext();
    const userPermission = usePermission('floor-plans');
    let navigate = useNavigate();

    const [floorName, setFloorName] = useState(floorData?.selectedFloor?.name || '');
    const [floorType, setFloorType] = useState(floorData?.selectedFloor?.type || 'dinein');
    const [floorTables, setFloorTables] = useState(floorData?.floorTables || []);
    const [errors, setErrors] = useState({});
    const [floorPlanErrors, setFloorPlanErrors] = useState({});
    const [visible, setVisible] = useState(false);
    const [seats, setSeats] = useState();
    const [height, setHeight] = useState();
    const [width, setWidth] = useState();
    const [shape, setShape] = useState(null);
    const [name, setName] = useState('');
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });
    const {state} = useLocation();
    const radioOptions = [
        {
            label: I18n.dine_in,
            value: 'dinein',
        },
        {
            label: I18n.take_away,
            value: 'takeaway',
        },
        {
            label: I18n.delivery,
            value: 'delivery',
        },
    ];

    function handleClose(id) {
        floorData ? navigate('/floor-plans') : navigate(`/pos-cockpit?floor-plan=${id}`);
    }

    const onChange = (label, value) => {
        if (label == I18n.floor_plan_name) setFloorName(value);

        setFloorPlanErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const handleChange = async e => {
        const value = e?.target.value;
        setFloorType(value);

        const label = 'floor_plan_type';
        setFloorPlanErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const saveTable = resetModal => {
        const tableObj = {
            id: uuid(),
            name,
            seats,
            shape: shape?.name,
            height,
            width,
            xAxis: 20,
            yAxis: 20,
            xCoord: 20,
            yCoord: 20,
        };

        const formErrors = validateTableData(tableObj);
        console.log('formErrors', formErrors);
        setErrors(formErrors);
        if (Object.keys(formErrors).length > 0) return;

        setFloorTables(prev => [...prev, tableObj]);
        reset();
        !resetModal && setVisible(false);
    };

    const reset = () => {
        setName('');
        setShape('');
        setSeats('');
        setWidth('');
        setHeight('');
    };

    const resetFloorDetails = () => {
        setFloorName('');
        setFloorType('');
        setFloorTables([]);
    };

    const handleDeleteTable = tableData => {
        const tableId = tableData?.id;
        const filterTables = floorTables.filter(table => table.id !== tableId);
        setFloorTables(filterTables);
    };
    const handleSave = async () => {
        try {
            const floorPayload = {
                name: floorName,
                type: floorType,
                componentType: floorType === 'dinein' ? 'Dinein' : 'Cockpit',
            };

            const formErrors = validateFloorPlanForm(floorPayload);
            console.log('formErrors', formErrors);
            setFloorPlanErrors(formErrors);

            if (Object.keys(formErrors).length > 0) return;
            let createdFloor;
            if (floorData) {
                const floorPayload = {
                    name: floorName,
                };
                const {additionArray, deletionArray} = filterOptions(floorData?.floorTables, floorTables, 'floorPlans');
                const updatedDimensionsTables = additionArray.map((item, ind) => ({
                    ...item,
                    xAxis: (ind + 1) * 20,
                    yAxis: (ind + 1) * 20,
                    xCoord: (ind + 1) * 20,
                    yCoord: (ind + 1) * 20,
                }));

                await floorData.selectedFloor.updateDetails(floorPayload);
                await bulkCreateDineInTables(updatedDimensionsTables, floorData.selectedFloor);
                await bulkDeleteDineInTables(deletionArray);
            } else {
                createdFloor = await business.createFloorPlan(floorPayload);

                if (floorType === 'dinein' && floorTables.length > 0) {
                    const updatedDimensionsTables = floorTables.map((item, ind) => ({
                        ...item,
                        xAxis: (ind + 1) * 20,
                        yAxis: (ind + 1) * 20,
                        xCoord: (ind + 1) * 20,
                        yCoord: (ind + 1) * 20,
                    }));

                    await bulkCreateDineInTables(updatedDimensionsTables, createdFloor);
                } else {
                    const tableObj = [
                        {
                            id: uuid(),
                            name: 'Table 01',
                            seats: 4,
                            shape: 'Square',
                            height: 160,
                            width: 200,
                            xAxis: 20,
                            yAxis: 20,
                            xCoord: 20,
                            yCoord: 20,
                        },
                    ];
                    await bulkCreateDineInTables(tableObj, createdFloor);
                }
            }

            const toastMessage = floorData ? I18n.floor_plan_updated_successfully : I18n.floor_plan_created_successfully;
            setRenderToast(toastMessage);
            handleClose(createdFloor?.id);
            resetFloorDetails();
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleCloseModal = () => {
        reset();
        setErrors({});
        setVisible(false);
    };

    const handleEnterPress = event => {
        if (event.key === 'Enter') {
            handleSave();
        }
    };

    return (
        <>
            <div onKeyDown={handleEnterPress}>
                <Header
                    type="draftHeader"
                    title={I18n.back}
                    saveCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.save,
                                  action: () => handleSave(),
                              }
                            : null
                    }
                />

                <CustomContainer reduceHeight={110} className="addScreenMainBox">
                    <Row className="justify-content-md-center">
                        <Col md={6}>
                            <div className="flex verticalCenter horizontalCenter">
                                <div className="width100 marTop10">
                                    <p className="marBot30 fontSize24 fontWeight600 textCenter" style={{color: theme.white}}>
                                        {floorData ? I18n.update_floor_plan : I18n.create_floor_plan}
                                    </p>

                                    <p className="marBot20 fontSize20 fontWeight400" style={{color: theme.white}}>
                                        {I18n.floor_plan_details}
                                    </p>

                                    <TextField
                                        onChange={onChange}
                                        error={floorPlanErrors?.['floor_plan_name']}
                                        label={I18n.floor_plan_name}
                                        placeholder={I18n.floor_plan_name}
                                        value={floorName}
                                        maxLength={100}
                                        required={true}
                                    />

                                    <p className="marTop20 marBot20 fontSize14 fontWeight400" style={{color: theme.white}}>
                                        {I18n.which_type_of_floor}
                                    </p>
                                    <RadioButton options={radioOptions} className="selectFloorPlan" selectedSize={floorType} handleChange={handleChange} disable={floorData} />
                                    {floorPlanErrors?.floor_plan_type && <p className="inputError">{floorPlanErrors.floor_plan_type}</p>}

                                    {floorType === 'dinein' && (
                                        <>
                                            <div className="divider width100 marTop20" />
                                            <p className="marTop20 marBot20 fontSize20 fontWeight400" style={{color: theme.white}}>
                                                {I18n.add_tables}
                                            </p>

                                            <div className="addTableDivWrapper" onClick={() => setVisible(true)}>
                                                <Icon name={'add2Icon'} width="24" height="24" viewBox={'0 0 18 18'} fill={theme.barclaysBlue} />
                                                <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.barclaysBlue}}>
                                                    {I18n.add_tables}
                                                </p>
                                            </div>

                                            {floorTables?.length > 0 && (
                                                <div className="tablesDivWrapper">
                                                    <div className="flex">
                                                        <p className="marBot0 fontSize14 fontWeight600" style={{color: theme.white, width: '50%'}}>
                                                            {I18n.table_name}
                                                        </p>
                                                        <p className="marBot0 fontSize14 fontWeight600" style={{color: theme.white, width: '20%'}}>
                                                            {I18n.seats}
                                                        </p>
                                                        <p className="marBot0 fontSize14 fontWeight600" style={{color: theme.white, width: '20%'}}>
                                                            {I18n.shape}
                                                        </p>
                                                        <p className="marBot0 fontSize14 fontWeight600" style={{color: theme.white, width: '10%'}} />
                                                    </div>
                                                    {floorTables?.map(tableData => {
                                                        return (
                                                            <>
                                                                <div className="divider width100 marTop10 marBot10" />
                                                                <div className="flex verticalCenter">
                                                                    <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.white, width: '50%'}}>
                                                                        {tableData?.name}
                                                                    </p>
                                                                    <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.white, width: '20%'}}>
                                                                        {tableData?.seats}
                                                                    </p>
                                                                    <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.white, width: '20%'}}>
                                                                        {tableData?.shape}
                                                                    </p>
                                                                    <span className="cursorPointer">
                                                                        <Icon
                                                                            name="delete2Icon"
                                                                            fill={theme.red}
                                                                            width="18"
                                                                            height="18"
                                                                            viewBox={'0 0 18 18'}
                                                                            onClick={() => handleDeleteTable(tableData)}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CustomContainer>
            </div>

            <NewAppModal
                className="deliveryFloorModal modal-backdrop-custom"
                toggle={visible}
                backCta={{
                    name: 'backArrowIcon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }}
                handleClose={() => handleCloseModal()}
                primaryCta={{
                    title: 'Save & new',
                    action: () => saveTable(true),
                }}
                secondaryCta={{
                    title: 'Save & close',
                    action: () => saveTable(),
                }}
                cancelCta={{
                    title: I18n.cancel,
                    action: () => setVisible(false),
                }}
                title="Create table">
                <CreateTable
                    name={name}
                    setName={setName}
                    width={width}
                    setWidth={setWidth}
                    height={height}
                    setHeight={setHeight}
                    shape={shape}
                    setShape={setShape}
                    seats={seats}
                    setSeats={setSeats}
                    errors={errors}
                    setErrors={setErrors}
                />
            </NewAppModal>

            {/* <ToastBox
            className="kdsToastBox"
            ToastVisiable={showMessage.visible}
            bodyPara={showMessage.message}
            showHeader={false}
            setShowMessage={setShowMessage}
          /> */}
        </>
    );
};

export default CreateFloorPlan;
