import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Loading, TabBox } from '../../../../common';
import { useAuthContext, useLanguageContext, useThemeContext } from '../../../../../context';
import { getDashboardAnalytics } from '../../../../../api';
import usePermission from '../../../../../hooks/usePermission';
import { dateFilterMapping, DEVICE_HEIGHT, formatDateWithTime, formateIdForApi, payment_key_metrics } from '../../../../../constants';
import SalesOverview from './Sales';
import WalletOverview from './Payment';
import { useLocation } from 'react-router-dom';

const NewDashboardComponent = () => {
    const { I18n } = useLanguageContext();
    const { theme } = useThemeContext();
    const { business } = useAuthContext();
    const { state } = useLocation();

    const [tabValue, setTabVal] = useState();
    const [tabIndex, setTabIndex] = useState(0);
    const [dashboardData, setDashboardData] = useState({});
    const [selectedDateRange, setSelectedDateRange] = useState({ start_date: formatDateWithTime(new Date()), end_date: formatDateWithTime(new Date()) });
    const [visible, setVisible] = useState({ reports: false, location: true, dateSelector: true });
    const [selectedLocation, setSelectedLocation] = useState({
        [business?.id]: {
            business_name: business?.location_name,
            selected: true,
        },
    });
    const [loading, setLoadig] = useState(false);
    const tabs = [];

    const salesPermission = usePermission('sales-overview');
    const walletPermission = usePermission('wallet-overview');
    const locationString = formateIdForApi(selectedLocation, business?.id);

    if (salesPermission?.canView) {
        tabs.push(I18n.sales);
    }
    if (walletPermission?.canView) {
        tabs.push(I18n.wallet);
    }

    const fetchDashboardData = () => {
        setLoadig(true);
        const apiPayload = {
            start_date: selectedDateRange?.start_date,
            end_date: selectedDateRange?.end_date,
            date_range: dateFilterMapping[selectedDateRange?.label || 'Today'],
            // business_ids: role[0]?._raw?.name === 'dashboard-admin' ? null : locationString,
            business_ids: locationString,
        };
        getDashboardAnalytics(apiPayload)
            .then(resp => {
                if (resp) setDashboardData(resp);
                setLoadig(false);
            })
            .catch(err => {
                console.log('API call failed:', err);
                setLoadig(false);
            });
    };

    useEffect(() => {
        fetchDashboardData();
    }, [selectedDateRange, selectedLocation]);

    useEffect(() => {
        if (state?.screenName === 'wallet') {
            const ind = tabs.indexOf('Wallet');
            setTabVal(tabs[ind]);
            setTabIndex(ind);
        } else if (state?.screenName === 'sales') {
            const ind = tabs.indexOf('Sales');
            setTabVal(tabs[ind]);
            setTabIndex(ind);
        } else {
            setTabVal(tabs[0]);
        }
    }, [salesPermission, walletPermission]);

    const handleChange = (event, newValue) => {
        setTabVal(event.target.innerText);
        setTabIndex(newValue);
    };

    return Object.keys(dashboardData)?.length <= 0 ? (
        <Loading />
    ) : (
        <div
            className="dashboardBox"
            style={{
                background: theme.topBarBG,
                height: DEVICE_HEIGHT - 45,
            }}>
            <Container fluid>
                <TabBox tabValue={tabIndex} tabs={tabs} handleChange={handleChange} />
                {tabValue === 'Sales' ? (
                    <SalesOverview
                        selectedDateRange={selectedDateRange}
                        setSelectedDateRange={setSelectedDateRange}
                        dashboardData={dashboardData}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        loading={loading}
                        setLoading={setLoadig}
                        initialTab={state?.tab === 'transactions' ? 1 : state?.tab === 'online' ? 2 : state?.tab === 'report' ? 3 : 0}
                        visible={visible}
                        setVisible={setVisible}
                    />
                ) : tabValue === 'Wallet' ? (
                    <WalletOverview
                        selectedDateRange={selectedDateRange}
                        setSelectedDateRange={setSelectedDateRange}
                        dashboardData={payment_key_metrics}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        initialTab={state?.tab === 'balance' ? 1 : state?.tab === 'activity' ? 2 : 0}
                        visible={visible}
                        setVisible={setVisible}
                    />
                ) : null}
            </Container>
        </div>
    );
};
export default NewDashboardComponent;
