import {useEffect, useState} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useOrder} from '../../../../../hooks';
import {Loading, NonIdealScreen, OrderDetailCompnent} from '../../../../common';
import {useSearchParams} from 'react-router-dom';
import {findBusinessByID} from '../../../../../pos-core/database/helpers';

const OrderDetails = () => {
    const {business} = useAuthContext();
    const [searchParams] = useSearchParams();
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const [resolvedBusiness, setResolvedBusiness] = useState(business);
    const business_id = searchParams?.get('business_id');

    const handleBusiness = async () => {
        if (business_id) {
            const businessData = await findBusinessByID(business_id);
            setResolvedBusiness(businessData);
        }
    };
    useEffect(() => {
        handleBusiness();
    }, [business_id]);

    const {isLoading, order} = useOrder(resolvedBusiness);

    return isLoading ? (
        <Loading />
    ) : order?._raw?.id ? (
        <OrderDetailCompnent order={order} />
    ) : (
        <NonIdealScreen
            heading={I18n.page_not_found}
            subHeading={I18n.this_page_doesnot_exist}
            name="notFoundIcon"
            fill={theme.white}
            viewBox="0 0 100 100"
            height="100"
            width="100"
            isMultiStep={false}
        />
    );
};

export default OrderDetails;
