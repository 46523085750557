import React, {useEffect, useState} from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import Icon from '../../../assets/icons';
import TextField from '../TextField';

const BeneficiaryCard = ({length, beneficiary, handleUpdateBeneficiaries, errors, setErrors, ind}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [amount, setAmount] = useState('');

    const onChange = (label, val) => {
        if (label == I18n.enter_amount) setAmount(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[beneficiary.beneficiary_id]) {
                delete updatedErrors[beneficiary.beneficiary_id];
            }
            return updatedErrors;
        });
    };

    const handleDelete = () => {
        handleUpdateBeneficiaries(beneficiary, 'delete');
    };

    const handleUpdate = () => {
        const payload = {
            ...beneficiary,
            amount: Number(amount),
        };
        handleUpdateBeneficiaries(payload, 'updateamount');
    };

    useEffect(() => {
        setAmount(beneficiary?.amount);
    }, [beneficiary]);

    return (
        <div className="marTop15" key={ind}>
            <div className="spaceBetweenCenter">
                <p className="fontSize16 fontWeight500 marBot0" style={{color: theme.text}}>
                    {beneficiary.account_title}

                    <span className="marLeft10 fontSize12 fontWeight400 " style={{color: theme.darkGrayTwo}}>
                        <img
                            src={beneficiary.logo_link}
                            title={`${beneficiary.bank_name} logo`}
                            alt={`${beneficiary.bank_name} logo`}
                            style={{
                                width: '20px',
                                height: '20px',
                                objectFit: 'contain',
                                marginRight: '5px',
                                marginBottom: '5px',
                            }}
                        />
                        {beneficiary.bank_name}
                    </span>
                </p>

                <p className="fontSize16 fontWeight400 marBot0" style={{color: theme.text}}>
                    {beneficiary.account_number}
                    <span className="marLeft20 cursorPointer">
                        <Icon name="closeIcon" fill={theme.darkGrayTwo} width={'12'} height={'12'} viewBox={'0 0 15 15'} onClick={handleDelete} />
                    </span>
                </p>
            </div>

            <div className="marTop10">
                <TextField
                    onChange={onChange}
                    onBlur={handleUpdate}
                    error={errors?.[beneficiary.beneficiary_id]?.amount}
                    label={I18n.enter_amount}
                    placeholder={I18n.enter_amount}
                    maxLength={16}
                    required={true}
                    value={amount ? `Rs. ${amount}` : ''}
                    onlyNumbers={true}
                />
            </div>
            {ind < length - 1 && <div className="thinDivider width100 marTop0" />}
        </div>
    );
};

export default BeneficiaryCard;
