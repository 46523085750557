import React, {useState} from 'react';
import {CustomContainer, Header, TextField} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {Col, Row} from 'react-bootstrap';
import {RESTAURANT, toSnakeCase, validateSendEmailForm} from '../../../../../constants';
import {useLocation, useNavigate} from 'react-router-dom';
import {sendUserCredentials} from '../../../../../api';

const SendEmail = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business} = useAuthContext();

    let navigate = useNavigate();
    const {state} = useLocation();

    const [email, setEmail] = useState(JSON.parse(localStorage.getItem('created_user'))?.email);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleSend = async () => {
        try {
            setLoading(true);
            let emailFormObj = {
                email,
            };

            const formErrors = await validateSendEmailForm(emailFormObj);
            console.log('form Errors', formErrors);
            setErrors(formErrors);

            if (Object.keys(formErrors).length > 0) return;

            const user = JSON.parse(localStorage.getItem('created_user'));
            const userCred = {
                password: user.password,
                pin: user?.posPin ? user?.posPin : null,
                role: user.userRole,
                location: user.location,
                phone: user.phone,
                email,
                pos_allow: user.posAllow,
            };
            const response = await sendUserCredentials(userCred);
            localStorage.removeItem('created_user');
            console.log('response', response);
            handleNavigate();
            setLoading(false);
        } catch (error) {
            console.log('something went wrong');
            setLoading(false);
        }
    };

    const backAction = () => {
        handleNavigate();
    };

    const handleNavigate = () => {
        if (state?.screenName == 'is_onboarding') {
            if (business.type === RESTAURANT) {
                navigate('/pos-cockpit');
            } else {
                navigate('/pos');
            }
        } else {
            navigate('/team');
        }
    };

    const onChange = async (label, value) => {
        if (label === I18n.enter_email_address) setEmail(value);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};

            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }

            return updatedErrors;
        });
    };

    const handleCancel = () => {
        navigate('/team');
    };

    return (
        <div>
            <Header
                type="draftHeader"
                title={I18n.back}
                backAction={backAction}
                saveCta={{
                    title: I18n.send,
                    action: () => handleSend(),
                    saveLoad: loading,
                }}
                primaryCta={{
                    title: I18n.skip,
                    action: () => handleCancel(),
                }}
            />
            <CustomContainer className="addScreenMainBox createCutomerContainer">
                <Row className="justify-content-md-center">
                    <Col md={4}>
                        <div>
                            <p className="fontSize24 textCenter fontWeight600 marBot20 marTop0" style={{color: theme.white}}>
                                POS login access
                            </p>
                            <p className="fontSize20  fontWeight400 marBot10 marTop40" style={{color: theme.white}}>
                                Send member login credentials to email or phone
                            </p>
                            <p className="fontSize12  fontWeight400 marBot15" style={{color: theme.darkGrayTwo}}>
                                Send this member login credentials to email or phone number with instructions on how to sign in to the Oscar.
                            </p>

                            <TextField
                                onChange={onChange}
                                error={errors?.['enter_email_address']}
                                label={I18n.enter_email_address}
                                placeholder={I18n.enter_email_address}
                                value={email}
                            />
                        </div>
                    </Col>
                </Row>
            </CustomContainer>
        </div>
    );
};

export default SendEmail;
