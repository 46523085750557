import {Model} from '@nozbe/watermelondb';
import {PAYMENT_SCHEMA} from '../schema';
import {date, field, relation, text, writer} from '@nozbe/watermelondb/decorators';

export default class Payment extends Model {
    static table = PAYMENT_SCHEMA;

    static associations = {
        business: {type: 'belongs_to', key: 'business_id'},
        customer: {type: 'belongs_to', key: 'customer_id'},
        order: {type: 'belongs_to', key: 'order_id'},
    };

    @text('payment_method') payment_method;
    @text('card_number') card_number;
    @field('amount') amount;
    @text('status') status;
    @text('type') type;
    @field('received_amount') received_amount;
    @field('change') change;
    @text('txns_number') txns_number;
    @text('invoice_number') invoice_number;
    @text('device_type') device_type;

    @date('completed_at') completed_at;
    @date('expiry_date') expiry_date;
    @date('started_at') started_at;
    @date('created_at') created_at;
    @text('payment_intent') payment_intent;

    @relation('business', 'business_id') business;
    @relation('customer', 'customer_id') customer;
    @relation('order', 'order_id') order;

    @writer async updatePayment(details) {
        return await this.update(payment => {
            payment.payment_method = details.payment_method;
            payment.card_number = details.card_number;
            payment.status = details.status;
            payment.type = details.type;
            payment.received_amount = details.received_amount;
            payment.change = details.change;
            payment.completed_at = details.completed_at;
        });
    }
}
