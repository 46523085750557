import {Col, FormCheck, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {TextField, Header, MessageModal, DateSelector} from '../../common';
import {useState, useEffect} from 'react';
import {convertArrayToObj, filterOptions, getAccountBusiness, getHashMap, getLocationNames, toSnakeCase, validateDiscountForm, validateDraftDiscount} from '../../../constants';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Sync} from '../../../api';
import LocationModal from '../LocationModal';
import {bulkCreateDiscountBusiness, bulkDeleteDiscountBusiness} from '../../../pos-core/database/helpers/discountBusiness';

const CreateDiscountComponent = ({discount}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {user, business, setRenderToast, account, userBusiness, persistData, setPersistData} = useAuthContext();

    const isCreatedDiscount = !Boolean(discount);

    let navigate = useNavigate();

    const {
        name_data,
        tax_behavior_data,
        discount_type_data,
        amount_limit_data,
        max_discount_cap_data,
        selectedLocations_data,
        start_date_data,
        end_date_data,
        enableDateRange_data,
    } = persistData?.discounts || {};

    const [name, setName] = useState((isCreatedDiscount ? name_data : discount?.name) || '');
    const [startDate, setStartDate] = useState((isCreatedDiscount ? start_date_data : discount?.start_date) || null);
    const [endDate, setEndDate] = useState((isCreatedDiscount ? end_date_data : discount?.end_date) || null);
    const [enableDateRange, setEnableDateRange] = useState((isCreatedDiscount ? enableDateRange_data : discount?.start_date) || false);
    const [location, setLocation] = useState(null);
    const [locationModal, setLocationModal] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState(isCreatedDiscount ? selectedLocations_data : {});
    const [locationsObj, setLocationsObj] = useState();
    const [tax_behavior, setTaxBehavior] = useState(isCreatedDiscount ? tax_behavior_data : discount ? I18n[discount.is_inclusive ? 'tax_inclusive' : 'tax_exclusive'] : '');
    const [discount_type, setDiscountType] = useState(
        isCreatedDiscount ? discount_type_data : discount ? I18n[discount.type == 'percentage' ? 'type_percentage' : 'type_amount'] : '',
    );
    const [discardModalToggle, setDiscardModalToggle] = useState(false);
    const [amount_limit, setAmountLimit] = useState(isCreatedDiscount ? amount_limit_data : discount?.limit || '');
    const [max_discount_cap, setMaxDiscountCap] = useState(isCreatedDiscount ? max_discount_cap_data : discount?.max_discount_cap || '');
    const [errors, setErrors] = useState({});
    const [searchParams] = useSearchParams();

    const discountType = [I18n.type_amount, I18n.type_percentage];
    const location_id = searchParams?.get('location_id');
    const orderNumber = searchParams.get('order_id');

    let discountPersistData = {
        name_data: name,
        tax_behavior_data: tax_behavior,
        discount_type_data: discount_type,
        amount_limit_data: amount_limit,
        max_discount_cap_data: max_discount_cap,
        selectedLocations_data: selectedLocations || {},
        enableDateRange_data: enableDateRange,
        start_date_data: startDate,
        end_date_data: endDate,
    };

    useEffect(() => {
        getBusinessOfAccount();
    }, []);

    useEffect(() => {
        if (location_id && location) {
            setSelectedLocations(prev => {
                let obj = {...prev};
                obj[location_id] = location_id;
                return obj;
            });
        }
    }, [location_id, location]);

    useEffect(() => {
        // for handling location field, onchange isn't working there
        if (Object.values(selectedLocations || {}).length > 0) {
            setErrors(prevErrors => {
                const updatedErrors = {...prevErrors};
                let label = 'Location';
                if (updatedErrors[toSnakeCase(label)]) {
                    delete updatedErrors[toSnakeCase(label)];
                }

                return updatedErrors;
            });
        }
    }, [selectedLocations]);
    const onChange = (label, val) => {
        if (label == I18n.discounts_name) setName(val);
        else if (label == I18n.taxation_behaviour) setTaxBehavior(val);
        else if (label == I18n.amount_type) setDiscountType(val);
        else if (label == I18n.amount) setAmountLimit(val);
        else if (label == I18n.maximum_discount_cap) setMaxDiscountCap(val);
        else if (label == I18n.location) setLocationModal(true);
        else if (label === I18n.start_date) setStartDate(val);
        else if (label === I18n.end_date) setEndDate(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const handleCreate = async val => {
        const {draft, id} = discount || {};
        try {
            if (selectedLocations?.hasOwnProperty('all')) {
                delete selectedLocations['all'];
            }
            const locationsArray = Object.values(selectedLocations || {}).map(item => locationsObj[item]);

            let paramsForDiscount = {
                name,
                is_inclusive: tax_behavior == I18n.tax_inclusive ? true : false,
                type: discount_type == I18n.type_amount ? 'amount' : discount_type == I18n.type_percentage ? 'percentage' : '',
                limit: Number(amount_limit),
                max_discount_cap: max_discount_cap ? Number(max_discount_cap) : null,
                draft: val === 'saveDraft',
                enableDateRange,
                start_date: new Date(startDate).getTime(),
                end_date: new Date(endDate).getTime(),
                locationsArray,
            };
            let createdDiscount;

            const formErrors = await (val === 'save' ? validateDiscountForm(paramsForDiscount) : validateDraftDiscount(paramsForDiscount));
            setErrors(formErrors);
            console.log('form errors', formErrors);

            if (Object.keys(formErrors).length > 0) return;

            if (discount) {
                createdDiscount = await discount.updateDetails(paramsForDiscount);
                const discountBusinessess = await discount.getDiscountBusinessess();

                const {additionArray, deletionArray} = filterOptions(discountBusinessess, locationsArray, 'business');
                const discountBussPayload = {
                    locations: additionArray,
                    discount: createdDiscount,
                };

                await bulkCreateDiscountBusiness(discountBussPayload);
                await bulkDeleteDiscountBusiness(deletionArray);
            } else {
                createdDiscount = await account.createDiscount(paramsForDiscount);
                const discountBussPayload = {
                    locations: locationsArray,
                    discount: createdDiscount,
                };

                await bulkCreateDiscountBusiness(discountBussPayload);
            }

            let toastMessage;
            if (val === 'saveDraft') {
                toastMessage = 'discount_saved_into_draft';
            } else {
                toastMessage = draft ? 'discount_published_successfully' : id ? 'discount_update' : 'discount_created';
            }

            setRenderToast(toastMessage);
            setPersistData(null);
            Sync(user?.email);
            if (location_id) {
                navigate('/discounts');
            } else {
                navigate(-1);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const toggleDiscardModal = () => {
        setDiscardModalToggle(x => !x);
    };

    const handleDiscardAction = () => {
        setPersistData(null);
        navigate('/discounts');
    };

    const handleEnterPress = event => {
        if (event?.key === 'Enter') {
            handleCreate('save');
        }
    };

    async function getBusinessOfAccount() {
        const singleSelection = false;
        const businessLocations = await getAccountBusiness(account, business, userBusiness, singleSelection);
        const businessObj = await getHashMap(account);

        if (discount) {
            const discountBusinessess = await discount.getDiscountBusinessess();
            setSelectedLocations(convertArrayToObj(discountBusinessess));
        }

        setLocation(businessLocations);
        setLocationsObj(businessObj);
    }

    const handleToggleDiscountModal = () => {
        setLocationModal(true);
        setPersistData({discounts: discountPersistData});
    };

    const handleDateRange = e => {
        setEnableDateRange(e.target.checked);
    };

    return (
        <>
            <div onKeyDown={handleEnterPress}>
                <Header
                    type="draftHeader"
                    title={I18n.back}
                    saveCta={{
                        title: I18n.save,
                        action: () => handleCreate('save'),
                    }}
                    backAction={toggleDiscardModal}
                    primaryCta={
                        !discount?.id
                            ? {
                                  title: I18n.save_draft,
                                  action: () => handleCreate('saveDraft'),
                              }
                            : null
                    }
                />

                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <div className="signupTextBox">
                            <h3 className="fontSize24  textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                                {discount ? I18n.edit_discount : I18n.create_discount}
                            </h3>
                            <h3 className="fontSize20 fontWeight400 marBot15 marLeft15 marTop0" style={{color: theme.text}}>
                                {I18n.discount_details}
                            </h3>

                            <div className="itemFields">
                                <TextField
                                    onChange={onChange}
                                    error={errors['discount_name']}
                                    label={I18n.discounts_name}
                                    placeholder={I18n.discounts_name}
                                    value={name}
                                    autoFocus={true}
                                    required={true}
                                />
                                <div className="intventoryFormGridTwo">
                                    <div className="priceSectionInputLeft">
                                        <TextField
                                            label={I18n.amount_type}
                                            placeholder={I18n.amount_type}
                                            error={errors['amount_type']}
                                            onChange={onChange}
                                            data={discountType}
                                            required={true}
                                            value={discount_type}
                                            inputType="select"
                                            type="text"
                                            suffix="downIcon2"
                                            suffix_fill={theme.white}
                                            suffix_width={'24'}
                                            suffix_height={'24'}
                                            suffix_viewBox={'0 0 18 18'}
                                        />
                                    </div>
                                    <div className="priceSectionInputRight">
                                        <TextField
                                            onChange={onChange}
                                            error={errors['amount']}
                                            label={I18n.amount}
                                            placeholder={discount_type == I18n.type_percentage ? '0%' : 'Rs. 0'}
                                            value={amount_limit}
                                            type={'numeric'}
                                            required={true}
                                            maxLength={7}
                                        />
                                    </div>
                                </div>

                                <div className="intventoryFormGridTwo">
                                    <div className="priceSectionInputLeft">
                                        <TextField
                                            onChange={onChange}
                                            label={I18n.maximum_discount_cap}
                                            placeholder={I18n.maximum_discount_cap}
                                            disabled={discount_type == I18n.type_amount}
                                            value={max_discount_cap}
                                            type={'numeric'}
                                            maxLength={7}
                                        />
                                    </div>
                                    <div onClick={handleToggleDiscountModal} className="priceSectionInputRight">
                                        <TextField
                                            label={I18n.location}
                                            placeholder={I18n.location}
                                            error={errors?.['location']}
                                            onChange={onChange}
                                            value={getLocationNames(locationsObj, selectedLocations)}
                                            inputType="text"
                                            type="text"
                                            suffix="downIcon2"
                                            suffix_fill={theme.white}
                                            suffix_width={'24'}
                                            suffix_height={'24'}
                                            required={true}
                                            suffix_viewBox={'0 0 18 18'}
                                            // disabled={discount ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3 className="fontSize20 fontWeight400 marBot15 marLeft15 marTop20" style={{color: theme.text}}>
                                    {I18n.discount_schedule}
                                </h3>

                                <div className="flex marLeft15 marTop20 marBot20 alignCenter">
                                    <div>
                                        <FormCheck type="switch" id="stock_available" onChange={e => handleDateRange(e)} checked={enableDateRange} />
                                    </div>
                                    <div>
                                        <p className="marBot0 fontSize14 marLeft20 fontWeight400" style={{color: theme.white}}>
                                            {I18n.set_date_range}
                                        </p>
                                        <p className="marBot0 fontSize12 marLeft20 fontWeight400" style={{color: theme.darkGrayTwo}}>
                                            {I18n.set_the_dates}
                                        </p>
                                    </div>
                                </div>
                                {enableDateRange && (
                                    <div className="intventoryFormGridTwo">
                                        <div className="priceSectionInputLeft">
                                            <DateSelector
                                                label={I18n.start_date}
                                                required={true}
                                                icon={'addCustomer'}
                                                value={startDate}
                                                setValue={onChange}
                                                error={errors['start_date']}
                                            />
                                        </div>
                                        <div className="priceSectionInputRight">
                                            <DateSelector
                                                label={I18n.end_date}
                                                required={true}
                                                icon={'addCustomer'}
                                                value={endDate}
                                                setValue={onChange}
                                                error={errors['end_date']}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <LocationModal
                toggle={locationModal}
                setToggle={setLocationModal}
                selectedLocations={selectedLocations}
                setSelectedLocations={setSelectedLocations}
                locations={location}
                singleSelection={false}
                screenName="discount"
            />
            <MessageModal
                className="messageModal"
                setToggle={setDiscardModalToggle}
                toggle={discardModalToggle}
                description={I18n.do_you_really_want_to_discard}
                subDescription={I18n.this_action_is_irreversible_once_you_discard_it_its_gone}
                secondaryCta={{
                    title: I18n.cancel,
                    action: toggleDiscardModal,
                }}
                primaryCta={{
                    title: I18n.discard,
                    backgroundColor: theme.white,
                    borderColor: theme.red,
                    txtColor: theme.red,
                    action: handleDiscardAction,
                    isDelete: true,
                }}
            />
        </>
    );
};

export default CreateDiscountComponent;
