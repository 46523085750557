import {Col, Row, Spinner} from 'react-bootstrap';
import {useThemeContext, useLanguageContext} from '../../../context';
import CustomContainer from '../CustomContainer';
import {firstLetterCaptilize} from '../../../constants';

const Loading = ({heading, subHeading, preHeading, text, ...rest}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    return (
        <CustomContainer className="loadingScreenMain" {...rest}>
            <Row>
                <Col>
                    <div className="loadingScreenInner">
                        <Spinner animation="border" variant="brightGreen" className="loaderCircle marBot25" />

                        {preHeading && (
                            <h2 className="fontSize20 fontWeight400 marTop5 marBot10" style={{color: theme.text}}>
                                {preHeading}
                            </h2>
                        )}

                        <h1 className="fontSize24 fontWeight700 marBot5" style={{color: theme.text}}>
                            {firstLetterCaptilize(I18n.loading_with_dot)}
                        </h1>

                        {subHeading && (
                            <h2 className="fontSize20 fontWeight400 marTop5 marBot5" style={{color: theme.text}}>
                                {subHeading}
                            </h2>
                        )}

                        {text && (
                            <h3 className="fontSize16 fontWeight300 marTop5 marBot5" style={{color: theme.text}}>
                                {text}
                            </h3>
                        )}
                    </div>
                </Col>
            </Row>
        </CustomContainer>
    );
};

export default Loading;
