import {appSchema, tableSchema} from '@nozbe/watermelondb';

export const ACCOUNT_SCHEMA = 'account';
export const BUSINESS_SCHEMA = 'business';
export const USER_SCEHMA = 'user';
export const USER_BUSINESS_SCHEMA = 'user_business';
export const CATEGORY_SCHEMA = 'category';
export const ITEM_SCHEMA = 'item';
export const CUSTOMER_SCHEMA = 'customer';
export const CREDIT_TRANSACTION_SCHEMA = 'credit_transactions';
export const PRODUCT_TEMPLATE_SCHEMA = 'product_template';
export const PRODUCT_SCHEMA = 'product';
export const PRODUCT_BUSINESS_SCHEMA = 'product_business';
export const OPTION_SCHEMA = 'option';
export const SESSION_SCHEMA = 'session';
export const ORDER_SCHEMA = 'order';
export const WAITER_SCHEMA = 'waiter';
export const RIDER_SCHEMA = 'rider';
export const ORDER_LINE_SCHEMA = 'order_line';
export const DISCOUNT_SCHEMA = 'discount';
export const DISCOUNT_BUSINESS_SCHEMA = 'discount_business';
export const SALES_TAX_SCHEMA = 'sales_tax';
export const PURCHASE_TAX_SCHEMA = 'purchase_tax';
export const COUNTER_SCHEMA = 'counter';
export const PURCHASE_ORDER_SCHEMA = 'purchase_order';
export const PO_LINE_SCHEMA = 'purchase_order_line';
export const COMPANY_SCHEMA = 'company';
export const ROLE_SCHEMA = 'role';
export const MODULE_SCHEMA = 'module';
export const MODIFIER_SCHEMA = 'modifier';
export const MODIFIER_OPTION_SCHEMA = 'modifier_option';
export const PRODUCT_MODIFIER_SCHEMA = 'product_modifier';
export const FLOOR_PLAN_SCHEMA = 'floor_plan';
export const DINE_IN_TABLE_SCHEMA = 'dine_in_table';
export const PAYMENT_SCHEMA = 'payment';
export const SETTING_CONFIG_SCHEMA = 'setting_config';
export const PAYMENT_METHOD_SCHEMA = 'payment_method';
export const DEAL_SCHEMA = 'deal';
export const DEAL_PRODUCT_SCHEMA = 'deal_product';

export const mySchema = appSchema({
    version: 1,
    tables: [
        tableSchema({
            name: ACCOUNT_SCHEMA,
            columns: [
                {name: 'name', type: 'string'},
                {name: 'email', type: 'string'},
                {name: 'phone_number', type: 'string'},
                {name: 'is_active', type: 'boolean', isOptional: true},
            ],
        }),
        tableSchema({
            name: BUSINESS_SCHEMA,
            columns: [
                {name: 'account_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'kind', type: 'string', isOptional: true},
                {name: 'category', type: 'string', isOptional: true},
                {name: 'type', type: 'string', isOptional: true},
                {name: 'country', type: 'string', isOptional: true},
                {name: 'province', type: 'string', isOptional: true},
                {name: 'zip_code', type: 'string', isOptional: true},
                {name: 'city', type: 'string', isOptional: true},
                {name: 'address', type: 'string', isOptional: true},
                {name: 'email', type: 'string', isOptional: true},
                {name: 'phone', type: 'string', isOptional: true},
                {name: 'location_name', type: 'string', isOptional: true},
                {name: 'location_type', type: 'string', isOptional: true},
                {name: 'is_hq', type: 'boolean', isOptional: true},
                {name: 'is_active', type: 'boolean', isOptional: true},
                {name: 'last_active_at', type: 'number'},
                {name: 'void_reason', type: 'string', isOptional: true},
            ],
        }),
        tableSchema({
            name: USER_SCEHMA,
            columns: [
                {name: 'account_id', type: 'string'},
                {name: 'username', type: 'string'},
                {name: 'first_name', type: 'string'},
                {name: 'last_name', type: 'string'},
                {name: 'salary_type', type: 'string', isOptional: true},
                {name: 'salary_amount', type: 'number', isOptional: true},
                {name: 'pin', type: 'string'},
                {name: 'email', type: 'string', isOptional: true},
                {name: 'phone_number', type: 'string', isOptional: true},
                {name: 'cnic', type: 'string', isOptional: true},
                {name: 'cnic_front', type: 'string', isOptional: true},
                {name: 'cnic_back', type: 'string', isOptional: true},
                {name: 'image', type: 'string', isOptional: true},
                {name: 'is_active', type: 'boolean', isOptional: true},
                {name: 'archive', type: 'boolean', isOptional: true},
                {name: 'created_at', type: 'number'},
                {name: 'last_visited', type: 'number'},
            ],
        }),
        tableSchema({
            name: ROLE_SCHEMA,
            columns: [
                {name: 'account_id', type: 'string'},
                {name: 'name', type: 'string'},
            ],
        }),
        tableSchema({
            name: MODULE_SCHEMA,
            columns: [
                {name: 'name', type: 'string'},
                {name: 'role_id', type: 'string'},
                {name: 'create', type: 'boolean'},
                {name: 'read', type: 'boolean'},
                {name: 'update', type: 'boolean'},
                {name: 'delete', type: 'boolean'},
            ],
        }),
        tableSchema({
            name: USER_BUSINESS_SCHEMA,
            columns: [
                {name: 'user_id', type: 'string'},
                {name: 'business_id', type: 'string'},
                {name: 'role_id', type: 'string'},
            ],
        }),

        tableSchema({
            name: CATEGORY_SCHEMA,
            columns: [
                {name: 'account_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'color_code', type: 'string'},
                {name: 'archive', type: 'boolean'},
            ],
        }),
        tableSchema({
            name: ITEM_SCHEMA,
            columns: [
                {name: 'business_id', type: 'string'},
                {name: 'category_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'description', type: 'string', isOptional: true},
                {name: 'image', type: 'string', isOptional: true},
                {name: 'selling_price', type: 'number', isOptional: true},
                {name: 'cost_price', type: 'number', isOptional: true},
                {name: 'is_taxable', type: 'boolean', isOptional: true},
                {name: 'profit', type: 'number', isOptional: true},
                {name: 'margin', type: 'number', isOptional: true},
                {name: 'quantity', type: 'number', isOptional: true},
                {name: 'low_stock', type: 'number', isOptional: true},
                {name: 'is_trackable', type: 'boolean', isOptional: true},
                {name: 'barcode', type: 'string', isOptional: true},
            ],
        }),
        tableSchema({
            name: MODIFIER_SCHEMA,
            columns: [
                {name: 'account_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'print_in_receipt', type: 'boolean'},
                {name: 'archive', type: 'boolean'},
                {name: 'created_at', type: 'number'},
            ],
        }),

        tableSchema({
            name: MODIFIER_OPTION_SCHEMA,
            columns: [
                {name: 'modifier_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'price', type: 'string'},
                {name: 'in_stock', type: 'boolean'},
                {name: 'archive', type: 'boolean'},
                {name: 'created_at', type: 'number'},
            ],
        }),
        tableSchema({
            name: PRODUCT_MODIFIER_SCHEMA,
            columns: [
                {name: 'product_id', type: 'string'},
                {name: 'modifier_id', type: 'string'},
                {name: 'archive', type: 'boolean'},
            ],
        }),
        tableSchema({
            name: CUSTOMER_SCHEMA,
            columns: [
                {name: 'account_id', type: 'string'},
                {name: 'name', type: 'string', isOptional: true},
                {name: 'phone_number', type: 'string', isOptional: true},
                {name: 'email', type: 'string', isOptional: true},
                {name: 'dob', type: 'number', isOptional: true},
                {name: 'province', type: 'string', isOptional: true},
                {name: 'country', type: 'string', isOptional: true},
                {name: 'city', type: 'string', isOptional: true},
                {name: 'address', type: 'string', isOptional: true},
                {name: 'archive', type: 'boolean', isOptional: true},
                {name: 'created_at', type: 'number'},
                {name: 'available_points', type: 'number', isOptional: true},
                {name: 'total_credit', type: 'number', isOptional: true},
                {name: 'draft', type: 'boolean', isOptional: true},
            ],
        }),
        tableSchema({
            name: WAITER_SCHEMA,
            columns: [
                {name: 'business_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'phone_number', type: 'string', isOptional: true},
                {name: 'email', type: 'string', isOptional: true},
                {name: 'dob', type: 'number', isOptional: true},
                {name: 'province', type: 'string', isOptional: true},
                {name: 'country', type: 'string', isOptional: true},
                {name: 'city', type: 'string', isOptional: true},
                {name: 'address', type: 'string', isOptional: true},
                {name: 'archive', type: 'boolean', isOptional: true},
                {name: 'created_at', type: 'number'},
                {name: 'draft', type: 'boolean', isOptional: true},
            ],
        }),
        tableSchema({
            name: RIDER_SCHEMA,
            columns: [
                {name: 'business_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'phone_number', type: 'string', isOptional: true},
                {name: 'email', type: 'string', isOptional: true},
                {name: 'dob', type: 'number', isOptional: true},
                {name: 'province', type: 'string', isOptional: true},
                {name: 'country', type: 'string', isOptional: true},
                {name: 'city', type: 'string', isOptional: true},
                {name: 'address', type: 'string', isOptional: true},
                {name: 'archive', type: 'boolean', isOptional: true},
                {name: 'created_at', type: 'number'},
                {name: 'draft', type: 'boolean', isOptional: true},
            ],
        }),
        tableSchema({
            name: PRODUCT_TEMPLATE_SCHEMA,
            columns: [
                {name: 'account_id', type: 'string'},
                {name: 'category_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'description', type: 'string', isOptional: true},
                {name: 'image', type: 'string', isOptional: true},
                {name: 'is_taxable', type: 'boolean', isOptional: true},
                {name: 'is_trackable', type: 'boolean', isOptional: true},
                {name: 'unit', type: 'string', isOptional: true},
                {name: 'secondary_unit', type: 'string', isOptional: true},
                {name: 'conversion_rate', type: 'number', isOptional: true},
            ],
        }),
        tableSchema({
            name: PRODUCT_SCHEMA,
            columns: [
                {name: 'account_id', type: 'string'},
                {name: 'category_id', type: 'string'},
                {name: 'product_template_id', type: 'string'},
                {name: 'sales_tax_id', type: 'string', isOptional: true},
                {name: 'purchase_tax_id', type: 'string', isOptional: true},
                {name: 'deal_id', type: 'string', isOptional: true},
                {name: 'name', type: 'string'},
                {name: 'selling_price', type: 'number', isOptional: true},
                {name: 'base_price', type: 'number', isOptional: true},
                {name: 'low_stock', type: 'number', isOptional: true},
                {name: 'cost_price', type: 'number', isOptional: true},
                {name: 'profit', type: 'number', isOptional: true},
                {name: 'margin', type: 'number', isOptional: true},
                {name: 'quantity', type: 'number', isOptional: true},
                {name: 'barcode', type: 'string', isOptional: true},
                {name: 'type', type: 'string', isOptional: true},
                {name: 'product_level_discount', type: 'string', isOptional: true},
                {name: 'archive', type: 'boolean', isOptional: true},
                {name: 'draft', type: 'boolean', isOptional: true},
                {name: 'created_at', type: 'number'},
            ],
        }),
        tableSchema({
            name: PRODUCT_BUSINESS_SCHEMA,
            columns: [
                {name: 'business_id', type: 'string'},
                {name: 'product_id', type: 'string'},
                {name: 'quantity', type: 'number'},
            ],
        }),
        tableSchema({
            name: OPTION_SCHEMA,
            columns: [
                {name: 'business_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'type', type: 'string'},
                {name: 'values', type: 'string'},
            ],
        }),
        tableSchema({
            name: SESSION_SCHEMA,
            columns: [
                {name: 'business_id', type: 'string'},
                {name: 'user_id', type: 'string'},
                {name: 'is_active', type: 'boolean'},
                {name: 'started_at', type: 'number'},
                {name: 'closed_at', type: 'number', isOptional: true},
                {name: 'opening_balance', type: 'number', isOptional: true},
                {name: 'closing_balance', type: 'number', isOptional: true},
            ],
        }),
        tableSchema({
            name: ORDER_SCHEMA,
            columns: [
                {name: 'business_id', type: 'string'},
                {name: 'user_id', type: 'string', isOptional: true},
                {name: 'customer_id', type: 'string', isOptional: true},
                {name: 'waiter_id', type: 'string', isOptional: true},
                {name: 'rider_id', type: 'string', isOptional: true},
                {name: 'discount_id', type: 'string', isOptional: true},
                {name: 'sales_tax_id', type: 'string', isOptional: true},
                {name: 'dine_in_table_id', type: 'string', isOptional: true},
                {name: 'floor_plan_id', type: 'string', isOptional: true},
                {name: 'number', type: 'string'},
                {name: 'status', type: 'string'},
                {name: 'total', type: 'number'},
                {name: 'sub_total', type: 'number'},
                {name: 'total_tax', type: 'number', isOptional: true},
                {name: 'total_item_level_discount', type: 'number', isOptional: true},
                {name: 'order_level_discount', type: 'number', isOptional: true},
                {name: 'received_amount', type: 'number'},
                {name: 'change', type: 'number'},
                {name: 'discount', type: 'number', isOptional: true},
                {name: 'order_discount', type: 'string', isOptional: true},
                {name: 'payment_method', type: 'string', isOptional: true},
                {name: 'completed_at', type: 'number', isOptional: true},
                {name: 'archive', type: 'boolean', isOptional: true},
                {name: 'started_at', type: 'number', isOptional: true},
                {name: 'type', type: 'string', isOptional: true},
                {name: 'no_of_guests', type: 'string', isOptional: true},
                {name: 'note', type: 'string', isOptional: true},
                {name: 'void', type: 'boolean', isOptional: true},
                {name: 'void_reason', type: 'string', isOptional: true},
                {name: 'redeemed_points', type: 'number', isOptional: true},
                {name: 'earned_points', type: 'number', isOptional: true},
            ],
        }),
        tableSchema({
            name: ORDER_LINE_SCHEMA,
            columns: [
                {name: 'order_id', type: 'string'},
                {name: 'product_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'base_price', type: 'number'},
                {name: 'discount_price', type: 'number'},
                {name: 'aggregate_discount', type: 'number'},
                {name: 'aggregate_tax', type: 'number'},
                {name: 'sub_total', type: 'number'},
                {name: 'total', type: 'number'},
                {name: 'selling_price', type: 'number'},
                {name: 'cost_price', type: 'number'},
                {name: 'transacted_at', type: 'number'},
                {name: 'quantity', type: 'number'},
                {name: 'unit', type: 'string'},
                {name: 'rate', type: 'number'},
                {name: 'discount', type: 'number', isOptional: true},
                {name: 'discount_type', type: 'string', isOptional: true},
                {name: 'tax_name', type: 'string', isOptional: true},
                {name: 'tax_rate', type: 'number', isOptional: true},
                {name: 'is_tax_inclusive', type: 'boolean', isOptional: true},
                {name: 'is_open', type: 'boolean', isOptional: true},
                {name: 'archive', type: 'boolean', isOptional: true},
                {name: 'order_line_modifiers', type: 'string', isOptional: true},
                {name: 'note', type: 'string', isOptional: true},
                {name: 'void', type: 'boolean', isOptional: true},
                {name: 'void_reason', type: 'string', isOptional: true},
            ],
        }),
        tableSchema({
            name: ROLE_SCHEMA,
            columns: [
                {name: 'account_id', type: 'string'},
                {name: 'name', type: 'string'},
            ],
        }),
        tableSchema({
            name: FLOOR_PLAN_SCHEMA,
            columns: [
                {name: 'business_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'type', type: 'string'},
                {name: 'componentType', type: 'string'},
                {name: 'created_at', type: 'number'},
                {name: 'archive', type: 'boolean'},
            ],
        }),
        tableSchema({
            name: PAYMENT_METHOD_SCHEMA,
            columns: [
                {name: 'account_id', type: 'string'},
                {name: 'sales_tax_id', type: 'string', isOptional: true},
                {name: 'name', type: 'string'},
                {name: 'placement', type: 'number'},
                {name: 'created_at', type: 'number'},
            ],
        }),
        tableSchema({
            name: DINE_IN_TABLE_SCHEMA,
            columns: [
                {name: 'floor_plan_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'height', type: 'number'},
                {name: 'width', type: 'number'},
                {name: 'seats', type: 'number'},
                {name: 'shape', type: 'string'},
                {name: 'xAxis', type: 'number'},
                {name: 'yAxis', type: 'number'},
                {name: 'xCoord', type: 'number'},
                {name: 'yCoord', type: 'number'},
                {name: 'created_at', type: 'number'},
                {name: 'archive', type: 'boolean'},
                {name: 'is_active', type: 'boolean'},
            ],
        }),
        tableSchema({
            name: DISCOUNT_SCHEMA,
            columns: [
                {name: 'account_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'limit', type: 'number'},
                {name: 'max_discount_cap', type: 'number', isOptional: true},
                {name: 'is_inclusive', type: 'boolean', isOptional: true},
                {name: 'type', type: 'string', isOptional: true},
                {name: 'archive', type: 'boolean', isOptional: true},
                {name: 'draft', type: 'boolean', isOptional: true},
                {name: 'total_given', type: 'number', isOptional: true},
                {name: 'start_date', type: 'number', isOptional: true},
                {name: 'end_date', type: 'number', isOptional: true},
            ],
        }),
        tableSchema({
            name: DISCOUNT_BUSINESS_SCHEMA,
            columns: [
                {name: 'business_id', type: 'string'},
                {name: 'discount_id', type: 'string'},
            ],
        }),
        tableSchema({
            name: SALES_TAX_SCHEMA,
            columns: [
                {name: 'account_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'rate', type: 'number'},
                {name: 'is_inclusive', type: 'boolean'},
                {name: 'is_pre_discounted', type: 'boolean'},
                {name: 'is_selected_items', type: 'boolean'},
                {name: 'archive', type: 'boolean', isOptional: true},
                {name: 'created_at', type: 'number'},
                {name: 'draft', type: 'boolean', isOptional: true},
            ],
        }),
        tableSchema({
            name: PURCHASE_TAX_SCHEMA,
            columns: [
                {name: 'business_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'rate', type: 'number'},
                {name: 'archive', type: 'boolean', isOptional: true},
                {name: 'created_at', type: 'number'},
            ],
        }),
        tableSchema({
            name: COUNTER_SCHEMA,
            columns: [
                {name: 'user_id', type: 'string'},
                {name: 'auth_session_count', type: 'number'},
                {name: 'pos_session_count', type: 'number', isOptional: true},
                {name: 'order_count', type: 'number', isOptional: true},
            ],
        }),
        tableSchema({
            name: PURCHASE_ORDER_SCHEMA,
            columns: [
                {name: 'business_id', type: 'string'},
                {name: 'company_id', type: 'string'},
                {name: 'number', type: 'string'},
                {name: 'status', type: 'string'},
                {name: 'reference', type: 'string'},
                {name: 'ship_to', type: 'string'},
                {name: 'amount', type: 'number'},
                {name: 'purchase_order_discount', type: 'string', isOptional: true},
                {name: 'created_at', type: 'number'},
                {name: 'po_date', type: 'number', isOptional: true},
                {name: 'po_expected_date', type: 'number', isOptional: true},
                {name: 'shipping_method', type: 'string'},
                {name: 'archive', type: 'boolean', isOptional: true},
                {name: 'is_delivered', type: 'boolean'},
                {name: 'notes', type: 'string', isOptional: true},
                {name: 'notememo', type: 'string', isOptional: true},
            ],
        }),

        tableSchema({
            name: COMPANY_SCHEMA,
            columns: [
                {name: 'account_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'email', type: 'string', isOptional: true},
                {name: 'phone_number', type: 'string', isOptional: true},
                {name: 'country', type: 'string', isOptional: true},
                {name: 'province', type: 'string', isOptional: true},
                {name: 'city', type: 'string', isOptional: true},
                {name: 'address', type: 'string', isOptional: true},
                {name: 'postal_code', type: 'string', isOptional: true},
                {name: 'archive', type: 'boolean', isOptional: true},
                {name: 'draft', type: 'boolean', isOptional: true},
            ],
        }),
        tableSchema({
            name: PO_LINE_SCHEMA,
            columns: [
                {name: 'purchase_order_id', type: 'string'},
                {name: 'product_id', type: 'string'},
                {name: 'purchase_tax_id', type: 'string', isOptional: true},
                {name: 'name', type: 'string'},
                {name: 'unit', type: 'string'},
                {name: 'selling_price', type: 'number', isOptional: true},
                {name: 'cost_price', type: 'number', isOptional: true},
                {name: 'quantity', type: 'number', isOptional: true},
                {name: 'discount', type: 'number', isOptional: true},
                {name: 'archive', type: 'boolean', isOptional: true},
            ],
        }),
        tableSchema({
            name: PAYMENT_SCHEMA,
            columns: [
                {name: 'customer_id', type: 'string', isOptional: true},
                {name: 'business_id', type: 'string'},
                {name: 'order_id', type: 'string'},
                {name: 'expiry_date', type: 'number', isOptional: true},
                {name: 'completed_at', type: 'number', isOptional: true},
                {name: 'started_at', type: 'number', isOptional: true},
                {name: 'type', type: 'string', isOptional: true},
                {name: 'payment_method', type: 'string'},
                {name: 'card_number', type: 'string', isOptional: true},
                {name: 'created_at', type: 'number'},
                {name: 'status', type: 'string'},
                {name: 'amount', type: 'number'},
                {name: 'received_amount', type: 'number', isOptional: true},
                {name: 'change', type: 'number', isOptional: true},
                {name: 'txns_number', type: 'string', isOptional: true},
                {name: 'invoice_number', type: 'string', isOptional: true},
                {name: 'device_type', type: 'string', isOptional: true},
                {name: 'payment_intent', type: 'string', isOptional: true},
            ],
        }),
        tableSchema({
            name: CREDIT_TRANSACTION_SCHEMA,
            columns: [
                {name: 'customer_id', type: 'string', isOptional: true},
                {name: 'payment_method', type: 'string'},
                {name: 'status', type: 'string'},
                {name: 'amount', type: 'number'},
                {name: 'created_at', type: 'number'},
                {name: 'completed_at', type: 'number'},
            ],
        }),
        tableSchema({
            name: DEAL_SCHEMA,
            columns: [
                {name: 'account_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'amount', type: 'number'},
                {name: 'archive', type: 'boolean'},
                {name: 'created_at', type: 'number'},
            ],
        }),
        tableSchema({
            name: DEAL_PRODUCT_SCHEMA,
            columns: [
                {name: 'deal_id', type: 'string'},
                {name: 'product_id', type: 'string'},
                {name: 'quantity', type: 'number'},
            ],
        }),
        tableSchema({
            name: SETTING_CONFIG_SCHEMA,
            columns: [
                {name: 'business_id', type: 'string'},
                {name: 'name', type: 'string'},
                {name: 'sub_title', type: 'string'},
                {name: 'status', type: 'string', isOptional: true},
                {name: 'is_active', type: 'boolean', isOptional: true},
                {name: 'created_at', type: 'number'},
            ],
        }),
    ],
});
