import {useEffect, useState} from 'react';
import Icon from '../../../assets/icons';
import {firstLetterCaptilize, formatNum, UNITS} from '../../../constants';
import {useLanguageContext, useThemeContext} from '../../../context';
import TextField from '../TextField';
import TooltipComponent from '../V2/TooltipComponent';

const CreateVarientComponent = ({title, data, onChange, errors, index, handleDeleteVariant}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [showVarientFeild, setShowVarientFeild] = useState(false);
    const [profit, setProfit] = useState(0);
    const [margin, setMargin] = useState(0);
    const [profitTooltipToggle, setProfitTooltipToggle] = useState(false);
    const [marginTooltipToggle, setMarginTooltipToggle] = useState(false);
    const handleToggleVarientList = () => {
        setShowVarientFeild(x => !x);
    };
    useEffect(() => {
        handleCalculateProfitMargin(data?.sellingPrice, data?.costPrice);
    }, [data?.sellingPrice, data?.costPrice]);

    const handleCalculateProfitMargin = (sell, cost) => {
        let selling = sell || 0,
            costing = cost || 0;

        let totalProfit = selling - costing;
        let totalMargin = (totalProfit / costing) * 100;

        setProfit(totalProfit <= 0 ? 0 : totalProfit);
        setMargin(totalMargin <= 0 || totalMargin == Infinity ? 0 : totalMargin);
    };

    function ToggleMarginToolTip(x) {
        setMarginTooltipToggle(x);
    }
    function ToggleProfitToolTip(x) {
        setProfitTooltipToggle(x);
    }

    const {sellingPrice, costPrice, quantity, lowStock, id, name, barcode} = data;

    let is_invalid = {
        barcode: errors[name]?.barcode,
        costPrice: errors[name]?.costPrice,
        sellingPrice: errors[name]?.sellingPrice,
    };

    return (
        <>
            <p style={{background: theme.topBarBG}} className="itemSeperator"></p>

            <div className="flex spaceBetweenCenter">
                <div
                    key={index}
                    onClick={handleToggleVarientList}
                    className="variationsListButton width90"
                    style={{
                        borderColor: errors[data.name] ? theme.red : showVarientFeild ? theme.barclaysBlue : '',
                    }}>
                    <p className="fontSize14 fontWeight400 marBot0 " style={{color: theme.white}}>
                        {firstLetterCaptilize(title || '')}
                    </p>

                    <div className="rowConatainer">
                        <p className="fontSize14 fontWeight400 marBot0 marRight10" style={{color: theme.inputText}}>
                            {sellingPrice ? `Rs. ${formatNum(sellingPrice)}` : ''}
                        </p>
                        <Icon name={showVarientFeild ? 'arrowUpIcon' : 'downIcon2'} width="24" height="24" viewBox={'0 0 18 18'} fill={theme.white} />
                    </div>
                </div>
                <div className="deleteIconWrapper width10" onClick={() => handleDeleteVariant(title)}>
                    <Icon name="delete2Icon" fill={theme.red} width="18" height="18" viewBox={'0 0 18 18'} />
                </div>
            </div>

            {errors[data.name] && <p className="inputError">{firstLetterCaptilize(I18n.please_enter_all_required_fileds)}</p>}

            {showVarientFeild && (
                <div>
                    <h3 className="fontSize20 fontWeight400 marBot10 marTop10" style={{color: theme.text}}>
                        {I18n.pricing}
                    </h3>
                    <div className="intventoryFormGridTwo">
                        <div className="priceSectionInputLeft">
                            <TextField
                                onChange={(label, value) => onChange(title, 'sellingPrice', value)}
                                label={I18n.selling_price}
                                placeholder={I18n.rs_zero}
                                error={is_invalid['sellingPrice']}
                                value={sellingPrice}
                                type="numeric"
                                required={true}
                                maxLength={8}
                            />
                        </div>
                        <div className="priceSectionInputRight">
                            <TextField
                                onChange={(label, value) => onChange(title, 'costPrice', value)}
                                error={is_invalid['costPrice']}
                                label={I18n.cost_price}
                                placeholder={I18n.rs_zero}
                                value={costPrice}
                                type="numeric"
                                required={true}
                                maxLength={8}
                            />
                        </div>
                    </div>

                    {/* --- */}

                    <div className="intventoryFormGridTwo">
                        <div className="priceSectionInputLeft tooltipFeild" onMouseEnter={() => ToggleProfitToolTip(true)} onMouseLeave={() => ToggleProfitToolTip(false)}>
                            <TooltipComponent
                                titleName={`${I18n.profit}:`}
                                desc={I18n.profit_will_be_automatically_calculated_and_displayed_when_adding_selling_price_and_item_cost}
                                yesCta={{
                                    title: I18n.ok,
                                    action: () => ToggleProfitToolTip(false),
                                }}
                                toggle={profitTooltipToggle}
                                children={
                                    <TextField
                                        onChange={onChange}
                                        label={I18n.profit}
                                        hideInputError={true}
                                        placeholder={I18n.profit}
                                        value={profit ? `Rs. ${profit?.toFixed(2)}` : ''}
                                        disabled={true}
                                        suffix="infocircleIcon"
                                        suffix_fill={theme.inputText}
                                        suffix_width={'24'}
                                        suffix_height={'24'}
                                        suffix_viewBox={'0 0 18 18'}
                                    />
                                }
                            />
                        </div>
                        <div className="priceSectionInputRight tooltipFeild" onMouseEnter={() => ToggleMarginToolTip(true)} onMouseLeave={() => ToggleMarginToolTip(false)}>
                            <TooltipComponent
                                titleName={`${I18n.margin}:`}
                                desc={I18n.margin_will_be_automatically_calculated_and_displayed_when_adding_selling_price_and_item_cost}
                                yesCta={{
                                    title: I18n.ok,
                                    action: () => ToggleMarginToolTip(false),
                                }}
                                toggle={marginTooltipToggle}
                                children={
                                    <TextField
                                        onChange={onChange}
                                        label={I18n.margin}
                                        placeholder={I18n.margin}
                                        value={margin ? `${margin?.toFixed(2)}%` : ''}
                                        disabled={true}
                                        hideInputError={true}
                                        suffix="infocircleIcon"
                                        suffix_fill={theme.inputText}
                                        suffix_width={'24'}
                                        suffix_height={'24'}
                                        suffix_viewBox={'0 0 18 18'}
                                    />
                                }
                            />
                        </div>
                    </div>

                    <h3 className="fontSize20 fontWeight400 marBot10" style={{color: theme.text}}>
                        {I18n.inventory}
                    </h3>

                    <div className="intventoryFormGridTwo">
                        <div className="priceSectionInputLeft">
                            <TextField
                                onChange={(label, value) => onChange(title, 'quantity', value)}
                                label={I18n.add_quantity}
                                placeholder={I18n.add_quantity}
                                value={quantity}
                                type="numeric"
                                maxLength={8}
                            />
                        </div>
                        <div className="priceSectionInputRight">
                            <TextField
                                onChange={(label, value) => onChange(title, 'lowStock', value)}
                                label={I18n.stock_alert}
                                placeholder={I18n.stock_alert}
                                value={lowStock}
                                type="numeric"
                                maxLength={8}
                            />
                        </div>
                    </div>

                    <div className="intventoryFormGridTwo">
                        <div className="priceSectionInputLeft">
                            <TextField
                                onChange={(label, value) => onChange(title, 'barcode', value)}
                                label={I18n.barcode}
                                error={is_invalid['barcode']}
                                placeholder={I18n.enter_barcode}
                                value={barcode}
                                type="text"
                                required={true}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CreateVarientComponent;
