import {useThemeContext} from '../../../context';
import {Dropdown} from 'react-bootstrap';
import TextField from '../TextField';

const DropdownInputList = ({dropDown, selected, setSelected}) => {
    const {theme} = useThemeContext();

    const onChange = (lable, value) => {
        setSelected(prev => {
            let all = {...prev};
            all[lable] = value?.value || value;
            return all;
        });
    };

    return dropDown.textInputs.map((item, index) => {
        return (
            <Dropdown.Item key={index} value={item.value} onClick={event => event.stopPropagation()}>
                <TextField
                    suffix_viewBox={'0 0 18 18'}
                    value={selected[item.label]}
                    suffix={item.suffix || ''}
                    suffix_fill={theme.white}
                    suffix_height={'24'}
                    suffix_width={'24'}
                    onChange={onChange}
                    key={index}
                    {...item}
                />
            </Dropdown.Item>
        );
    });
};

export default DropdownInputList;
