import {Autocomplete, InputAdornment, TextField} from '@mui/material';
import Icon from '../../../assets/icons';
import {capitalizeFirstAlphabet} from '../../../constants';

const TextBox = ({inputType, maxLength, data, onClick, label, type, rows, inputRef, highlighter, setOptionAsLabel, variant, required, suffixIcon, ...rest}) => {
    const myHighlighter = (props, option) => {
        if (label === 'Table shape') {
            return (
                <span
                    {...props}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        color: option.color,
                        width: '100%',
                        padding: '10px 10px',
                    }}>
                    {option.name}
                    <Icon name={option?.iconName} width={option?.width} height={option?.height} viewBox={option?.viewBox} />
                </span>
            );
        } else {
            return (
                <>
                    <span {...props} style={{color: option.color}}>
                        {option.name}
                    </span>
                </>
            );
        }
    };

    return inputType === 'select' ? (
        <Autocomplete
            ref={inputRef}
            // open={open}
            // onFocus={handleFocus}
            // onBlur={handleBlur}

            clearIcon={false}
            popupIcon={suffixIcon}
            blurOnSelect
            autoComplete
            includeInputInList
            options={data?.length ? data : []}
            getOptionLabel={option => setOptionAsLabel && capitalizeFirstAlphabet(option?.name || option)}
            label={label}
            type={type}
            renderOption={highlighter ? myHighlighter : null}
            renderInput={params => <TextField {...params} label={label} required={required} onClick={() => onClick && onClick(label)} variant="filled" inputRef={inputRef} />}
            {...rest}
        />
    ) : inputType === 'textarea' ? (
        <TextField
            id="filled-multiline-static"
            label={label}
            multiline
            rows={rows}
            required={required}
            // defaultValue="Default Value"
            variant="filled"
            {...rest}
        />
    ) : (
        <TextField
            prefix="Rs."
            InputProps={{
                endAdornment: suffixIcon,
            }}
            inputRef={inputRef}
            inputProps={{maxLength, inputMode: type}}
            autoComplete="off"
            variant={variant}
            label={label}
            type={type}
            required={required}
            {...rest}
        />
    );
};

export default TextBox;
