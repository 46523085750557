import React, {useEffect, useState} from 'react';
import {Col, FormCheck, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {TextField, Header, TaxesModal, CustomContainer} from '../../common';
import {
    convertArrayToObj,
    filterOptions,
    filterProductRelation,
    firstLetterCaptilize,
    getAccountBusiness,
    getHashMap,
    getLocationNames,
    INITIAL_PAGE,
    ITEMS_PER_PAGE,
    toSnakeCase,
    uuid,
    validateDealDraftForm,
    validateDealForm,
} from '../../../constants';
import {useNavigate} from 'react-router-dom';
import Icon from '../../../assets/icons';
import Modal from '../../screens/Authenticated/Restaurant/Modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import {isCategoryExist, queryItems} from '../../../pos-core/database/helpers';
import ItemList from '../../screens/Authenticated/Restaurant/Modifiers/CreateModifier/ItemList';
import LocationModal from '../LocationModal';
import DealsItem from '../DealsItem';
import {bulkCreateProductBusiness, bulkDeleteProductBusiness} from '../../../pos-core/database/helpers/product_business';
import {bulkCreateProdDeal, bulkDeleteDealProductRelation, bulkUpdateDealItems, getDealProducts} from '../../../pos-core/database/helpers/deal_product';

const CreateDealComponent = ({deal}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {user, business, setRenderToast, userBusiness, account, persistData, setPersistData} = useAuthContext();
    let navigate = useNavigate();

    const [itemModal, setItemModal] = useState(false);
    const [name, setName] = useState('');
    const [amount, setAmount] = useState(0);
    const [tax, setTax] = useState('');
    const [taxModal, setTaxModal] = useState(false);
    const [locationsObj, setLocationsObj] = useState();
    const [selectedLocations, setSelectedLocations] = useState();
    const [discountRules, setDiscountRules] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [locationModal, setLocationModal] = useState(false);
    const [location, setLocation] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [cloneSelectedItems, setCloneSelectedItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(true);
    const [search, setSearch] = useState('');
    const [dealObj, setDealObj] = useState();

    const handleEnterPress = event => {
        if (event.key === 'Enter' && !Object.keys(errors).length) {
            handleCreateDeal('save');
        }
    };

    const onChange = (label, val) => {
        if (label == I18n.deal_name) setName(val);
        else if (label == I18n.amount) setAmount(val);
        else if (label == I18n.location) setLocationModal(true);
        else if (label == I18n.taxes) setTaxModal(true);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    async function handleCreateDeal(val) {
        try {
            if (selectedLocations?.hasOwnProperty('all')) {
                delete selectedLocations['all'];
            }
            const locationsArray = Object.values(selectedLocations || {}).map(item => locationsObj[item]);

            let createdCategory = await isCategoryExist('Deals');
            if (!createdCategory) {
                let paramsForCategory = {
                    name: 'Deals',
                    color_code: '#8F9397',
                };
                createdCategory = await account.createCategory(paramsForCategory);
            }

            let paramsForTemplate = {
                barcode: 'deal',
                category: createdCategory,
                locationsArray,
                name,
                unit: 'Pieces',
                amount,
                discountRules,
                selectedItems,
            };

            const formErrors = val === 'saveDraft' ? await validateDealDraftForm(paramsForTemplate) : await validateDealForm(paramsForTemplate);
            console.log('form errors', formErrors);
            setErrors(formErrors);
            if (Object.keys(formErrors).length > 0) return;

            setLoading(true);
            let template = '';
            if (!deal) {
                template = await account.createProductTemplate(paramsForTemplate);
            }

            let base_price = Number(amount || 0);
            if (tax?.is_inclusive) {
                base_price = (amount * (100 / (100 + tax?.rate))).toFixed(2);
            }

            let paramsForProduct = {
                name,
                account,
                barcode: uuid(),
                base_price,
                category: createdCategory,
                draft: val === 'saveDraft',
                low_stock: 0,
                margin: 0,
                profit: 0,
                quantity: 0,
                tax,
                cost_price: Number(amount),
                selling_price: Number(amount),
                type: 'deal',
            };

            const createdProduct = deal ? await deal?.product.updateDetails(paramsForProduct) : await template.createProduct(paramsForProduct);

            if (deal) {
                const product_businessess = await deal.product.product_business.fetch();
                const {additionArray, deletionArray} = filterOptions(product_businessess, locationsArray, 'business');

                const prodBusPayload = {
                    locations: additionArray,
                    product: createdProduct,
                    quantity: 0,
                };

                await bulkCreateProductBusiness(prodBusPayload);
                await bulkDeleteProductBusiness(deletionArray);
            } else {
                const prodBusPayload = {
                    locations: locationsArray,
                    product: createdProduct,
                    quantity: 0,
                };
                await bulkCreateProductBusiness(prodBusPayload);
            }

            const dealPayload = {
                name,
                amount: Number(amount),
            };

            const createdDeal = deal ? await dealObj.updateDeal(dealPayload) : await account.createDeal(dealPayload);

            if (!deal) {
                await createdProduct.setProductDeal(createdDeal);
            }

            if (deal) {
                const {additionProdArray, deletionProdArray, updationArray} = filterProductRelation(cloneSelectedItems, selectedItems);

                await bulkCreateProdDeal(dealObj, additionProdArray);
                await bulkUpdateDealItems(dealObj, updationArray);
                await bulkDeleteDealProductRelation(dealObj, deletionProdArray);
            } else {
                await bulkCreateProdDeal(createdDeal, selectedItems);
            }

            // const toastMessage = deal ? I18n.deal_updated_successfully : I18n.deal_created_successfully;

            let toastMessage;
            if (val === 'saveDraft') {
                toastMessage = I18n.deal_saved_into_draft;
            } else {
                toastMessage = deal ? I18n.deal_updated_successfully : I18n.deal_created_successfully;
            }

            setLoading(false);
            setRenderToast(toastMessage);
            setPersistData(null);
            navigate('/deals');
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    }

    const handleDiscountRules = e => {
        setDiscountRules(e.target.checked);
    };

    const handleItemsModal = () => {
        setItemModal(true);
    };

    const closeModal = () => {
        setSelectedItems([]);
        setItemModal(false);
    };

    const saveModal = () => {
        setSelectedItems(prev =>
            prev.map(item => ({
                ...item,
                quantity: item.quantity || 1,
            })),
        );

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors['item_list']) {
                delete updatedErrors[toSnakeCase('item_list')];
            }
            return updatedErrors;
        });

        setItemModal(false);
    };

    const onChangeSearch = async (event, key) => {
        let value = key || event?.target?.value || '';
        setSearch(value);
        const {data, totalCount} = await queryItems('', value.toLowerCase(), INITIAL_PAGE, ITEMS_PER_PAGE, false);
        setProducts(data);
        setIsFetching(false);
        setCurrentPage(INITIAL_PAGE);
        setHasMore(totalCount > ITEMS_PER_PAGE);
    };

    const fetchMoreData = () => {
        setIsFetching(true);
        setCurrentPage(prevPage => prevPage + 1);
    };

    const fetchData = async pageNum => {
        const {data} = await queryItems('', search.toLowerCase(), pageNum, ITEMS_PER_PAGE, false);
        if (data.length === 0) {
            setHasMore(false);
        } else {
            setProducts(prevData => [...prevData, ...data]);
        }
        setIsFetching(false);
    };

    const handleToggleLocation = () => {
        setLocationModal(true);
        handlePersistData();
    };

    async function getBusinessOfAccount() {
        const singleSelection = false;
        const businessLocations = await getAccountBusiness(account, business, userBusiness, singleSelection);
        const businessObj = await getHashMap(account);

        setLocation(businessLocations);
        setLocationsObj(businessObj);
    }

    const handleToggleTaxModal = () => {
        setTaxModal(true);
        handlePersistData();
    };

    const handleSalesTax = async val => {
        if (val == 'remove_saleTax') {
            setTax('');
        } else {
            setTax(val);
        }
        setTaxModal(false);
    };

    const setInitialValues = async () => {
        if (deal) {
            const tax = await deal.product.sales_tax.fetch();
            const prodBusiness = await deal.product.product_business.fetch();
            const dealProd = await deal.product.deal.fetch();
            const dealProducts = await getDealProducts(dealProd.id);

            const products = await Promise.all(
                dealProducts.map(async dealProduct => {
                    const prod = await dealProduct.product.fetch();
                    return {
                        id: prod.id,
                        item: prod,
                        quantity: dealProduct.quantity,
                    };
                }),
            );

            setDealObj(dealProd);
            setName(deal.product?.name);
            setAmount(deal.product?.selling_price);
            setSelectedLocations(convertArrayToObj(prodBusiness));
            setTax(tax);
            setDiscountRules(true);
            setSelectedItems(products);
            setCloneSelectedItems(products);
        } else if (Object.keys(persistData?.deal)?.length > 0) {
            setDealObj(persistData?.deal?.dealObj);
            setName(persistData?.deal?.name);
            setAmount(persistData?.deal?.amount);
            setSelectedLocations(persistData?.deal?.selectedLocations);
            setTax(persistData?.deal?.tax);
            setDiscountRules(persistData?.deal?.discountRules);
            setSelectedItems(persistData?.deal?.selectedItems);
            setCloneSelectedItems(persistData?.deal?.cloneSelectedItems);
        }
    };

    useEffect(() => {
        if (deal || Object?.keys(persistData?.deal || {})?.length > 0) {
            setInitialValues();
        }
        getBusinessOfAccount();
    }, [deal, persistData?.deal]);

    useEffect(() => {
        if (isFetching) {
            fetchData(currentPage);
        }
    }, [currentPage, isFetching]);

    useEffect(() => {
        if (Object.values(selectedLocations || {}).length > 0) {
            setErrors(prevErrors => {
                const updatedErrors = {...prevErrors};
                let label = 'Location';
                if (updatedErrors[toSnakeCase(label)]) {
                    delete updatedErrors[toSnakeCase(label)];
                }

                return updatedErrors;
            });
        }
    }, [selectedLocations]);

    const handlePersistData = () => {
        let dealPersisitData = {
            dealObj: dealObj,
            name: name,
            amount: amount,
            selectedLocations: selectedLocations,
            tax: tax,
            discountRules: discountRules,
            selectedItems: selectedItems,
            cloneSelectedItems: cloneSelectedItems,
        };
        setPersistData({deal: dealPersisitData});
    };

    const handleBack = () => {
        navigate(-1);
        setPersistData(null);
    };

    return (
        <>
            <div onKeyDown={handleEnterPress}>
                <Header
                    type="draftHeader"
                    title={I18n.back}
                    saveCta={{
                        title: I18n.save,
                        disabled: loading,
                        saveLoad: loading,
                        action: () => handleCreateDeal('save'),
                    }}
                    backAction={handleBack}
                    primaryCta={
                        deal
                            ? null
                            : {
                                  title: I18n.save_draft,
                                  action: () => handleCreateDeal('saveDraft'),
                              }
                    }
                />

                <CustomContainer reduceHeight={110} className="addScreenMainBox">
                    <Row className="justify-content-md-center">
                        <Col md={6}>
                            <div className="signupTextBox">
                                <h3 className="fontSize24  textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                                    {deal ? I18n.edit_deal : I18n.create_deal}
                                </h3>
                                <h3 className="fontSize20 fontWeight400 marBot15 marTop0 marLeft15" style={{color: theme.text}}>
                                    {I18n.deal_details}
                                </h3>

                                <div className="itemFields">
                                    <div>
                                        <TextField
                                            onChange={onChange}
                                            error={errors['deal_name']}
                                            label={I18n.deal_name}
                                            value={name}
                                            required={true}
                                            autoFocus={true}
                                            maxLength={100}
                                        />
                                    </div>
                                    <div className="intventoryFormGridTwo">
                                        <div className="priceSectionInputLeft" onClick={handleToggleLocation}>
                                            <TextField
                                                label={I18n.location}
                                                placeholder={I18n.location}
                                                error={errors['location']}
                                                onChange={onChange}
                                                value={getLocationNames(locationsObj, selectedLocations)}
                                                inputType="text"
                                                type="text"
                                                required={true}
                                                suffix="downIcon2"
                                                suffix_fill={theme.white}
                                                suffix_width={'24'}
                                                suffix_height={'24'}
                                                suffix_viewBox={'0 0 18 18'}
                                            />
                                        </div>
                                        <div className="priceSectionInputRight" onClick={handleToggleTaxModal}>
                                            <TextField
                                                label={I18n.taxes}
                                                placeholder={I18n.taxes}
                                                value={tax ? `${tax?.name} ${tax?.rate}%` : ''}
                                                onChange={onChange}
                                                inputType="text"
                                                type="text"
                                                suffix="downIcon2"
                                                suffix_fill={theme.white}
                                                suffix_width={'24'}
                                                suffix_height={'24'}
                                                suffix_viewBox={'0 0 18 18'}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <TextField
                                            onChange={onChange}
                                            error={errors['amount']}
                                            label={I18n.amount}
                                            value={amount}
                                            required={true}
                                            onlyNumbers={true}
                                            maxLength={7}
                                        />
                                    </div>
                                    <div className="divider width100 marTop10" />
                                    <div>
                                        <h3 className="fontSize20 fontWeight400  marTop20" style={{color: theme.text}}>
                                            {I18n.deal_discount}
                                        </h3>
                                        <div className="flex marLeft15 marTop20 marBot20">
                                            <div>
                                                <FormCheck type="switch" id="stock_available" onChange={e => handleDiscountRules(e)} checked={discountRules} />
                                            </div>
                                            <div>
                                                <p className="marBot0 fontSize14 marLeft20 fontWeight400" style={{color: theme.white}}>
                                                    {I18n.discount_rules}
                                                </p>
                                                <p className="marBot0 fontSize12 marLeft20 fontWeight400" style={{color: theme.darkGrayTwo}}>
                                                    {I18n.apply_this_discount_automatically}
                                                </p>

                                                {errors['item_list'] && (
                                                    <p className="inputError" style={{marginLeft: '20px'}}>
                                                        {firstLetterCaptilize(errors['item_list'])}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        {discountRules && (
                                            <div>
                                                <p className="marBot0 fontSize14 marBot20 fontWeight400" style={{color: theme.white}}>
                                                    {I18n.apply_to_items}
                                                </p>
                                                <div className="addItemWrapper cursorPointer" onClick={() => handleItemsModal()}>
                                                    <Icon name={'add2Icon'} width="24" height="24" viewBox={'0 0 18 18'} fill={theme.barclaysBlue} />
                                                    <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.barclaysBlue}}>
                                                        {I18n.add_items}
                                                    </p>
                                                </div>
                                                {selectedItems?.length > 0 && <DealsItem itemList={selectedItems} setSelectedItems={setSelectedItems} amount={amount} />}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CustomContainer>
            </div>

            <LocationModal
                toggle={locationModal}
                setToggle={setLocationModal}
                selectedLocations={selectedLocations}
                setSelectedLocations={setSelectedLocations}
                locations={location}
                singleSelection={false}
            />

            <TaxesModal setToggle={setTaxModal} toggle={taxModal} handleSelect={handleSalesTax} data={tax} />
            <Modal
                toggle={itemModal}
                setToggle={setItemModal}
                title={I18n.select_items}
                searchPlaceholder={I18n.search_item_by_name}
                onChange={onChangeSearch}
                scrollable={false}
                bottomCancelCta={{
                    title: I18n.cancel,
                    action: () => closeModal(),
                }}
                bottomSaveCta={{
                    title: I18n.confirm,
                    action: () => saveModal(),
                }}
                targetId="scrollableDiv"
                children={
                    <InfiniteScroll
                        className="infiniteScrollBar"
                        dataLength={products.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget="scrollableDiv">
                        {products?.map(item => (
                            <ItemList item={item} setSelectedItems={setSelectedItems} selectedItems={selectedItems} setErrors={setErrors} />
                        ))}
                    </InfiniteScroll>
                }></Modal>
        </>
    );
};

export default CreateDealComponent;
