import debounce from 'lodash.debounce';
import React, {useEffect, useMemo, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import {getSalesTransationsList, getTransationCSV} from '../../../../../../api';
import {DEVICE_HEIGHT, downloadCsvVReport, formatNum, formateIdForApi, removeTime} from '../../../../../../constants';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../../context';
import {Header, NonIdealScreen} from '../../../../../common';
import PaginationLoader from '../../../../../common/PaginationLoader';
import TotalTiles from '../../../../../common/TotalTiles';
import FilterItem from './FilterItem';
import HeaderItem from './HeaderItem';
import ListItem from './ListItem';

const TransactionList = ({selectedDateRange, selectedLocation}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business} = useAuthContext();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalpages, setTotalPages] = useState(0);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [transationData, setTransationData] = useState([]);
    const [filterOption, setFilterOption] = useState({});
    const [text, setText] = useState('');
    const locationString = formateIdForApi(selectedLocation, business?.id);
    const isMuiltlocation = locationString.split(',')?.length > 1;
    const [isExportLoading, setIsExportLoading] = useState(false);

    const getHeaderData = isMuiltlocation => {
        const headers = [
            {name: 'amount', className: 'justifyStart', sorting: true},
            {name: 'date_and_time', className: 'justifyCenter', sorting: true},
            {name: 'payment_method', className: 'justifyCenter', sorting: true},
            {name: 'customer_id', className: 'justifyCenter'},
            {name: 'transaction_id', className: 'justifyCenter'},
            {name: 'payment_type', className: 'justifyCenter'},
        ];

        if (isMuiltlocation) {
            headers.push({name: 'location', className: 'justifyCenter'});
        }

        return headers;
    };

    let HEADER_DATA = getHeaderData(isMuiltlocation);

    const handleExport = async () => {
        setIsExportLoading(true);
        try {
            let params = {
                business_ids: locationString,
                start_date: removeTime(selectedDateRange.start_date),
                end_date: removeTime(selectedDateRange?.end_date),
            };
            const response = await getTransationCSV(params);
            downloadCsvVReport(response, 'sales_transactions.csv');
            setIsExportLoading(false);
        } catch (error) {
            console.log('Error in Activity Export API --->', error);
            setIsExportLoading(false);
        }
    };
    const onChange = (label, value) => {
        setText(value);
    };

    const debouncedResults = useMemo(() => debounce(onChange, 500), []);

    const handleSort = column => {
        if (sortColumn === column) {
            if (sortOrder === 'asc') {
                setSortOrder('desc');
            } else if (sortOrder === 'desc') {
                setSortOrder(null);
                setSortColumn(null);
            } else {
                setSortOrder('asc');
            }
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    const sortedTransactions = useMemo(() => {
        if (!sortOrder || !sortColumn) return transationData;
        return [...transationData].sort((a, b) => {
            let valueA = a[sortColumn];
            let valueB = b[sortColumn];
            if (sortColumn === 'date_and_time') {
                valueA = new Date(a['created_at']);
                valueB = new Date(b['created_at']);
            }
            if (sortColumn === 'payment_method') {
                valueA = a['payment_method'];
                valueB = b['payment_method'];
            }
            if (sortOrder === 'asc') {
                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return valueA.localeCompare(valueB);
                } else {
                    return valueA > valueB ? 1 : -1;
                }
            } else if (sortOrder === 'desc') {
                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return valueB.localeCompare(valueA);
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            }
            return 0;
        });
    }, [transationData, sortColumn, sortOrder]);

    const getTransationData = async page => {
        if (!loading) setLoading(true);
        try {
            let params = {
                start_date: removeTime(selectedDateRange.start_date),
                end_date: removeTime(selectedDateRange?.end_date),
                business_ids: locationString,
                page: page || 1,
                limit: 20,
                filter: filterOption,
                keyword: text,
            };
            const response = await getSalesTransationsList(params);
            if (response?.success) {
                setTransationData(prevData => [...prevData, ...response?.transactions?.data]);
                setCurrentPage(page);
                setTotalPages(response?.transactions?.total_pages);

                setLoading(false);
            }
        } catch (error) {
            console.log('Error in transaction API --->', error);
            setLoading(false);
        }
    };

    const handleFilter = data => {
        const {amount = {}, payment_method = [], transaction_status = []} = data;
        let filterData = {};

        if (amount.min != null && amount.max != null) {
            filterData.amount = {
                min: Number(amount.min),
                max: Number(amount.max),
            };
        }

        if (payment_method.length > 0) {
            filterData.payment_type = payment_method;
        }

        if (transaction_status?.length > 0) {
            filterData.status = transaction_status;
        }

        setFilterOption(filterData);
    };

    const fetchMoreData = () => {
        getTransationData(currentPage + 1);
    };

    useEffect(() => {
        setTransationData([]);
        setCurrentPage(1);
        getTransationData(1);
    }, [selectedDateRange, selectedLocation, text, filterOption]);

    return (
        <section className="layoutMainBox" style={{backgroundColor: theme.topBarBG, padding: '15px 10px'}}>
            <div className="layoutMainInnerBox">
                <Header
                    type="filterHeader"
                    title={I18n.transactions}
                    search={{
                        placeholder: I18n.search_by_transaction_id,
                        onChange: debouncedResults,
                        prefix: {
                            name: 'search2Icon',
                            fill: theme.white,
                        },
                    }}
                    filterOption={{
                        handleFilter: handleFilter,
                        loading: loading,
                        filterBasedOn: ['amount', 'payment_method', 'transaction_status'],
                    }}
                    rightCta={{
                        title: I18n.export,
                        action: handleExport,
                        saveLoad: isExportLoading,
                    }}
                />

                <Container fluid>
                    <Row className="transactionList">
                        {loading && !transationData?.length ? (
                            <PaginationLoader customeHeight={365} />
                        ) : (
                            <Col md={12} xs={12}>
                                {transationData?.length > 0 ? (
                                    <>
                                        <HeaderItem isMuiltlocation={isMuiltlocation} data={HEADER_DATA} handleSort={handleSort} sortColumn={sortColumn} sortOrder={sortOrder} />
                                        <div
                                            id="scrollableDiv"
                                            className="selectCustomerListBox tableListMainBox"
                                            style={{
                                                height: DEVICE_HEIGHT - 387,
                                            }}>
                                            <InfiniteScroll
                                                className="infiniteScrollBar"
                                                dataLength={transationData.length}
                                                next={fetchMoreData}
                                                hasMore={currentPage < totalpages}
                                                loader={<PaginationLoader />}
                                                scrollableTarget="scrollableDiv">
                                                {sortedTransactions?.map((item, index) => (
                                                    <ListItem isMuiltlocation={isMuiltlocation} key={item.transaction_id || index} item={item} />
                                                ))}
                                            </InfiniteScroll>
                                        </div>
                                    </>
                                ) : (
                                    <NonIdealScreen
                                        heading={I18n.no_Payments_found}
                                        customHeight={325}
                                        width={60}
                                        height={60}
                                        backgroundColor={theme.blackBg}
                                        subHeading={I18n.there_arent_any_results_for_that_query_Try_using_different_filters}
                                        name="receiptSeacrchIcon"
                                        fill={theme.white}
                                        isMultiStep={false}
                                    />
                                )}
                            </Col>
                        )}
                    </Row>
                </Container>
            </div>
        </section>
    );
};

export default TransactionList;
