import {database} from '..';
import {uuid} from '../../constants';
import {Q} from '@nozbe/watermelondb';
import {ACCOUNT_SCHEMA, BUSINESS_SCHEMA, CUSTOMER_SCHEMA} from '../schema';

const customer = database.collections.get(CUSTOMER_SCHEMA);

export const observeActiveCustomers = () => customer.query(Q.where('archive', Q.notEq(true)), Q.where('draft', Q.notEq(true))).observe();

export const observeArchivedCustomers = () => customer.query(Q.where('archive', Q.eq(true))).observe();

export const observeDraftCustomers = () => customer.query(Q.where('draft', Q.eq(true))).observe();

export const fetchCustomersByDate = async (start, end) =>
    await customer.query(Q.where('archive', Q.notEq(true)), Q.where('created_at', Q.between(Number(start), Number(end)))).fetch();

export const isCustomerExist = phone_number => customer.query(Q.where('phone_number', Q.eq(phone_number))).fetch();

const prepareInsertion = (data, account) => {
    return data.map(item => {
        try {
            return customer.prepareCreate(field => {
                field.account.set(account);
                field._raw.id = item.id;
                field.name = item.name;
                field.phone_number = item.phone_number;
                field.email = item.email;
                field.province = item.province;
                field.city = item.city;
                field.country = item.country;
                field.dob = new Date(item.dob);
                field.address = item.address;
                field.created_at = new Date()?.getTime();
                field.archive = false;
                field.draft = false;
            });
        } catch (err) {
            console.log(err);
        }
    });
};

export const bulkCreateCustomer = async (data, account) => {
    await database.write(async () => {
        const allRecords = prepareInsertion(data, account);
        await database.batch(allRecords);
    });
};
