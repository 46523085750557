import {Col, Container, Row} from 'react-bootstrap';
import {Button, SearchBox} from '..';
import {firstLetterCaptilize} from '../../../constants';
import {useLanguageContext, useThemeContext} from '../../../context';
import FilterItem from '../../screens/Authenticated/Payments/Transactions/TransactionList/FilterItem';

const FilterHeader = ({rightCta, search, title, className, filterOption}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    return (
        <section style={{background: theme.blackBg}} className={`topBarMainCont  filterHeaderLeft ${className}`}>
            <Container fluid>
                <Row>
                    <Col md={2}>
                        <div className="topBarLeftSide ">
                            {title && (
                                <p className="fontSize18 fontWeight500 marLeft10 marBot0" style={{color: theme.white}}>
                                    {firstLetterCaptilize(title || '')}
                                </p>
                            )}
                        </div>
                    </Col>
                    <Col md={10}>
                        <div className="topBarRightSide">
                            {search && <SearchBox type="pos" {...search} />}

                            {filterOption && (
                                <div className="walletFilterBox">
                                    <FilterItem
                                        handleFilter={filterOption.handleFilter}
                                        filterBasedOn={filterOption.filterBasedOn}
                                        loading={filterOption.loading}
                                        urlFilter={filterOption.urlFilter}
                                    />
                                </div>
                            )}

                            {rightCta && (
                                <Button
                                    className={`headerRightCta marLeft15  fontWeight500 fontSize16 cursorPointer`}
                                    backgroundColor={rightCta.backgroundColor || theme.brightGreen}
                                    txtColor={rightCta.txtColor || theme.white}
                                    title={rightCta.title}
                                    handleClick={rightCta.action}
                                    borderColor={rightCta.borderColor || theme.brightGreen}
                                    saveLoad={rightCta.saveLoad}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};
export default FilterHeader;
