import {useLanguageContext, useThemeContext} from '../../../context';
import {TextField} from '../../common';
import {InputField} from '../V2';

const SearchBox = ({prefixFill, type, ...rest}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    return (
        <>
            {type == 'pos' ? (
                <div className="newposSearchBar width100">
                    <InputField
                        {...rest}
                        type="text"
                        // inputType={"searchinput"}
                    />
                </div>
            ) : (
                <div className="posSearchBar">
                    <TextField
                        {...rest}
                        type="text"
                        inputType={'searchinput'}
                        prefix="search2Icon"
                        prefix_fill={prefixFill || 'blackFont'}
                        prefix_width={'15'}
                        prefix_height={'15'}
                        prefix_viewBox={'0 0 18 18'}
                    />
                </div>
            )}
        </>
    );
};

export default SearchBox;
