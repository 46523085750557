import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useThemeContext} from '../../../../../../context';
import TableBody from '../../../../../common/TableContainer/TableBody';
import {capitalizeFirstAlphabet, formatDateAndTimewithoutYear, formatNum} from '../../../../../../constants';
import Icon from '../../../../../../assets/icons/';
import StatusBox from '../../../../../common/StatusBox';

const ListItem = ({index, item}) => {
    const {theme} = useThemeContext();
    const navigate = useNavigate();
    const {type, amount, timestamp, transaction_id, account_title, status} = item;

    const iconMap = {
        payout: {name: 'payoutNewIcon', color: theme.mustardYellow},
        deposits: {name: 'depositIcon', color: theme.brightGreen},
        failed: {name: 'failIcon', color: theme.brightOrange},
        refund: {name: 'refundIcon', color: theme.brightOrange},
    };

    const {name: iconName, color: iconColor} = iconMap[type] || iconMap.refund;

    const handlePayment = () => navigate(`/activity/${transaction_id}`);

    return (
        <TableBody style={{borderColor: theme.inputBorder}} className={`fiveColumn cursorPointer`} onClick={handlePayment} key={index}>
            <div className="BoxWidth  justifyStart textwithLebal">
                <Icon name={iconName} fill={iconColor} width="24" height="24" viewBox="0 0 18 18" />
                <p className="fontSize14 fontWeight600 OneLineTruncate marBot0" style={{color: theme.white}}>
                    {capitalizeFirstAlphabet(type) || '--'}
                </p>
                {status && <StatusBox status={status} labelChip={true} />}
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    Rs. {formatNum(amount)}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {formatDateAndTimewithoutYear(new Date(timestamp))}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.barclaysBlue}}>
                    {transaction_id || '--'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate marLeft20" style={{color: theme.white}}>
                    {account_title || '--'}
                </p>
            </div>
        </TableBody>
    );
};

export default ListItem;
