import {Col, Container, Row} from 'react-bootstrap';
import {CheckBox, Header, Loading, MessageModal, NonIdealScreen, ToastBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useLocation, useNavigate} from 'react-router-dom';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import {DEVICE_HEIGHT} from '../../../../../constants';
import List from './List';
import {useSearch} from '../../../../../hooks';
import {useEffect, useState} from 'react';
import usePermission from '../../../../../hooks/usePermission';
import withObservables from '@nozbe/with-observables';
import {observeActiveDeals, observeArchiveDeals, observeDraftDeals} from '../../../../../pos-core/database/helpers';

const DealList = ({activeDeals, archivedList, draftList}) => {
    const {state} = useLocation();
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {text, setText, searchList} = useSearch(handleSearch);
    const [filter, setFilter] = useState(state?.isArchived ? I18n.archived : I18n.all_deals);
    const [loading, setloading] = useState(false);
    const [archiveModal, setArchiveModal] = useState(false);
    const [selected, setSelected] = useState({});
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });
    const {renderToast, setRenderToast} = useAuthContext();
    const userPermission = usePermission('deals');

    const messagesMap = {
        [I18n.deal_created_successfully]: I18n.deal_created_successfully,
        [I18n.deal_updated_successfully]: I18n.deal_updated_successfully,
        [I18n.deal_archived_successfully]: I18n.deal_archived_successfully,
        [I18n.deal_unarchived_successfully]: I18n.deal_unarchived_successfully,
        [I18n.deal_saved_into_draft]: I18n.deal_saved_into_draft,
    };

    const filterOption = [
        {
            title: I18n.all_deals,
            action: () => handleFilter(I18n.all_deals),
            checked: filter == I18n.all_deals || filter === '' ? true : false,
        },
        {
            title: I18n.archived,
            action: () => handleFilter(I18n.archived),
            checked: filter == I18n.archived ? true : false,
        },
        {
            title: I18n.drafts_saved,
            action: () => handleFilter(I18n.drafts_saved),
            checked: filter == I18n.drafts_saved ? true : false,
        },
    ];

    const actionList = [
        {
            title: filter == I18n.archived ? I18n.unarchive : I18n.archive,
            action: toggleArchiveModal,
        },
    ];

    function toggleArchiveModal() {
        if (Object.values(selected)?.length) {
            setArchiveModal(x => !x);
        } else {
            setShowMessage({
                visible: true,
                message: I18n.please_select_customer_first,
            });
        }
    }

    const handleFilter = val => {
        setSelected({});
        setFilter(val);
    };

    const handleArchivedAction = async selected => {
        setArchiveModal(false);
        setloading(true);
        let selectedIds = [];
        await Promise.all(
            Object.values(selected)?.map(async item => {
                await item.archiveProduct(!item.archive);
                selectedIds.push(item.id);
            }),
        );
        setSelected(selected => {
            let obj = {...selected};
            selectedIds.map(val => {
                if (obj[val]) delete obj[val];
            });
            return obj;
        });

        setloading(false);
    };

    const handleChecked = val => {
        let data = val?.id;
        setSelected(items => {
            let obj = {...items};
            let item = obj[data];
            if (item) delete obj[data];
            else obj[val.id] = val;
            return obj;
        });
    };

    const handleSelectAll = () => {
        let list = filter == I18n.archived ? archivedList : activeDeals;
        if (Object.keys(selected).length != list.length) {
            list?.map(val => {
                setSelected(values => {
                    let obj = {...values};
                    obj[val.id] = val;
                    return obj;
                });
            });
        } else {
            setSelected({});
        }
    };

    const checkedClick = () =>
        Object.keys(selected)?.length > 0 && Object.keys(selected)?.length === (filter == I18n.archived ? archivedList.length : activeDeals.length) ? true : false;

    const handleCreateDeal = () => {
        navigate('/deals/create');
    };

    function handleSearch(val) {
        const filterList = filter === I18n.archived ? archivedList : activeDeals;
        const values = val?.toLowerCase();
        return filterList.filter(x => x?.name?.toLowerCase().includes(values));
    }

    const onChange = (label, value) => setText(value);
    const renderList = text ? searchList : filter === I18n.archived ? archivedList : filter === I18n.drafts_saved ? draftList : activeDeals;

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (messagesMap?.[renderToast]) {
            setShowMessage({
                visible: true,
                message: messagesMap[renderToast],
            });
        }
    }, [renderToast]);

    return loading ? (
        <Loading />
    ) : activeDeals?.length > 0 || archivedList?.length > 0 || draftList?.length > 0 ? (
        <>
            <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                <Header
                    type="search"
                    title={`${I18n.deals} (${renderList?.length})`}
                    search={{
                        type: 'pos',
                        placeholder: I18n.search_by_name,
                        prefix: {
                            name: 'search2Icon',
                            fill: theme.white,
                        },
                        onChange: onChange,
                    }}
                    leftCta={{
                        title: filter,
                        name: 'downIcon2',
                        fill: theme.white,
                        width: '20',
                        height: '20',
                        viewBox: '0 0 18 18',
                        option: filterOption,
                    }}
                    rightCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.create_deals,
                                  action: handleCreateDeal,
                              }
                            : null
                    }
                    actionCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.actions,
                                  name: 'downIcon2',
                                  fill: theme.white,
                                  width: '20',
                                  height: '20',
                                  viewBox: '0 0 18 18',
                                  option: actionList,
                              }
                            : null
                    }
                    className={'borderRadiusTopleftTopRigt'}
                />

                <div className="itemListContainer borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 160}}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <TableHeader className={`fiveColumn`} style={{borderColor: theme.inputBorder}}>
                                    <div className="checkboxCol">
                                        <CheckBox
                                            checkboxLabel={
                                                <p className="marBot0 fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                    {I18n.name}
                                                </p>
                                            }
                                            onChange={handleSelectAll}
                                            checked={checkedClick()}
                                        />
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.type}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.amount}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.options}
                                        </p>
                                    </div>

                                    {userPermission?.canCrud && (
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.action}
                                            </p>
                                        </div>
                                    )}
                                </TableHeader>
                                <div className="tableListMainBox" style={{height: DEVICE_HEIGHT - 280}}>
                                    {renderList?.length === 0 ? (
                                        <NonIdealScreen
                                            heading={text ? I18n.search : filter == I18n.archived ? I18n.archive_deal : filter == I18n.drafts_saved ? I18n.draft_deal : I18n.deals}
                                            subHeading={
                                                text
                                                    ? I18n.we_couldnt_find_any_results_for_your_search
                                                    : filter == I18n.archived
                                                    ? I18n.no_archive_deal_found
                                                    : filter == I18n.drafts_saved
                                                    ? I18n.no_draft_deal_found
                                                    : I18n.no_deal_found
                                            }
                                            name={text ? 'seacrhNotFoundIcon' : filter == I18n.archived ? 'archiveIcon' : 'draftIcon'}
                                            fill={theme.white}
                                            isMultiStep={false}
                                            tablenonIdeal={true}
                                        />
                                    ) : (
                                        renderList?.map((val, index) => (
                                            <List
                                                key={index}
                                                product={val}
                                                filter={filter}
                                                userPermission={userPermission}
                                                setRenderToast={setRenderToast}
                                                setSelected={setSelected}
                                                selected={selected}
                                                handleChecked={handleChecked}
                                            />
                                        ))
                                    )}
                                </div>
                                <ToastBox
                                    className="addSuccessfullyToast"
                                    ToastVisiable={showMessage.visible}
                                    bodyPara={showMessage.message}
                                    setShowMessage={setShowMessage}
                                    showIcon={true}
                                />

                                <MessageModal
                                    setToggle={setArchiveModal}
                                    toggle={archiveModal}
                                    className="messageModal"
                                    description={
                                        filter == I18n.archived
                                            ? I18n.are_you_sure_you_want_to_unarchive_the_selected_deal
                                            : I18n.are_you_sure_you_want_to_archive_the_selected_deal
                                    }
                                    secondaryCta={{
                                        title: I18n.no,
                                        action: toggleArchiveModal,
                                    }}
                                    primaryCta={{
                                        title: I18n.yes,
                                        action: () => {
                                            handleArchivedAction(selected);
                                        },
                                        isDelete: true,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    ) : (
        <>
            <NonIdealScreen
                heading={I18n.deals}
                subHeading={I18n.create_deal_and_start_selling}
                name="dealIcon"
                fill={theme.white}
                viewBox={'0 0 100 100'}
                secondaryCta={
                    userPermission?.canCrud
                        ? {
                              title: I18n.create_deal,
                              action: handleCreateDeal,
                          }
                        : null
                }
                isMultiStep={false}
            />
        </>
    );
};

const enhance = withObservables([], () => {
    const business_id = localStorage.getItem('business_id');
    return {
        activeDeals: observeActiveDeals(business_id),
        archivedList: observeArchiveDeals(business_id),
        draftList: observeDraftDeals(business_id),
    };
});

export default enhance(DealList);
