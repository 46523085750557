import {useEffect, useState, useRef} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../../context';
import {useNavigate} from 'react-router-dom';
import withObservables from '@nozbe/with-observables';
import TableBody from '../../../../../common/TableContainer/TableBody';
import {Button, ToastBox} from '../../../../../common';
import {DELIVERY, DISPATCH, PAYMENT, PREPARING, READY, SERVE, capitalizeFirstAlphabet, formatDate, formatDateWithTime, formatNum, formatTime} from '../../../../../../constants';
import SelectedRiderModal from '../../../../../common/V2/SelectedRiderModal/index.js';
import SelectedWaiterModal from '../../../../../common/V2/SelectedWaiterModal/index.js';
import {SelectedCustomerModal} from '../../../../../common/V2/index.js';
import OrderCompletionReceipt from '../../../../../common/OrderCompletionReceipt/index.js';
import {useReactToPrint} from 'react-to-print';
import Icon from '../../../../../../assets/icons/index.js';

const List = ({order, customer, waiter, rider, table}) => {
    let navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business, user} = useAuthContext();

    const [selectRider, setSelectRider] = useState(false);
    const [selectWaiter, setSelectWaiter] = useState(false);
    const [selectCustomer, setSelectCustomer] = useState(false);
    const [orderReceiptData, setOrderReceiptData] = useState({});
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePrintReceipt = e => {
        e.stopPropagation();
        handlePrint();
    };

    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    const navigateToDetail = () => {
        if ([PREPARING, READY, SERVE].includes(order.status)) {
            navigate(`/restaurant-pos?id=${order?.number}`);
        }
    };

    const handleRiderModal = e => {
        e?.stopPropagation();
        if (order?.type !== 'delivery') return;
        setSelectRider(true);
    };

    const handleSelectRider = async val => {
        const riderId = order?.rider?.id;
        let message = '';
        if (val == 'removeRider') {
            await order?.updateRider(null);
            message = I18n.remove_rider;
        } else {
            message = riderId ? I18n.update_rider : I18n.add_rider;
            await order?.updateRider(val);
        }
        setSelectRider(false);
        setShowMessage({
            visible: true,
            message: message,
        });
    };

    const handleCreateRider = () => {
        navigate(`/riders/create?id=${order.number}`);
    };

    const handleWaiterModal = e => {
        e?.stopPropagation();
        if (order?.type !== 'dinein') return;
        setSelectWaiter(true);
    };

    const handleSelectWaiter = async val => {
        const waiterId = order?.waiter?.id;
        let message = '';
        if (val == 'removeWaiter') {
            await order?.updateWaiter(null);
            message = I18n.remove_waiter;
        } else {
            message = waiterId ? I18n.update_waiter : I18n.add_waiter;
            await order?.updateWaiter(val);
        }

        setSelectWaiter(false);
        setShowMessage({
            visible: true,
            message: message,
        });
    };

    const handleCreateWaiter = () => {
        navigate(`/waiters/create?id=${order.number}&route=pos-cockpit`);
    };

    const handleCustomerModal = e => {
        e?.stopPropagation();
        setSelectCustomer(true);
    };

    const handleSelectCustomer = async val => {
        const customerId = order?.customer?.id;
        let message = '';
        if (val == 'removeCustomer') {
            await order?.updateCustomer(null);
            message = I18n.remove_Customer;
        } else {
            message = customerId ? I18n.update_Customer : I18n.customer_added;
            await order?.updateCustomer(val);
        }
        setSelectCustomer(false);
        setShowMessage({
            visible: true,
            message: message,
        });
    };

    const handleCreateCustomer = () => {
        navigate(`/customers/create?id=${order.number}&route=pos-cockpit`);
    };

    const getButtonTitle = () => {
        let orderTitle = 'ready';

        if (order.status === PREPARING) {
            orderTitle = READY;
        }
        if (order.status === READY) {
            if (order.type === DELIVERY) {
                orderTitle = DISPATCH;
            } else {
                orderTitle = SERVE;
            }
        }
        if (order.status === SERVE || order.status === PAYMENT) {
            orderTitle = PAYMENT;
        }

        return orderTitle;
    };

    const handleButtonClick = async e => {
        e.stopPropagation();
        const orderStatus = e.target.innerText?.toLowerCase();

        if (orderStatus === DISPATCH) {
            if (order?.rider?.id) {
                redirectToPayment();
            } else {
                handleRiderModal();
            }
        } else {
            await order.updateOrderStatus(orderStatus);

            if (order.status === PAYMENT) {
                redirectToPayment();
            }
        }
    };

    function redirectToPayment() {
        if (order.type === DELIVERY && !rider) {
            setShowMessage({
                visible: true,
                message: 'Add rider for payment',
            });
            return;
        }
        navigate(`/pos/billing?id=${order?.number}`);
    }

    const prepareReceiptData = async () => {
        const account = await business.account.fetch();
        const customerData = await order?.customer.fetch();
        const orderLines = await order?.order_line.fetch();

        const orderReceipt = {
            account,
            business,
            order,
            orderLines,
            customerData,
            user,
            table,
            showAmountSection: false,
        };
        setOrderReceiptData(orderReceipt);
    };

    const getOrderStatus = orderStatus => {
        let orderTitle = orderStatus;
        if (orderStatus === 'serve' || orderStatus === 'payment') {
            orderTitle = 'served';
        }

        return capitalizeFirstAlphabet(orderTitle);
    };

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        prepareReceiptData();
    }, [order]);

    return (
        <>
            <TableBody className={`tenColumn cursorPointer`} style={{borderColor: theme.posRightBg}} onClick={navigateToDetail}>
                <div className="BoxWidth justifyCenter itemListBox">
                    <p className="fontSize14" style={{color: theme.barclaysBlue}}>
                        {order?.number}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.lightGray}}>
                        {formatTime(order?.started_at)}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.lightGray}}>
                        {order?.type === 'dinein' ? 'Dine In' : capitalizeFirstAlphabet(order?.type)}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.lightGray}}>
                        {order?.type === 'dinein' ? table?.name : '-'}
                    </p>
                </div>

                <div className="BoxWidth justifyCenter" onClick={e => handleCustomerModal(e)}>
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.barclaysBlue}}>
                        {customer?.name || 'Add customer'}
                    </p>
                </div>

                <div className="BoxWidth justifyCenter" onClick={e => handleWaiterModal(e)}>
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.barclaysBlue}}>
                        {order.type === 'dinein' ? waiter?.name || 'Add waiter' : '-'}
                    </p>
                </div>

                <div className="BoxWidth justifyCenter" onClick={e => handleRiderModal(e)}>
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.barclaysBlue}}>
                        {order?.type === 'delivery' ? rider?.name || 'Add rider' : '-'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p
                        className="fontSize14 OneLineTruncate"
                        style={{color: order.status === 'preparing' ? theme.brightOrange : order.status === 'ready' ? theme.mustardYellow : theme.brightGreen}}>
                        {getOrderStatus(order?.status)}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.lightGray}}>
                        Rs. {formatNum(order?.total)}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <Button
                        title={capitalizeFirstAlphabet(getButtonTitle())}
                        handleClick={e => handleButtonClick(e)}
                        className={`${getButtonTitle()}Btn   fontWeight400 fontSize14 cursorPointer`}
                    />
                    <span className="marLeft10">
                        <Icon onClick={handlePrintReceipt} name="printerNewIcon" fill={'white'} width={'24'} height={'24'} viewBox={'0 0 18 18'} />
                    </span>
                </div>
            </TableBody>

            <SelectedCustomerModal
                setToggle={setSelectCustomer}
                toggle={selectCustomer}
                order={order}
                handleSelect={handleSelectCustomer}
                handleCreateCustomer={handleCreateCustomer}
            />

            <SelectedWaiterModal setToggle={setSelectWaiter} toggle={selectWaiter} order={order} handleSelect={handleSelectWaiter} handleCreateWaiter={handleCreateWaiter} />
            <SelectedRiderModal setToggle={setSelectRider} toggle={selectRider} order={order} handleSelect={handleSelectRider} handleCreateRider={handleCreateRider} />
            <ToastBox className="addSuccessfullyToast" ToastVisiable={showMessage.visible} bodyPara={showMessage.message} setShowMessage={setShowMessage} showIcon={true} />
            <div style={{display: 'none'}}>
                <OrderCompletionReceipt ref={componentRef} orderReceiptData={orderReceiptData} />
            </div>
        </>
    );
};

const enhance = withObservables(['order'], ({order}) => ({
    customer: order.customer.observe(),
    waiter: order.waiter.observe(),
    rider: order.rider.observe(),
    table: order.dine_in_table.observe(),
}));

export default enhance(List);
