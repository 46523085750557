import React from 'react';
import {useThemeContext} from '../../../../../../context';

const List = ({mod, selectedModifier, handleSelectModifier}) => {
    const {theme} = useThemeContext();
    const isModifiersAvailable = mod?.modifiersOption?.filter(modOpt => modOpt.in_stock)?.length > 0;

    return (
        <div>
            {isModifiersAvailable ? (
                <>
                    <p className="fontSize14 marBot10 marTop20" style={{color: theme.darkGrayTwo}}>
                        {mod?.modifiers?.name}
                    </p>
                    <div className="flex gap20" style={{flexWrap: 'wrap'}}>
                        {mod.modifiersOption
                            ?.filter(modOpt => modOpt.in_stock)
                            ?.map(opt => {
                                return (
                                    <div
                                        className={`cursorPointer ${selectedModifier?.[mod?.modifiers?.id]?.[opt.id] ? 'selectedModifierBox' : 'modifierBox'}`}
                                        onClick={() => handleSelectModifier(mod?.modifiers?.id, opt)}>
                                        <p className="fontSize14 marBot0" style={{color: selectedModifier?.[mod?.modifiers?.id]?.[opt.id] ? theme.barclaysBlue : theme.white}}>
                                            {opt.name}
                                        </p>
                                        <p
                                            className="fontSize14 marBot0"
                                            style={{color: selectedModifier?.[mod?.modifiers?.id]?.[opt.id] ? theme.barclaysBlue : theme.darkGrayTwo}}>
                                            {`Rs. ${opt.price || 0}`}
                                        </p>
                                    </div>
                                );
                            })}
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default List;
